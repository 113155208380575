import { royaltySalesConstants } from "../../constants";
import { hideLoader, showLoader } from "./Loader";
import * as FileSaver from 'file-saver';
import { pdtConstants } from '../../constants';
import {
  getPartnerProductList,
  getRoyaltySalesRequestData,
  getRoyaltySalesMappedData,
  deleteSalesRequest,
  addSalesRequest,
  editSalesRequest,
  getSingleRoyaltySalesRequestData,
  downloadRoyaltyPOApprovalRequestsDocument
} from "../../services";
import { showMessage } from "../../helpers";


export const getPartnersProductList = () => {
  return (dispatch) => {
    dispatch({ type: royaltySalesConstants.GET_PARTNERS_PRODUCT_LIST_REQUEST });
    getPartnerProductList()
      .then((response) => {
        dispatch({
          type: royaltySalesConstants.GET_PARTNERS_PRODUCT_LIST_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: royaltySalesConstants.GET_PARTNERS_PRODUCT_LIST_FAILURE });
        console.log(error);
      });
  };
};

export const getSalesRequestData = (isApproved, partner_id, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: royaltySalesConstants.GET_SALES_REQUEST_DATA_REQUEST });
    getRoyaltySalesRequestData(isApproved, partner_id)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: royaltySalesConstants.GET_SALES_REQUEST_DATA_SUCCESS,
          data: response ? response : [],
        });
        if (onSuccess) {
          onSuccess(response)
      }
      })
      .catch((error) => {
        dispatch({ type: royaltySalesConstants.GET_SALES_REQUEST_DATA_FAILURE });
        console.log(error);
      });
  };
};
export const getSalesMappedData = (isApproved) => {
  return (dispatch) => {
    dispatch({ type: royaltySalesConstants.GET_SALES_MAPPED_DATA_REQUEST });
    getRoyaltySalesMappedData(isApproved)
      .then((response) => {
        dispatch({
          type: royaltySalesConstants.GET_SALES_MAPPED_DATA_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: royaltySalesConstants.GET_SALES_MAPPED_DATA_FAILURE });
        console.log(error);
      });
  };
};

export const getSingleSalesRequestData = (id) => {
  return (dispatch) => {
    dispatch({ type: royaltySalesConstants.SINGLE_SALE_VALUE_REQUEST });
    getSingleRoyaltySalesRequestData(id)
      .then((response) => {
        dispatch({
          type: royaltySalesConstants.SINGLE_SALE_VALUE_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: royaltySalesConstants.SINGLE_SALE_VALUE_FAILURE });
        console.log(error);
      });
  };
};


export const deleteSalesRequestData = (id, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: royaltySalesConstants.DELETE_SALES_REQUEST_DATA_REQUEST });
    deleteSalesRequest(id)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: royaltySalesConstants.DELETE_SALES_REQUEST_DATA_SUCCESS });
        showMessage("success", "Removed!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: royaltySalesConstants.DELETE_SALES_REQUEST_DATA_FAILURE });
        console.log(error);
      });
  };
};

export const raiseSalesRequest = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: royaltySalesConstants.RAISE_SALES_REQUEST_REQUEST });
    addSalesRequest(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: royaltySalesConstants.RAISE_SALES_REQUEST_SUCCESS });
        showMessage("success", "Sales Request raised succcessfully!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: royaltySalesConstants.RAISE_SALES_REQUEST_FAILURE });
        let errorMsg =
          error && error.error
            ? error.error.filter((value) => value !== "None")
            : "";
        if (errorMsg.length) {
          showMessage("error", "Error");
        }
      });
  };
};

export const editRoyaltySalesRequestData = (id, data, onSuccess,status) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: royaltySalesConstants.EDIT_SALES_REQUEST_REQUEST });
    editSalesRequest(id, data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: royaltySalesConstants.EDIT_SALES_REQUEST_SUCCESS });
        if(status){
          showMessage("success", status);
        }else{
          showMessage("success", "Request edited succcessfully!");
        }

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: royaltySalesConstants.EDIT_SALES_REQUEST_FAILURE });
        let errorMsg =
          error && error.error
            ? error.error.filter((value) => value !== "None")
            : "";
        if (errorMsg.length) {
          showMessage("error", errorMsg[0]);
          showMessage("error", "Error");
        }
      });
  };
};

export const downloadPOApprovalRequestsDocument = (filename, requestObj, onSuccess) => {
  return dispatch => {
      dispatch(showLoader());
      dispatch({ type: pdtConstants.REPORT_DOWNLOAD_PDT_REQUEST });
      downloadRoyaltyPOApprovalRequestsDocument(requestObj).then(response => {
          dispatch(hideLoader());
          dispatch({ type: pdtConstants.REPORT_DOWNLOAD_PDT_SUCCESS });
          FileSaver.saveAs(response, filename);
          if (onSuccess) {
              onSuccess()
          }
      }).catch(
          error => {
              dispatch(hideLoader());
              dispatch({ type: pdtConstants.REPORT_DOWNLOAD_PDT_FAILURE });
              console.log(error)
          }
      )
  }
};

