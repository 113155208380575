import {InteractiveMapConstants } from "../../constants";

const initialSettings = {
 
  InteractiveMapList: [],
 
  isLoaded: false,
};

const InteractiveMaps = (state = initialSettings, action) => {
  switch (action.type) {
    case InteractiveMapConstants.INTERACTIVE_MAP_LOGS_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case InteractiveMapConstants.INTERACTIVE_MAP_LOGS_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        InteractiveMapList: action.data ? action.data : [],
      };
    case InteractiveMapConstants.INTERACTIVE_MAP_LOGS_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
       case InteractiveMapConstants.PRODUCT_AVAILABILITIES_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case InteractiveMapConstants.PRODUCT_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        isLoaded: true,
      };
    case InteractiveMapConstants.PRODUCT_AVAILABILITIES_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    case InteractiveMapConstants.PRODUCT_DEVELOPMENT_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case InteractiveMapConstants.PRODUCT_DEVELOPMENT_SUCCESS:
      return {
        ...state,
        isLoaded: true,
      };
    case InteractiveMapConstants.PRODUCT_DEVELOPMENT_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    case InteractiveMapConstants.SYNC_INTERACTIVE_ENTRY_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case InteractiveMapConstants.SYNC_INTERACTIVE_ENTRY_SUCCESS:
      return {
        ...state,
        isLoaded: true,
      };
    case InteractiveMapConstants.SYNC_INTERACTIVE_ENTRY_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    default:
      return state;
  }
};

export default InteractiveMaps;
