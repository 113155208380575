import { config } from "../constants";
import { authHeader } from "../helpers";
import { handleResponse } from "./handle-response";


export const uploadPdf = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader()},
      body: data,
    };
  
    return fetch(
      `${config.API_URL}/admin/mom-doc/`,
      requestOptions
    ).then(handleResponse);
  };

  export const downloadPdf = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
  
    return fetch(
      `${config.API_URL}/admin/mom-doc/download/`,
      requestOptions
    ).then(handleResponse);
  };


  export const getMOMListData = () => {
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
  
    return fetch(
      `${config.API_URL}/admin/mom-doc/`,
      requestOptions
    ).then(handleResponse);
  };