import React, { useState, useEffect } from "react";
import {
  getPartnersList,
  getAssignedComplianceList,
  getAdminComplianceList,
  adminAssignTemplate,
  resetAssign,
} from "../../../appRedux/actions";
import { showConfirm, getRole } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Button, Card } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Select } from "antd";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AssignComplianceTemplate = (props) => {
  const isLoaded = useSelector((state) => state.partnerCompliance.isLoaded);
  const partnersList = useSelector(
    (state) => state.partnerCompliance.PartnerListDetails
  );
  const assignedComplianceList = useSelector(
    (state) => state.partnerCompliance.AssignedList
  );
  const adminComplianceList = useSelector(
    (state) => state.partnerCompliance.AdminComplianceList
  );
  const dispatch = useDispatch();
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [data, setData] = useState([]);
  const [admincomplianceListData, setadmincomplianceListData] = useState([]);
  const [assignedComplianceData, SetassignedComplianceData] = useState([]);
  const [assignedPartnerComliance, setassignedPartnerCompliance] = useState([]);
  const [partnerId, SetpartnerId] = useState("");

  useEffect(() => {
    setIsUserAdmin(() => {
      return getRole() === "ADMIN";
    });
    dispatch(
      getPartnersList(() => {
        dispatch(getAdminComplianceList());
      })
    );
  }, []);

  useEffect(() => {
    if (isLoaded) {
      let rowData = partnersList.length
        ? partnersList
            .filter((value) => value.partner)
            .map((value) => ({
              key: value.partner.partner_id,
              name: value.partner.company_name,
            }))
        : [];
      setData(rowData);
    }
  }, [isLoaded]);

  useEffect(() => {
    const prefilledTemplates = [];
    {
      assignedComplianceData.map((value) =>
        prefilledTemplates.push(value.c_id)
      );
    }
    setassignedPartnerCompliance(prefilledTemplates);
  }, [partnerId]);

  useEffect(() => {
    let complianceData = adminComplianceList.length
      ? adminComplianceList.map((value) => ({
          c_id: value.template_id,
          t_name: value.template_name,
        }))
      : [];
    setadmincomplianceListData(complianceData);
  }, [isLoaded]);

  const getFinalAssignedComplianceList = (assignedComplianceArray) => {
    let data = [];
    assignedComplianceArray.forEach((val) => {
      if (val.assign_templates && val.assign_templates.length > 0) {
        for (let i = 0; i < val.assign_templates.length; i++) {
          data.push({
            c_id: val.template_id,
            t_name: val.template_name,
          });
        }
      } else {
        data.push({
          c_id: val.template_id,
          t_name: val.template_name,
        });
      }
    });
    return data;
  };

  useEffect(() => {
    let complianceData = assignedComplianceList.partner_templates;
    let filteredcomplianceData = complianceData
      ? complianceData.assign_templates
      : [];
    const finalAssisgnedComplianceData = getFinalAssignedComplianceList(
      filteredcomplianceData
    );

    SetassignedComplianceData(finalAssisgnedComplianceData);
  }, [isLoaded]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const template_id_data = [];
    var ele;
    for (ele in values.compliance_letter) {
      template_id_data.push({
        template_id: values.compliance_letter[ele],
      });
    }
    const submitData = {
      partner_id: values.partner_list,
      templates: template_id_data,
    };

    dispatch(
      adminAssignTemplate(submitData, () => {
        dispatch(resetAssign());
      }, ()=>{
        dispatch(getAssignedComplianceList(submitData.partner_id, (data) => {
          if (!!data) {
            form.setFieldsValue({
              compliance_letter: data.partner_templates.assign_templates.map(
                (val) => val.template_id
              ),
            });
          } else {
            form.setFieldsValue({
              compliance_letter: [],
            });
          }
        }))
      })
    );
  };

  const navigateBack = () => {
    const { history } = props;
    history.goBack();
  };

  const navigateToComplianceList = () => {
    const { history } = props;
    history.push("/admin/partner-compliance");
  };

  const getAssignedCompliance = (value) => {
    SetpartnerId(value);
    dispatch(
      getAssignedComplianceList(value, (data) => {
        if (!!data) {
          form.setFieldsValue({
            compliance_letter: data.partner_templates.assign_templates.map(
              (val) => val.template_id
            ),
          });
        } else {
          form.setFieldsValue({
            compliance_letter: [],
          });
        }
      })
    );
  };

  const handleChange = (value) => {
    console.log("FieldValue :", value);
  };

  const options_data = (data) => {
    let data2 = [];
    data.forEach((user) => data2.push({value:user.key, label:user.name}))
    return data2;
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <h1 className="title gx-mb-4">
            <LeftOutlined className="mr-5" onClick={navigateBack} />
            Assign Template
          </h1>
        </Col>
        <Col span={24}>
          <Card className="gx-card">
            <Row>
              <Col span={22}>
                <Form
                  {...formItemLayout}
                  form={form}
                  name="add-template"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Form.Item name="partner_list" label="Partner">
                    <Select
                      showSearch
                      onChange={getAssignedCompliance}
                      filterOption={(input, option) =>
                       (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                       }
                      options={options_data(data)}>
                    </Select>
                  </Form.Item>

                  <Form.Item name="compliance_letter" label="Assigned">
                    <Select
                      mode="multiple"
                      showSearch={false}
                      allowClear
                      placeholder="Please select"
                    >
                      {admincomplianceListData.map((letter) => (
                        <Option key={letter.c_id}>{letter.t_name}</Option>
                      ))}
                      ;
                    </Select>
                  </Form.Item>
                  <Form.Item className="flex-d-row-reverse">
                    <Button
                      id="add-product-submit"
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      id="add-product-cancel"
                      onClick={navigateToComplianceList}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default AssignComplianceTemplate;
