export const partnerComplianceTemplateConstants = {
  GET_PARTNER_COMPLIANCE_LIST_REQUEST: "GET_PARTNER_COMPLIANCE_LIST_REQUEST",
  GET_PARTNER_COMPLIANCE_LIST_SUCCESS: "GET_PARTNER_COMPLIANCE_LIST_SUCCESS",
  GET_PARTNER_COMPLIANCE_LIST_FAILURE: "GET_PARTNER_COMPLIANCE_LIST_FAILURE",

  GET_PARTNER_LIST_REQUEST: "GET_PARTNER_LIST_REQUEST",
  GET_PARTNER_LIST_SUCCESS: "GET_PARTNER_LIST_SUCCESS",
  GET_PARTNER_LIST_FAILURE: "GET_PARTNER_LIST_FAILURE",

  GET_ASSIGNED_LIST_REQUEST: "GET_ASSIGNED_LIST_REQUEST",
  GET_ASSIGNED_LIST_SUCCESS: "GET_ASSIGNED_LIST_SUCCESS",
  GET_ASSIGNED_LIST_FAILURE: "GET_ASSIGNED_LIST_FAILURE",

  GET_ADMIN_COMPLIANCE_LIST_REQUEST: "GET_ADMIN_COMPLIANCE_LIST_REQUEST",
  GET_ADMIN_COMPLIANCE_LIST_SUCCESS: "GET_ADMIN_COMPLIANCE_LIST_SUCCESS",
  GET_ADMIN_COMPLIANCE_LIST_FAILURE: "GET_ADMIN_COMPLIANCE_LIST_FAILURE",

  SEND_COMPLIANCE_REMINDER_REQUEST: "SEND_COMPLIANCE_REMINDER_REQUEST",
  SEND_COMPLIANCE_REMINDER_SUCCESS: "SEND_COMPLIANCE_REMINDER_SUCCESS",
  SEND_COMPLIANCE_REMINDER_FAILURE: "SEND_COMPLIANCE_REMINDER_FAILURE",

  SEND_COMPLIANCE_MAIL_REQUEST: "SEND_COMPLIANCE_MAIL_REQUEST",
  SEND_COMPLIANCE_MAIL_SUCCESS: "SEND_COMPLIANCE_MAIL_SUCCESS",
  SEND_COMPLIANCE_MAIL_FAILURE: "SEND_COMPLIANCE_MAIL_FAILURE",

  DOWNLOAD_TEMPLATE_REQUEST: "DOWNLOAD_TEMPLATE_REQUEST",
  DOWNLOAD_TEMPLATE_SUCCESS: "DOWNLOAD_TEMPLATE_SUCCESS",
  DOWNLOAD_TEMPLATE_FAILURE: "DOWNLOAD_TEMPLATE_FAILURE",

  DOWNLOAD_SIGNED_TEMPLATE_REQUEST: "DOWNLOAD_SIGNED_TEMPLATE_REQUEST",
  DOWNLOAD_SIGNED_TEMPLATE_SUCCESS: "DOWNLOAD_SIGNED_TEMPLATE_SUCCESS",
  DOWNLOAD_SIGNED_TEMPLATE_FAILURE: "DOWNLOAD_SIGNED_TEMPLATE_FAILURE",

  VERIFY_PARTNER_SIGNED_LETTER_REQUEST: "VERIFY_PARTNER_SIGNED_LETTER_REQUEST",
  VERIFY_PARTNER_SIGNED_LETTER_SUCCESS: "VERIFY_PARTNER_SIGNED_LETTER_SUCCESS",
  VERIFY_PARTNER_SIGNED_LETTER_FAILURE: "VERIFY_PARTNER_SIGNED_LETTER_FAILURE",

  REJECT_PARTNER_SIGNED_LETTER_REQUEST: "REJECT_PARTNER_SIGNED_LETTER_REQUEST",
  REJECT_PARTNER_SIGNED_LETTER_SUCCESS: "REJECT_PARTNER_SIGNED_LETTER_SUCCESS",
  REJECT_PARTNER_SIGNED_LETTER_FAILURE: "REJECT_PARTNER_SIGNED_LETTER_FAILURE",

  DELETE_PARTNER_COMPLIANCE_REQUEST: "DELETE_PARTNER_COMPLIANCE_REQUEST",
  DELETE_PARTNER_COMPLIANCE_SUCCESS: "DELETE_PARTNER_COMPLIANCE_SUCCESS",
  DELETE_PARTNER_COMPLIANCE_FAILURE: "DELETE_PARTNER_COMPLIANCE_FAILURE",

  ASSIGN_TEMPLATE_PARTNER_REQUEST: "ASSIGN_TEMPLATE_PARTNER_REQUEST",
  ASSIGN_TEMPLATE_PARTNER_SUCCESS: "ASSIGN_TEMPLATE_PARTNER_SUCCESS",
  ASSIGN_TEMPLATE_PARTNER_FAILURE: "ASSIGN_TEMPLATE_PARTNER_FAILURE",

  RESET_ASSIGN: "RESET_ASSIGN",

  SEND_BULK_REMINDER_REQUEST: "SEND_BULK_REMINDER_REQUEST",
  SEND_BULK_REMINDER_SUCCESS: "SEND_BULK_REMINDER_SUCCESS",
  SEND_BULK_REMINDER_FAILURE: "SEND_BULK_REMINDER_FAILURE",

  GET_NOTIFICATION_REQUEST: "GET_NOTIFICATION_REQUEST",
  GET_NOTIFICATION_REQUEST_SUCCESS: "GET_NOTIFICATION_REQUEST_SUCCESS",
  GET_NOTIFICATION_REQUEST_FAILURE: "GET_NOTIFICATION_REQUEST_FAILURE",

  NOTIFICATION_READ_REQUEST: "NOTIFICATION_READ_REQUEST",
  NOTIFICATION_READ_REQUEST_SUCCESS: "NOTIFICATION_READ_REQUEST_SUCCESS",
  NOTIFICATION_READ_REQUEST_FAILURE: "NOTIFICATION_READ_REQUEST_FAILURE",

  GET_COMPLIANCE_MESSAGE_REQUEST: "GET_COMPLIANCE_MESSAGE_REQUEST",
  GET_COMPLIANCE_MESSAGE_SUCCESS: "GET_COMPLIANCE_MESSAGE_SUCCESS",
  GET_COMPLIANCE_MESSAGE_FAILURE: "GET_COMPLIANCE_MESSAGE_FAILURE",

  POST_COMPLIANCE_MESSAGE_REQUEST: "POST_COMPLIANCE_MESSAGE_REQUEST",
  POST_COMPLIANCE_MESSAGE_SUCCESS: "POST_COMPLIANCE_MESSAGE_SUCCESS",
  POST_COMPLIANCE_MESSAGE_FAILURE: "POST_COMPLIANCE_MESSAGE_FAILURE",
};
