import { adminComplianceConstants } from "../../constants";
import { hideLoader, showLoader } from "./Loader";
import {
  addTemplate,
  editTemplate,
  getComplianceTemplateList,
  getTemplate,
  deleteTemplate,
} from "../../services";
import { showMessage } from "../../helpers";

export const adminAddTemplate = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: adminComplianceConstants.ADD_COMPLIANCE_REQUEST });
    addTemplate(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: adminComplianceConstants.ADD_COMPLIANCE_SUCCESS });
        showMessage("success", "Compliance added succcessfully!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: adminComplianceConstants.ADD_COMPLIANCE_FAILURE });
        if(error.non_field_errors) showMessage("error", error.non_field_errors[0].toString());
      });
  };
};

export const adminEditTemplate = (id, data, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: adminComplianceConstants.EDIT_COMPLIANCE_REQUEST });
    editTemplate(id, data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: adminComplianceConstants.EDIT_COMPLIANCE_SUCCESS });
        showMessage("success", "Edited successfully!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: adminComplianceConstants.EDIT_COMPLIANCE_FAILURE });
        if(error.message){
          showMessage("error", error.message.toString());
        }
        else{
          showMessage("error", "Something went wrong!!")
        }
      });
  };
};

export const getAdminTemplate = (id, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: adminComplianceConstants.GET_COMPLIANCE_REQUEST });
    getTemplate(id)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: adminComplianceConstants.GET_COMPLIANCE_SUCCESS,
          data: response ? response : {},
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: adminComplianceConstants.GET_COMPLIANCE_FAILURE });
        console.log(error);
      });
  };
};

export const getAdminTemplateList = () => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: adminComplianceConstants.GET_COMPLIANCE_LIST_REQUEST });
    getComplianceTemplateList()
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: adminComplianceConstants.GET_COMPLIANCE_LIST_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: adminComplianceConstants.GET_COMPLIANCE_LIST_FAILURE,
        });
        console.log(error);
      });
  };
};

export const deleteAdminTemplate = (id, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: adminComplianceConstants.DELETE_COMPLIANCE_REQUEST });
    deleteTemplate(id)
      .then((response) => {
        dispatch(hideLoader());
        if(response.ok){
          dispatch({ type: adminComplianceConstants.DELETE_COMPLIANCE_SUCCESS });
          showMessage("success", "Template Deleted!");
          if (onSuccess) {
            onSuccess();
          }
        }else if(response.status === 400){
          dispatch({ type: adminComplianceConstants.DELETE_COMPLIANCE_FAILURE });
          showMessage("error", "This template is currently assigned to a partner and cannot be deleted.");
        }
        else{
          dispatch({ type: adminComplianceConstants.DELETE_COMPLIANCE_FAILURE });
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: adminComplianceConstants.DELETE_COMPLIANCE_FAILURE });
        console.log(error);
      });
  };
};

export const resetTemplate = () => {
  return (dispatch) => {
    dispatch({ type: adminComplianceConstants.RESET_TEMPLATE });
  };
};
