import { useState, useRef } from "react";
import React from "react";
import Tippy from "@tippyjs/react";
import moment from "moment";
import { CloseCircleFilled } from '@ant-design/icons';
const PopupCellRenderer = (params, salesrequestdata) => {
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  const dropDownContent = (
    <div className="menu-container">
      {salesrequestdata.map((value, index) => (
        <div
          onClick={() => onClickHandler(value.mapping_id)}
          className="menu-parent"
        >
          <div className="menu-item">{value.mapping_id}</div>
          <div className="menu-child">
            <div className="menu-subchild">
              <span>{`Quantity: ${value.quantity}`}</span>
            </div>
            <div className="menu-subchild">
              <span>{`Country: ${value.country_name}`}</span>
            </div>
            <div className="menu-subchild">
              <span>{`Product: ${value.product_name}`}</span>
            </div>
            <div className="menu-subchild">
              <span>{`Expected Date Of Sale: ${moment(
                value.expected_date_of_sale
              ).format("DD/MM/YYYY")}`}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const onClickHandler = (request_id__mapping_id) => {
    hide();
    params.data.request_id__mapping_id = request_id__mapping_id;
    const colId = params.column.getColId();
    params.api.refreshCells({ rowNodes: [params.node], columns: [colId] });
  };
  const onClickClearHandler = () => {
    hide();
    params.data.request_id__mapping_id = null;
    const colId = params.column.getColId();
    params.api.refreshCells({ rowNodes: [params.node], columns: [colId] });
  };

  return (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement="auto"
    >
      <div>
        <button className="request_id_btn" onClick={visible ? hide : show}>
          {params.data.request_id__mapping_id}
        </button>
        {params.data.request_id__mapping_id && (
          <button className="request_id_cross_btn" onClick={() => onClickClearHandler()}><CloseCircleFilled /></button>
        )}
      </div>
    </Tippy>
  );
};

export default PopupCellRenderer;
