export const adminPartnerConstants = {
  ADD_PARTNER_REQUEST: "ADD_PARTNER_REQUEST",
  ADD_PARTNER_SUCCESS: "ADD_PARTNER_SUCCESS",
  ADD_PARTNER_FAILURE: "ADD_PARTNER_FAILURE",

  EDIT_PARTNER_REQUEST: "EDIT_PARTNER_REQUEST",
  EDIT_PARTNER_SUCCESS: "EDIT_PARTNER_SUCCESS",
  EDIT_PARTNER_FAILURE: "EDIT_PARTNER_FAILURE",

  GET_PARTNER_LIST_REQUEST: "GET_PARTNER_LIST_REQUEST",
  GET_PARTNER_LIST_SUCCESS: "GET_PARTNER_LIST_SUCCESS",
  GET_PARTNER_LIST_FAILURE: "GET_PARTNER_LIST_FAILURE",

  GET_PARTNER_REQUEST: "GET_PARTNER_REQUEST",
  GET_PARTNER_SUCCESS: "GET_PARTNER_SUCCESS",
  GET_PARTNER_FAILURE: "GET_PARTNER_FAILURE",

  DELETE_PARTNER_REQUEST: "DELETE_PARTNER_REQUEST",
  DELETE_PARTNER_SUCCESS: "DELETE_PARTNER_SUCCESS",
  DELETE_PARTNER_FAILURE: "DELETE_PARTNER_FAILURE",

  SEND_REMINDER_REQUEST: "SEND_REMINDER_REQUEST",
  SEND_REMINDER_SUCCESS: "SEND_REMINDER_SUCCESS",
  SEND_REMINDER_FAILURE: "SEND_REMINDER_FAILURE",

  RESET_EDIT_PARTNER: "RESET_EDIT_PARTNER",

  GET_COUNTRY_LIST_REQUEST: "GET_COUNTRY_LIST_REQUEST",
  GET_COUNTRY_LIST_SUCCESS: "GET_COUNTRY_LIST_SUCCESS",
  GET_COUNTRY_LIST_FAILURE: "GET_COUNTRY_LIST_FAILURE",

  SET_CUT_OFF_DATE_REQUEST: "SET_CUT_OFF_DATE_REQUEST",
  SET_CUT_OFF_DATE_SUCCESS: "SET_CUT_OFF_DATE_SUCCESS",
  SET_CUT_OFF_DATE_FAILURE: "SET_CUT_OFF_DATE_FAILURE",

  GET_CUT_OFF_DATE_REQUEST: "GET_CUT_OFF_DATE_REQUEST",
  GET_CUT_OFF_DATE_SUCCESS: "GET_CUT_OFF_DATE_SUCCESS",
  GET_CUT_OFF_DATE_FAILURE: "GET_CUT_OFF_DATE_FAILURE",

  SET_LETTER_DATE_REQUEST: "SET_LETTER_DATE_REQUEST",
  SET_LETTER_DATE_SUCCESS: "SET_LETTER_DATE_SUCCESS",
  SET_LETTER_DATE_FAILURE: "SET_LETTER_DATE_FAILURE",

  GET_LETTER_DATE_REQUEST: "GET_LETTER_DATE_REQUEST",
  GET_LETTER_DATE_SUCCESS: "GET_LETTER_DATE_SUCCESS",
  GET_LETTER_DATE_FAILURE: "GET_LETTER_DATE_FAILURE",
};
