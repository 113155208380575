import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  // NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  NAV_STYLE_FIXED,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { getRole, getUserInfo } from "../../helpers";
import {
  SnippetsOutlined,
  FormOutlined,
  MedicineBoxOutlined,
  UsergroupAddOutlined,
  FileSearchOutlined,
  BarChartOutlined,
  LogoutOutlined,
  LockOutlined,
  UploadOutlined,
  HeatMapOutlined,
  ContainerOutlined,
  MessageOutlined,
  WalletOutlined,
  UserOutlined,
  FolderOutlined,
  DashboardOutlined,
  RiseOutlined,
  SettingOutlined,
  IdcardOutlined
} from "@ant-design/icons";
// import UserProfile from './UserProfile';

const SidebarContent = (props) => {
  let { navStyle, themeType, pathname } = useSelector(
    ({ settings }) => settings
  );
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserStaff, setIsUserStaff] = useState(false);
  const [isUserLegal, setIsUserLegal] = useState(false);
  const [isUserLicensor, setIsUserLicensor] = useState(false);
  const [isUserDetails, setUserDetails] = useState(false);

  // const getNoHeaderClass = (navStyle) => {
  //   if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
  //     return 'gx-no-header-notifications';
  //   }
  //   return '';
  // };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys =
    selectedKeys.split("/")[selectedKeys.split("/").length - 1];

  const getMenuItems = () => {
    return {
      menuItems:
        isUserAdmin || isUserStaff
          ? [
              {
                key: "dashboard",
                link: "/admin/dashboard",
                icon: <DashboardOutlined className="font-20"/>,
                name_id: "sidebar.dashboard",
              },

              {
                key: "partner-list",
                link: "/admin/partner-list",
                icon: <UsergroupAddOutlined className="font-20" />,
                name_id: "sidebar.partnerList",
              },
              {
                key: "product-list",
                link: "/admin/product-list",
                icon: <MedicineBoxOutlined className="font-20" />,
                name_id: "sidebar.productList",
              },
              {
                key: "Partner-Compliance",
                link: "/admin/partner-compliance",
                icon: <FormOutlined className="font-20" />,
                name_id: "sidebar.partner-compliance",
              },
              {
                key: "Royalty-Sales",
                link: "/partner/royalty-sales-approvals",
                icon: <ContainerOutlined className="font-20" />,
                name_id: "sidebar.royalty-sales-approval-request",
              },
              {
                key: "Compliance-Template",
                link: "/admin/compliance-template",
                icon: <SnippetsOutlined className="font-20" />,
                name_id: "sidebar.compliance-template",
              },
              {
                key: "MOM",
                link: "/admin/MOM",
                icon: <UploadOutlined className="font-20" />,
                name_id: "sidebar.MOM",
              },
              {
                key: "Interactive-Map",
                link: "/admin/interactive-map",
                icon: <HeatMapOutlined className="font-20" />,
                name_id: "sidebar.interactive-map",
              },
              {
                key: "Royalty Invoice Payment Tracking",
                link: "/partner/partner-invoice",
                icon: <WalletOutlined className="font-20" />,
                name_id: "sidebar.partner-invoice",
              },
              // {
              //   key: "Chats",
              //   link: "/admin/chats",
              //   icon: <MessageOutlined className="font-20" />,
              //   name_id: "sidebar.chats",
              // },
            ]
          : isUserLegal
          ? [
              {
                key: "Partner-Compliance",
                link: "/admin/partner-compliance",
                icon: <FormOutlined className="font-20" />,
                name_id: "sidebar.partner-compliance",
              },
              {
                key: "Compliance-Template",
                link: "/admin/compliance-template",
                icon: <SnippetsOutlined className="font-20" />,
                name_id: "sidebar.compliance-template",
              },
            ]
          : isUserLicensor
          ? [
              {
                key: "Royalty Invoice Payment Tracking",
                link: "/partner/partner-invoice",
                icon: <WalletOutlined className="font-20" />,
                name_id: "sidebar.partner-invoice",
              },
            ]
          : [
              {
                key: "dashboard",
                link: "/partner/dashboard",
                icon: <DashboardOutlined className="font-20"/>,
                name_id: "sidebar.dashboard",
              },
              {
                key: "partner-compliance-letter",
                link: "/partner/compliance-letter",
                icon: <FormOutlined className="font-20" />,
                name_id: "sidebar.partner-compliance-letter",
              },
              ...(isUserDetails.is_royalty_partner
                ? [
                    {
                      key: "Royalty-Sales",
                      link: "/partner/royalty-sales-approvals",
                      icon: <ContainerOutlined className="font-20" />,
                      name_id: "sidebar.royalty-sales-approval-request",
                    },
                    {
                      key: "Royalty Invoice Payment Tracking",
                      link: "/partner/partner-invoice",
                      icon: <WalletOutlined className="font-20" />,
                      name_id: "sidebar.partner-invoice",
                    },
                  ]
                : []),
            ],
      submenuItems:
        isUserAdmin || isUserStaff
          ? [
              {
                key: "development-timeline",
                link: "/admin/report/development-timeline",
                icon: <RiseOutlined className="font-16"/>,
                name_id: "sidebar.pdt",
              },
              {
                key: "filing-plans",
                link: "/admin/report/filing-plans",
                icon: <FileSearchOutlined className="font-16" />,
                name_id: "sidebar.filingPlans",
              },
              {
                key: "sales-report",
                link: "/admin/report/sales-report",
                icon: <BarChartOutlined className="font-16" />,
                name_id: "sidebar.sales-report",
              },
            ]
          : isUserLegal
          ? []
          : [
              {
                key: "development-timeline",
                link: "/partner/development-timeline",
                icon: <RiseOutlined className="font-16"/>,
                name_id: "sidebar.pdt",
              },
              {
                key: "filing-plans",
                link: "/partner/filing-plans",
                icon: <FileSearchOutlined className="font-16 mr-10" />,
                name_id: "sidebar.filingPlans",
              },
              {
                key: "sales-report",
                link: "/partner/sales-report",
                icon: <BarChartOutlined className="font-16 mr-10" />,
                name_id: "sidebar.sales-report",
              },
            ],
    };
  };

  const getProfileMenuItems = () => {
    let menuItems = [
      {
        key: "profile",
        link:
          isUserAdmin || isUserStaff || isUserLegal || isUserLicensor
            ? "/admin/profile"
            : "/partner/profile",
        icon: <IdcardOutlined className="font-16"/>,
        name_id: "sidebar.profile",
      },
      {
        key: "settings",
        link: "/admin/settings",
        icon: <SettingOutlined className="font-16"/>,
        name_id: "sidebar.settings",
      },
      {
        key: "change-password",
        link: "/change-password",
        icon: <LockOutlined className="font-16" />,
        name_id: "sidebar.change-password",
      },
    ];
    if (!isUserAdmin && !isUserStaff) {
      menuItems.splice(1, 1);
    }

    return menuItems;
  };

  const displayMenuItems = (itemsArray) => {
    return itemsArray.map((value) => (
      <Menu.Item key={value.key}>
        <Link id={value.key} to={value.link}>
          {value.icon}
          <span>
            <IntlMessages id={value.name_id} />
          </span>
        </Link>
      </Menu.Item>
    ));
  };

  useEffect(() => {
    setIsUserAdmin(() => {
      return getRole() === "ADMIN";
    });
    setIsUserStaff(() => {
      return getRole() === "STAFF";
    });
    setIsUserLegal(() => {
      return getRole() === "LEGAL";
    });
    setIsUserLicensor(() => {
      return getRole() === "LICENSOR";
    });
    setUserDetails(() => {
      return getUserInfo();
    });
  }, []);

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        {/* <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
        <UserProfile history={props.history} />
        <AppsNavigation/>
      </div> */}
        <CustomScrollbars className="gx-layout-sider-scrollbar gx-mt-2">
          <Menu
            className={
              navStyle === NAV_STYLE_FIXED
                ? "pad-left-25 gx-display-flex-column sidebar-width-250"
                : ""
            }
            defaultOpenKeys={[defaultOpenKeys]}
            // selectedKeys={[selectedKeys]}
            selectedKeys={[defaultOpenKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            {displayMenuItems(getMenuItems().menuItems)}
            {!isUserLegal && !isUserLicensor ? (
              <Menu.SubMenu
                id="side-menu-reports"
                className="mpp-submenu"
                key="Reports"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <FolderOutlined className="font-20 align-items-center"/>
                    <span>
                      <IntlMessages id="sidebar.reports" />
                    </span>
                  </span>
                }
              >
                {displayMenuItems(getMenuItems().submenuItems)}
              </Menu.SubMenu>
            ) : null}
            <Menu.SubMenu
              id="side-menu-user-profile"
              className="mpp-submenu"
              key="User Profile"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {" "}
                  <UserOutlined className="font-20 align-items-center" />
                  <span>User Profile</span>
                </span>
              }
            >
              {displayMenuItems(getProfileMenuItems())}
            </Menu.SubMenu>
            <Menu.Item key="logout">
              <Link to={"/logout"}>
                <LogoutOutlined className="font-20" />
                <span>Logout</span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
