import React, { useEffect, useState } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getComplianceMessages,
  postComplianceMessages,
} from "../../../appRedux/actions";
import { Button, Tooltip } from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import { getRole } from "../../../helpers";

const Chats = (props) => {
  const chats = useSelector((state) => state.partnerCompliance.chats);
  const [inputValue, setInputValue] = useState("");
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [partner, setPartner] = useState("");

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setIsUserAdmin(() => {
      return getRole() === "ADMIN";
    });
    if (!props.template_id) {
      const partnerId = new URLSearchParams(props.location.search).get(
        "partner_id"
      );
      const templateId = new URLSearchParams(props.location.search).get(
        "template_id"
      );
      const userId = new URLSearchParams(props.location.search).get("user_id");
      setPartner({
        partner_id: partnerId,
        template_id: templateId,
        user_id: userId,
      });
      dispatch(getComplianceMessages(partnerId, templateId));
    } else {
      setPartner({
        partner_id: props.partner_id,
        template_id: props.template_id,
        user_id: props.userId,
      });
      dispatch(getComplianceMessages(props.partner_id, props.template_id));
    }
  }, [props.template_id]);

  const onSend = () => {
    if (inputValue !== "") {
      dispatch(
        postComplianceMessages(
          partner.partner_id,
          partner.template_id,
          isUserAdmin ? true : false,
          inputValue
        )
      );
      setInputValue("");
    }
  };

  return (
    <>
      {props.heading ? <></> : <h1>Chats</h1>}
      <div style={{ position: "relative", height: "80vh" }}>
        <MainContainer>
          <ChatContainer>
            <MessageList>
              {chats
                .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                .map((chat) => {
                  return parseInt(partner.user_id) ===
                    parseInt(chat.user_id) ? (
                    <Message
                      key={chat.compliance_message_id}
                      model={{
                        type: "html",
                        direction: "outgoing",
                        sentTime: `${chat.created_at}`,
                        payload: (
                          <Message.CustomContent>
                            <div className="chat-message">{chat.message}</div>
                            {chat.role === "ADMIN" ? (
                              <div className="chat-item">{chat.email}</div>
                            ) : (
                              <Tooltip title={chat.email}>
                                <div className="chat-item">
                                  {chat.company_name}
                                </div>
                              </Tooltip>
                            )}
                            <div className="chat-item">
                              {moment(chat.created_at).format(
                                "MMM D, YYYY hh:mm:ss a"
                              )}
                            </div>
                          </Message.CustomContent>
                        ),
                      }}
                    />
                  ) : (
                    <Message
                      key={chat.compliance_message_id}
                      model={{
                        direction: "incoming",
                        payload: (
                          <Message.CustomContent>
                            <div className="chat-message">{chat.message}</div>
                            {chat.role === "ADMIN" ? (
                              <div className="chat-item">{chat.email}</div>
                            ) : (
                              <Tooltip title={chat.email}>
                                <div className="chat-item">
                                  {chat.company_name}
                                </div>
                              </Tooltip>
                            )}
                            <div className="chat-item">
                              {moment(chat.created_at).format(
                                "MMM D, YYYY hh:mm:ss a"
                              )}
                            </div>
                          </Message.CustomContent>
                        ),
                      }}
                    />
                  );
                })}
            </MessageList>
            <div as={MessageInput}>
              <div className="message-input chat-input">
                <TextArea
                  maxLength={200}
                  placeholder="Type message here"
                  showCount
                  value={inputValue}
                  style={{
                    resize: "none",
                  }}
                  onChange={handleInputChange}
                  className="chat-text-area"
                />
                <Button
                  type="primary"
                  style={{ marginLeft: "5px", flex: "none" }}
                  onClick={onSend}
                >
                  Send
                </Button>
              </div>
            </div>
          </ChatContainer>
        </MainContainer>
      </div>
    </>
  );
};

export default Chats;
