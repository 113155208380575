import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Card,
  DatePicker,
  Upload,
  message,
} from "antd";
import { LeftOutlined, InboxOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getLicensorsPartnersList,
  raisePartnerTaxInvoiceRequest,
  getPartnerTaxInvoiceData,
  editTaxInvoiceRequest,
  getSingleTaxRequestData,
  editRoyaltyInvoiceRequestData,
  deleteInvoiceRequestData,
} from "../../../appRedux/actions";
import moment from "moment";

const { TextArea } = Input;
const { Dragger } = Upload;

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AddTaxData = (props) => {
  const { editTaxRequestData, royaltyPartnersList } = useSelector(
    ({ partnerInvoice }) => partnerInvoice
  );
  console.log(props,"pros")
  const [editFlag, setEditFlag] = useState(false);
  const [products, setProducts] = useState([]);
  const [editedFields, setEditedFields] = useState({});

  const handleFieldChange = (changedValues) => {
    setEditedFields((prevEditedFields) => ({
      ...prevEditedFields,
      ...changedValues,
    }));
  };

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const navigateToPartnerList = () => {
    const { history } = props;
    history.push("/partner/partner-invoice");
  };

  useEffect(() => {
    // Set initial values when editTaxRequestData changes
    if(editFlag){
    form.setFieldsValue({
      partner: editTaxRequestData?.partner_id?.company_name,
      year: moment(`${editTaxRequestData?.year}-01-01T00:00:00.000Z`),
      tax_invoice: editTaxRequestData?.invoice_file?.map((file) => ({
        uid: file.id,
        name: file.document.split("/").pop(),
        status: "done",
        url: file.document,
      })),
    });
  }
  }, [editTaxRequestData]);


  const handleRemove = (file) => {
    if (file.url) {
      dispatch(deleteInvoiceRequestData(file.uid));
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const checkFile = (file) => {
    const allowedFileTypes = [
      "application/pdf",
      "image/jpeg",
      "image/jpg",
      "image/png",
    ];
    const isTypeValid = allowedFileTypes.includes(file.type);
    const isSizeValid = file.size / 1024 / 1024 <= 15;

    if (!isTypeValid) {
      message.error("Please upload a PDF, JPEG, or PNG document!");
    } else if (!isSizeValid) {
      message.error("File size must be less than or equal to 15 MB!");
    }

    return isTypeValid && isSizeValid;
  };

  const fetchData = async () => {
    await dispatch(getLicensorsPartnersList());

    const { path, params } = props.match;
    if (params.id && path.split("/")[3] === "edit-request") {
      await dispatch(getSingleTaxRequestData(params.id));
      setEditFlag(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigateBack = () => {
    const { history } = props;
    history.goBack();
  };

  const onFinish = (values) => {
    const { params } = props.match;
    const formData = new FormData();
    if (!editFlag) {
      values.tax_invoice.map((data) => {
        formData.append("tax_invoice", data.originFileObj);
      });
      formData.append("year", moment(values.year.toISOString())?.year());
      formData.append("partner_id", values.partner);
      dispatch(raisePartnerTaxInvoiceRequest(formData, navigateBack));
    } else {
      Object.entries(editedFields).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          // Filter files that have originFileObj
          const filesWithOriginFileObj = value.filter(
            (file) => file.originFileObj
          );
          filesWithOriginFileObj.forEach((file, index) => {
            formData.append(`${key}`, file.originFileObj);
          });
        } else {
          if (key == "year") {
            formData.append("year", moment(value.toISOString())?.year());
          } else {
            formData.append(key, value);
          }
        }
      });
      formData.append("status", "PENDING");
      dispatch(
        editTaxInvoiceRequest(params.id, formData, navigateBack)
      );
    }
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <h1 className="title gx-mb-4">
            <LeftOutlined className="mr-5" onClick={navigateBack} />
            {editFlag ? "Edit Tax Invoice Data" : "Add Tax Document Data"}
          </h1>
        </Col>
        <Col span={24}>
          <Card className="gx-card">
            <Row>
              <Col span={16}>
                <Form
                  {...formItemLayout}
                  form={form}
                  name="add-request"
                  onValuesChange={handleFieldChange}
                  onFinish={onFinish}
                  initialValues={{
                    // year: moment(`${editTaxRequestData?.year}-01-01T00:00:00.000Z`),
                    partner: editTaxRequestData?.partner_id?.company_name,
                    tax_invoice: editTaxRequestData?.file?.map((file) => ({
                      uid: file.id,
                      name: file.document.split("/").pop(),
                      status: "done",
                      url: file.document,
                    })),
                  }}
                  scrollToFirstError
                >
                  <Form.Item
                    label="Year"
                    name="year"
                    rules={[
                      {
                        required: true,
                        message: "Please select a year!",
                      },
                    ]}
                  >
                    <DatePicker picker="year" />
                  </Form.Item>

                  <Form.Item
                    label="Partner"
                    name="partner"
                    rules={[
                      {
                        required: true,
                        message: "Please select a partner!",
                      },
                    ]}
                  >
                    <Select placeholder="Select a partner">
                      {royaltyPartnersList.map((partner) => (
                        <Select.Option
                          key={partner.partner_id}
                          value={partner.partner_id}
                        >
                          {partner.company_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Document"
                    name="tax_invoice"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      ({ getFieldValue, getFieldError }) => ({
                        validator(_, value) {
                          const fileList = getFieldValue("tax_invoice");

                          if (!fileList) {
                            // Field not touched, show the error for null field
                            return Promise.reject(new Error("Please upload a file!"));
                          }

                          // Validate file type
                          const allowedFileTypes = [
                            "application/pdf",
                            "image/jpeg",
                            "image/jpg",
                            "image/png",
                          ];
                          const isFileTypeValid = fileList.every(
                            (file) =>
                              !file.originFileObj ||
                              allowedFileTypes.includes(file.type)
                          );

                          if (!isFileTypeValid) {
                            return Promise.reject(
                              new Error("Please upload a PDF, JPEG, or PNG document!")
                            );
                          }

                          // Validate size for PDF, JPEG, and PNG files
                          const isSizeValid = fileList.every(
                            (file) =>
                              !file.originFileObj ||
                              (allowedFileTypes.includes(file.type) &&
                                file.size / 1024 / 1024 <= 15)
                          );

                          if (!isSizeValid) {
                            return Promise.reject(
                              new Error("File size must be less than or equal to 15 MB!")
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Dragger
                      name="tax_invoice"
                      multiple={true}
                      beforeUpload={checkFile}
                      accept=".pdf, .png, .jpeg, .jpg"
                      onRemove={(file) => handleRemove(file)}
                      customRequest={({ file, onSuccess }) => {
                        // Custom logic for file upload, you can use a file upload API here
                        onSuccess();
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag files to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload (pdf, png, jpeg).
                      </p>
                    </Dragger>
                  </Form.Item>

                  <Form.Item className="flex-d-row-reverse">
                    <Button
                      id="add-partner-save"
                      type="primary"
                      htmlType="submit"
                    >
                      {editFlag ? "Save" : "Submit Request"}
                    </Button>
                    <Button
                      id="add-partner-cancel"
                      onClick={navigateToPartnerList}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={2}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddTaxData;
