import { adminComplianceConstants } from '../../constants';


const initialSettings = {
    istemplateAdded: false,
    istemplateEdited: false,
    istemplateLoaded:false,
    templateList: [],
    templateData :[],
    templateItem: {},
    templateDetails: {},
    isLoaded: false
};

const AdminComplianceTemplate = (state = initialSettings, action) => {
    switch (action.type) {

        case adminComplianceConstants.ADD_COMPLIANCE_REQUEST:
            return {
                ...state,
                istemplateAdded: false,
                
            };
        case adminComplianceConstants.ADD_COMPLIANCE_SUCCESS:
            return {
                ...state,
                istemplateAdded: true
            };
        case adminComplianceConstants.ADD_COMPLIANCE_FAILURE:
            return {
                ...state,
                istemplateAdded: false
            };

        case adminComplianceConstants.EDIT_COMPLIANCE_REQUEST:
            return {
                ...state,
                istemplateEdited: false
            };
        case adminComplianceConstants.EDIT_COMPLIANCE_SUCCESS:
            return {
                ...state,
                istemplateEdited: true
            };
        case adminComplianceConstants.EDIT_COMPLIANCE_FAILURE:
            return {
                ...state,
                istemplateEdited: false
            };

        case adminComplianceConstants.DELETE_COMPLIANCE_REQUEST:
            return {
                ...state,
            };
        case adminComplianceConstants.DELETE_COMPLIANCE_SUCCESS:
            return {
                ...state,
            };
        case adminComplianceConstants.DELETE_COMPLIANCE_FAILURE:
            return {
                ...state,
            };

 
        case adminComplianceConstants.GET_COMPLIANCE_LIST_REQUEST:
            return {
                ...state,
                isLoaded: false,
            };
        case adminComplianceConstants.GET_COMPLIANCE_LIST_SUCCESS:
            return {
                ...state,
                isLoaded: true,
                templateList: action.data
            };
        case adminComplianceConstants.GET_COMPLIANCE_LIST_FAILURE:
            return {
                ...state,
                isLoaded: true,
            };
            case adminComplianceConstants.GET_COMPLIANCE_REQUEST:
                return {
                    ...state,
                    isLoaded: false,
                };
            case adminComplianceConstants.GET_COMPLIANCE_SUCCESS:
                return {
                    ...state,
                    isLoaded: true,
                    templateItem: action.data
                };
            case adminComplianceConstants.GET_COMPLIANCE_FAILURE:
                return {
                    ...state,
                    isLoaded: true,
                };
    
        case adminComplianceConstants.RESET_TEMPLATE:
            return {
                ...state,
                istemplateAdded: false,
                istemplateEdited: false
            }
            case adminComplianceConstants.GET_COMPLIANCE_DETAILS_REQUEST:
                return {
                    ...state,
                    istemplateLoaded: false,
                };
            case adminComplianceConstants.GET_COMPLIANCE_DETAILS_SUCCESS:
                return {
                    ...state,
                    istemplateLoaded: true,
                    templateDetails: action.data
                };
            case adminComplianceConstants.GET_COMPLIANCE_DETAILS_FAILURE:
                return {
                    ...state,
                    istemplateLoaded: true,
                };

        default:
            return state;
    }
};

export default AdminComplianceTemplate;
