import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Modal,
  Col,
  Button,
  Upload,
  Popconfirm,
  Descriptions,
  List,
  Tooltip,
} from "antd";
import {
  LeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
  MessageTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminCountryList,
  getPartnersProductList,
  getSingleSalesRequestData,
  editRoyaltySalesRequestData,
  getSalesRequestData,
} from "../../../appRedux/actions";
import classNames from "classnames";
import { royalty_countries_product } from "../../../constants";
import moment from "moment";
import { getRole, getUserInfo, getIsApprover } from "../../../helpers";
const confirm = Modal.confirm;

const { TextArea } = Input;

const ViewRaisedRequest = (props) => {
  const { editSalesRequestData } = useSelector(
    ({ royaltySales }) => royaltySales
  );
  const [isAdminApprover, setIsAdminApprover] = useState([]);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserPartner, setIsUserPartner] = useState(false);
  const currentDate = new Date();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const fetchData = async () => {
    await dispatch(getAdminCountryList());
    await dispatch(getPartnersProductList());
    setIsAdminApprover(getIsApprover());
    setIsUserAdmin(() => {
      return getRole() === "ADMIN";
    });
    setIsUserPartner(() => {
      return getRole() === "PARTNER";
    });

    const { path, params } = props.match;
    await dispatch(getSingleSalesRequestData(params.id));
  };

  const [chatConversation, setChatConversation] = useState([]);
  const [isChatModalVisible, setIsChatModalVisible] = useState(false);
  const [messageInput, setMessageInput] = useState("");

  const handleMessageChange = (e) => {
    setMessageInput(e.target.value);
  };

  const handleSendMessage = (item) => {
    if (messageInput.trim() !== "") {
      const newMessage = {
        message: messageInput.trim(),
        is_partner: isUserPartner,
      };
      const rejectFormData = new FormData();
      rejectFormData.append("status", "NEEDS CLARIFICATION");
      rejectFormData.append("message", newMessage.message);
      dispatch(
        editRoyaltySalesRequestData(
          item,
          rejectFormData,
          () => {
            dispatch(getSalesRequestData());
          },
          "Message Sent"
        )
      );
      const updatedChatConversation = [...chatConversation, newMessage];
      setMessageInput("");
      setChatConversation(updatedChatConversation);
    }
  };

  const handleClarificationClick = (chatMessages) => {
    setChatConversation(chatMessages);
    setIsChatModalVisible(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigateBack = () => {
    const { history } = props;
    history.goBack();
  };

  const isDisabled =
    editSalesRequestData.status !== "PENDING" &&
    editSalesRequestData.status !== "NEEDS CLARIFICATION";

  const showCommentsForm = () => {
    return (
      <Form form={form} layout="vertical" className="mt-20">
        <div className="mb-10">
          <span>Add comments for partner:</span>
        </div>
        <Form.Item
          name="comment"
          className="mb-0"
          rules={[
            {
              required: isUserAdmin,
              message: isUserAdmin
                ? "Enter comments for partner"
                : "Enter comments for admin",
            },
          ]}
        >
          <TextArea placeholder="Enter comments here" />
        </Form.Item>
      </Form>
    );
  };

  const showApproveConfirm = (item, partner_id) => {
    confirm({
      title: "Do you want to approve the report?",
      className: "btn-confirm-green",
      content: showCommentsForm(),
      icon: <CheckCircleOutlined />,
      okText: "Approve",
      cancelText: "Cancel",
      onOk(close) {
        form.validateFields().then((values) => {
          const approveFormData = new FormData();
          approveFormData.append("is_approved", true);
          approveFormData.append("status", "APPROVED");
          approveFormData.append("approval_date", currentDate.toISOString());
          approveFormData.append("comment", values.comment);
          approveFormData.append("partner_id", parseInt(partner_id));
          dispatch(
            editRoyaltySalesRequestData(
              item,
              approveFormData,
              () => {
                dispatch(getSalesRequestData());
                navigateBack();
              },
              "Approved"
            )
          );
          close();
        });
      },
      onCancel() {
        form.resetFields(["comment"]);
      },
    });
  };

  const showRejectConfirm = (item, partner_id) => {
    confirm({
      title: "Do you want to reject this Request?",
      className: "btn-confirm-red",
      content: showCommentsForm(),
      okText: "Reject",
      cancelText: "Cancel",
      icon: <CloseCircleOutlined />,
      onOk(close) {
        form.validateFields().then((values) => {
          const rejectFormData = new FormData();
          rejectFormData.append("status", "REJECTED");
          rejectFormData.append("comment", values.comment);
          rejectFormData.append("partner_id", parseInt(partner_id));
          rejectFormData.append("is_approved", false);
          dispatch(
            editRoyaltySalesRequestData(
              item,
              rejectFormData,
              () => {
                dispatch(getSalesRequestData());
                navigateBack();
              },
              "Rejected"
            )
          );
          close();
        });
      },
      onCancel() {
        form.resetFields(["comment"]);
      },
    });
  };
  const showReviseConfirm = (item, partner_id) => {
    confirm({
      title: "Do you want to request for revisions?",
      className: "btn-confirm-primary",
      content: showCommentsForm(),
      okText: "Submit",
      cancelText: "Cancel",
      icon: <ExclamationCircleOutlined />,
      onOk(close) {
        form.validateFields().then((values) => {
          const rejectFormData = new FormData();
          rejectFormData.append("status", "REVISIONS REQUIRED");
          rejectFormData.append("comment", values.comment);
          rejectFormData.append("partner_id", parseInt(partner_id));
          rejectFormData.append("is_approved", false);
          dispatch(
            editRoyaltySalesRequestData(
              item,
              rejectFormData,
              () => {
                dispatch(getSalesRequestData());
                navigateBack();
              },
              "Status Sent"
            )
          );
          close();
        });
      },
      onCancel() {
        form.resetFields(["comment"]);
      },
    });
  };
  const showClarificationConfirm = (item, partner_id) => {
    confirm({
      title: "Do you want to request for clarification?",
      className: "btn-confirm-primary",
      content: showCommentsForm(),
      okText: "Submit",
      cancelText: "Cancel",
      icon: <WarningOutlined />,
      onOk(close) {
        form.validateFields().then((values) => {
          const rejectFormData = new FormData();
          rejectFormData.append("status", "NEEDS CLARIFICATION");
          rejectFormData.append("message", values.comment);
          rejectFormData.append("partner_id", parseInt(partner_id));
          rejectFormData.append("is_approved", false);
          dispatch(
            editRoyaltySalesRequestData(
              item,
              rejectFormData,
              () => {
                dispatch(getSalesRequestData());
                navigateBack();
              },
              "Status Sent"
            )
          );
          close();
        });
      },
      onCancel() {
        form.resetFields(["comment"]);
      },
    });
  };

  const labelStyle = { width: "200px" };

  return (
    <div style={{ padding: "30px" }}>
      <Col span={24}>
        <div
          className="gx-flex-row"
          style={{ justifyContent: "space-between" }}
        >
          <h1 className="title gx-mb-4">
            <LeftOutlined className="mr-5" onClick={navigateBack} />
            Royalty Sales Request Information
          </h1>
          {isAdminApprover && isUserAdmin && (
            <div>
              <Button
                className="gx-btn-success"
                icon={<CheckOutlined />}
                onClick={() =>
                  showApproveConfirm(
                    editSalesRequestData.request_id,
                    editSalesRequestData.partner_id.partner_id
                  )
                }
                id={`approve-${editSalesRequestData.request_id}`}
                disabled={isDisabled}
              >
                Approve
              </Button>

              <Button
                className="gx-btn-yellow"
                // type="warning"  // Different color for "Revisions Required"
                onClick={() =>
                  showReviseConfirm(
                    editSalesRequestData.request_id,
                    editSalesRequestData.partner_id.partner_id
                  )
                }
                icon={<ExclamationCircleOutlined />}
                id={`revisions-${editSalesRequestData.request_id}`}
                disabled={isDisabled}
              >
                Revisions Required
              </Button>

              <Button
                className="gx-btn-warning" // Different color for "Needs Clarification"
                onClick={() =>
                  showClarificationConfirm(
                    editSalesRequestData.request_id,
                    editSalesRequestData.partner_id.partner_id
                  )
                }
                icon={<WarningOutlined />}
                id={`clarification-${editSalesRequestData.request_id}`}
                disabled={editSalesRequestData.status !== "PENDING"}
              >
                Needs Clarification
              </Button>

              <Button
                className="gx-btn-danger"
                icon={<CloseOutlined />}
                onClick={() =>
                  showRejectConfirm(
                    editSalesRequestData.request_id,
                    editSalesRequestData.partner_id.partner_id
                  )
                }
                id={`reject-${editSalesRequestData.request_id}`}
                disabled={isDisabled}
              >
                Reject
              </Button>
            </div>
          )}
        </div>
      </Col>
      <Col span={12}>
        <Descriptions
          bordered
          column={1}
          style={{
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
          }}
        >
          <Descriptions.Item label="Company Name" style={labelStyle}>
            {editSalesRequestData?.partner_id?.company_name}
          </Descriptions.Item>
          <Descriptions.Item label="Product Name" style={labelStyle}>
            {editSalesRequestData?.product_id?.product_name}
          </Descriptions.Item>
          <Descriptions.Item label="Country" style={labelStyle}>
            {editSalesRequestData?.country_id?.country_name}
          </Descriptions.Item>
          <Descriptions.Item label="Request Date" style={labelStyle}>
            {moment(editSalesRequestData.request_date).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Expected Date of Sale" style={labelStyle}>
            {moment(editSalesRequestData.expected_date_of_sale).format(
              "DD/MM/YYYY"
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Pack Size" style={labelStyle}>
            {editSalesRequestData.pack_size}
          </Descriptions.Item>
          <Descriptions.Item label="Quantity" style={labelStyle}>
            {editSalesRequestData.quantity}
          </Descriptions.Item>
          <Descriptions.Item label="Proof of Sale Details" style={labelStyle}>
            {editSalesRequestData.proof_of_sale_details}
          </Descriptions.Item>
          <Descriptions.Item label="Buyer Name and Address" style={labelStyle}>
            {editSalesRequestData.buyer_name_and_address}
          </Descriptions.Item>
          <Descriptions.Item
            label="End Customer and Address"
            style={labelStyle}
          >
            {editSalesRequestData.end_customer_and_address}
          </Descriptions.Item>
          <Descriptions.Item label="Status" style={labelStyle}>
            {editSalesRequestData.status}
            {editSalesRequestData?.clarification?.length !== 0 && (
              <Tooltip
                onClick={() => {
                  handleClarificationClick(editSalesRequestData.clarification);
                }}
              >
                <span
                  style={{
                    cursor: "pointer",
                    paddingLeft: "5px",
                  }}
                >
                  <MessageTwoTone twoToneColor="#00AEEF" className="font-20" />
                </span>
              </Tooltip>
            )}
          </Descriptions.Item>
          {editSalesRequestData?.comment && (
            <Descriptions.Item label="Comments" style={labelStyle}>
              {editSalesRequestData.comment}
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Documents" style={labelStyle}>
            {editSalesRequestData?.file?.map((file, index) => (
              <>
                <a
                  key={file.po_id}
                  href={file.po_document}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PO {index + 1}
                </a>
                <br />
              </>
            ))}
          </Descriptions.Item>
          {/* Add more descriptions for other fields */}
        </Descriptions>
        <Modal
          title="Clarification Chat"
          visible={isChatModalVisible}
          onCancel={() => setIsChatModalVisible(false)}
          footer={null}
        >
          <div style={{ maxHeight: 400, overflowY: "auto" }}>
            <List
              itemLayout="horizontal"
              dataSource={chatConversation}
              renderItem={(message, index) => (
                <List.Item
                  className={classNames("chat-message", {
                    "chat-message-user": !message.is_partner,
                    "chat-message-partner": message.is_partner,
                  })}
                >
                  <List.Item.Meta
                    description={
                      <div
                        style={{
                          background: message.is_partner
                            ? "#f6f6f6"
                            : "#e6f7ff",
                        }}
                        className="chat-message-content"
                      >
                        {message.message}
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
          {editSalesRequestData.status == "NEEDS CLARIFICATION" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <TextArea
                rows={3}
                value={messageInput}
                onChange={handleMessageChange}
                placeholder="Type your message..."
                autoFocus
                style={{ flex: 1, marginRight: 10 }}
              />
              <Button
                type="primary"
                onClick={() => {
                  handleSendMessage(chatConversation[0].request_id);
                }}
              >
                Send
              </Button>
            </div>
          )}
        </Modal>
      </Col>
    </div>
  );
};

export default ViewRaisedRequest;
