import * as FileSaver from "file-saver";
import { MOMConstants } from "../../constants";
import { hideLoader, showLoader } from "./Loader";
import {
  getMOMListData,
  downloadPdf,
  uploadPdf
} from "../../services";
import { showMessage } from "../../helpers";

export const getMOMList = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: MOMConstants.GET_MOM_REQUEST,
    });
    getMOMListData(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: MOMConstants.GET_MOM_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: MOMConstants.GET_MOM_FAILURE,
        });
        console.log(error);
      });
  };
};


export const downloadMOMPDF = (filename, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: MOMConstants.DOWNLOAD_MOM_REQUEST,
    });
    downloadPdf(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: MOMConstants.DOWNLOAD_MOM_SUCCESS,
        });
        if(response.url)
          window.open(response.url)
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: MOMConstants.DOWNLOAD_MOM_FAILURE,
        });
        console.log(error);
      });
  };
};

export const uploadMOMPDF = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: MOMConstants.UPLOAD_MOM_REQUEST,
    });
    uploadPdf(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: MOMConstants.UPLOAD_MOM_SUCCESS,
        });
        if(response.document) {
          showMessage("success", "MOM uploaded successfully");
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: MOMConstants.UPLOAD_MOM_FAILURE,
        });
        console.log(error);
      });
  };
};
