import {MOMConstants } from "../../constants";

const initialSettings = {
 
  MOMList: [],
 
  isLoaded: false,
};

const MOMList = (state = initialSettings, action) => {
  switch (action.type) {
    case MOMConstants.GET_MOM_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case MOMConstants.GET_MOM_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        MOMList: action.data ? action.data.results.rows : [],
      };
    case MOMConstants.GET_MOM_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
       case MOMConstants.DOWNLOAD_MOM_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case MOMConstants.DOWNLOAD_MOM_SUCCESS:
      return {
        ...state,
        isLoaded: true,
      };
    case MOMConstants.DOWNLOAD_MOM_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    case MOMConstants.UPLOAD_MOM_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case MOMConstants.UPLOAD_MOM_SUCCESS:
      return {
        ...state,
        isLoaded: true,
      };
    case MOMConstants.UPLOAD_MOM_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    default:
      return state;
  }
};

export default MOMList;
