import React, { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import {
  Modal,
  Card,
  Table,
  Row,
  Col,
  Button,
  Tooltip,
  Form,
  Upload,
  message,
  Typography,
  Divider,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  getPartnerComplianceLetter,
  downloadPartnerLetter,
  sendPartnerSignedPDF,
} from "../../../appRedux/actions";
import {
  FileAddTwoTone,
  MessageOutlined,
  SaveTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import {
  showConfirm,
  getRole,
  showMessage,
  getUserInfo,
} from "../../../helpers";
import Chats from "../chats";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  if (e.fileList.length > 1) {
    return [e.fileList[1]];
  }

  return e && e.fileList;
};

const PartnerComplianceLetter = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templatHistoryId, settemplateHistoryId] = useState("");
  const [quarterDropdown, setQuarterDropdown] = useState([]);
  const [isUserPartner, setIsUserPartner] = useState(false);
  const [currentQuarter, setCurrentQuarter] = useState("");
  const [currentYear, setCurrentYear] = useState(null);
  const [chatOpen, setChatOpen] = useState(false);
  const [partner, setPartner] = useState();
  const [userDetails, setUserDetails] = useState({});
  const [uploadModalTitle, setUploadModalTitle] = useState(undefined);
  const isLoaded = useSelector(
    (state) => state.partnerComplianceLetter.isLoaded
  );
  const partnerComplianceLetters = useSelector(
    (state) => state.partnerComplianceLetter.PartnerComplianceLetterList
  );
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(true);
  const [form] = Form.useForm();

  const changeQuarter = (e) => {
    if (e.target.value !== "0") {
      setCurrentQuarter(e.target.value);
    } else {
      setCurrentQuarter("");
    }
  };

  useEffect(() => {
    setUserDetails(getUserInfo());
    setIsUserPartner(() => {
      return getRole() === "PARTNER";
    });
    if (currentQuarter) {
      dispatch(getPartnerComplianceLetter({ quarter: currentQuarter }));
    } else {
      dispatch(getPartnerComplianceLetter({}));
    }
  }, [currentQuarter]);

  useEffect(() => {
    let finaldata = partnerComplianceLetters
      ? partnerComplianceLetters.partner_templates
      : [];
    let partnerComplianceListData = !!finaldata
      ? finaldata.map((value) => ({
          key: value.template_id,
          template_name: value.template_name,
          template_history_id: value.template_history_id,
          template_id: value.template_id,
          date: value.year,
          verified: value.verification_status ? value.verification_status : "-",
          signed_pdf_status: value.signed_pdf_status,
        }))
      : [];
    const quarterFilters = partnerComplianceLetters
      ? partnerComplianceLetters.filter.year
      : [];
    const currentYearNum = partnerComplianceLetters
      ? partnerComplianceLetters.current_year
      : null;
    setData(partnerComplianceListData);
    if (currentYearNum) {
      setCurrentYear(currentYearNum);
      setQuarterDropdown(quarterFilters);
    }
  }, [isLoaded]);

  const openModal = (item) => {
    settemplateHistoryId(item.template_history_id);
    setUploadModalTitle(item.template_name);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = (e) => {
    setIsModalVisible(false);
    setUploadModalTitle(undefined);
    setUploadStatus(true);
  };

  const downloadLetter = (item) => {
    const sendDownloadData = {
      template_history_id: item.template_history_id,
      templates_pdf: "true",
      signed_pdf: "false",
    };

    dispatch(downloadPartnerLetter(item.template_name, sendDownloadData));
  };

  const dummyRequest = async (options) => {
    const { onSuccess, onError, file } = options;
    setTimeout(() => {
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        message.error("File must be smaller than 15MB!");
        onError("error");
      } else {
        setUploadStatus(false);
        onSuccess("ok");
      }
    }, 0);
  };

  const showChatModal = (item) => {
    setPartner(item);
    setChatOpen(true);
  };

  const onFinish = (values) => {
    let formData = new FormData();
    formData.append("template_history_id", templatHistoryId);
    formData.append("signed_pdf", values.upload[0].originFileObj);

    dispatch(
      sendPartnerSignedPDF(formData, () => {
        showMessage("success", "Document uploaded successsfully!");
        form.resetFields();
        setIsModalVisible(false);
        setUploadModalTitle(undefined);
        setUploadStatus(true);
        if (currentQuarter) {
          dispatch(getPartnerComplianceLetter({ quarter: currentQuarter }));
        } else {
          dispatch(getPartnerComplianceLetter({}));
        }
      })
    );
  };

  const columns = [
    {
      title: "Template Name",
      dataIndex: "template_name",
      key: "template_name",
      align: "center",
    },

    {
      title: "Year",
      dataIndex: "date",
      key: "date",
      align: "center",
    },

    {
      title: "Status",
      dataIndex: "signed_pdf_status",
      key: "signed_pdf_status",
      align: "center",
    },

    {
      title: "Verification Status",
      dataIndex: "verified",
      key: "verified",
      align: "center",
    },

    {
      title: "Action",
      key: "operation",
      align: "center",
      render: (item) => (
        <div style={{ display: "inline-flex" }}>
          {isUserPartner ? (
            <Button
              type="link"
              className="margin-0"
              onClick={() => showChatModal(item)}
              id={"sendmail-" + item.key}
              disabled={isUserPartner ? false : true}
            >
              <Tooltip title="Chats">
                <MessageOutlined twoToneColor="#00AEEF" className="font-20" />
              </Tooltip>
            </Button>
          ) : (
            <></>
          )}
          <Button
            type="link"
            className="margin-0"
            onClick={() => downloadLetter(item)}
            id={"download-template-" + item.key}
          >
            <Tooltip title="Download">
              <SaveTwoTone twoToneColor="#00AEEF" className="font-20" />
            </Tooltip>
          </Button>
          <Button
            type="link"
            className="margin-0"
            id={"upload-" + item.key}
            disabled={item.date < currentYear}
            onClick={() => {
              openModal(item);
              showModal();
            }}
          >
            <Tooltip title="Upload">
              <FileAddTwoTone twoToneColor="#00AEEF" className="font-20" />
            </Tooltip>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row>
        <Col span={24}>
          <div
            className="gx-flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <h1 className="title gx-mb-4">
              <IntlMessages id="sidebar.partner-compliance-letter" />
            </h1>
            <div className="quarter-dropdown-container-admin-pdt">
              <select onChange={changeQuarter} className="quarter-dropdown">
                {quarterDropdown.map((quarter) => {
                  if (quarter.quarter_year >= 2022) {
                    return (
                      <option
                        value={quarter.quarter_year}
                        className="quarter-dropdown-options"
                      >
                        {quarter.quarter_year}
                      </option>
                    );
                  }
                })}
              </select>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Card className="mb-0">
            <Modal
              title="Chats"
              centered
              visible={chatOpen}
              onCancel={() => setChatOpen(false)}
              footer={false}
              width={1000}
            >
              <Chats
                heading={true}
                partner_id={userDetails?.partner_id}
                template_id={partner?.template_id}
                userId={userDetails?.user_id}
              />
              <Divider />
              <Typography.Link
                href={`/admin/chats?template_id=${partner?.template_id}&partner_id=${userDetails?.partner_id}&user_id=${userDetails?.user_id}`}
                target="_blank"
              >
                Full view
              </Typography.Link>
            </Modal>
            <Table
              className="gx-table-responsive mpp-list-table"
              columns={columns}
              dataSource={data}
              bordered
            />
          </Card>
        </Col>
      </Row>
      <Modal
        destroyOnClose={true}
        title={`Upload Signed PDF for ${uploadModalTitle}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="uploadSignedDocument"
          onFinish={onFinish}
          preserve={false}
        >
          <Form.Item
            name="upload"
            label="Upload"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              customRequest={dummyRequest}
              listType="picture"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
          </Form.Item>

          <div className="mb-10">
            <small className="ant-upload-hint">
              **Only supports file size upto 15MB.
            </small>
          </div>
          <Form.Item
            wrapperCol={{
              span: 12,
              offset: 6,
            }}
          >
            <Button type="primary" htmlType="submit" disabled={uploadStatus}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default PartnerComplianceLetter;
