export const filingPlansConstants = {
    GET_FILING_PLANS_REQUEST: 'GET_FILING_PLANS_REQUEST',
    GET_FILING_PLANS_SUCCESS: 'GET_FILING_PLANS_SUCCESS',
    GET_FILING_PLANS_FAILURE: 'GET_FILING_PLANS_FAILURE',

    GET_FILING_PLANS_REPORT_REQUEST: 'GET_FILING_PLANS_REPORT_REQUEST',
    GET_FILING_PLANS_REPORT_SUCCESS: 'GET_FILING_PLANS_REPORT_SUCCESS',
    GET_FILING_PLANS_REPORT_FAILURE: 'GET_FILING_PLANS_REPORT_FAILURE',

    POST_FILING_PLANS_REQUEST: 'POST_FILING_PLANS_REQUEST',
    POST_FILING_PLANS_SUCCESS: 'POST_FILING_PLANS_SUCCESS',
    POST_FILING_PLANS_FAILURE: 'POST_FILING_PLANS_FAILURE',

    GET_ADMIN_FILING_PLANS_REQUEST: 'GET_ADMIN_FILING_PLANS_REQUEST',
    GET_ADMIN_FILING_PLANS_SUCCESS: 'GET_ADMIN_FILING_PLANS_SUCCESS',
    GET_ADMIN_FILING_PLANS_FAILURE: 'GET_ADMIN_FILING_PLANS_FAILURE',

    POST_ADMIN_FILING_PLANS_REQUEST: 'POST_ADMIN_FILING_PLANS_REQUEST',
    POST_ADMIN_FILING_PLANS_SUCCESS: 'POST_ADMIN_FILING_PLANS_SUCCESS',
    POST_ADMIN_FILING_PLANS_FAILURE: 'POST_ADMIN_FILING_PLANS_FAILURE',

    APPROVE_FILING_PLANS_REQUEST: 'APPROVE_FILING_PLANS_REQUEST',
    APPROVE_FILING_PLANS_SUCCESS: 'APPROVE_FILING_PLANS_SUCCESS',
    APPROVE_FILING_PLANS_FAILURE: 'APPROVE_FILING_PLANS_FAILURE',

    REPORT_FILING_PLANS_REQUEST: 'REPORT_FILING_PLANS_REQUEST',
    REPORT_FILING_PLANS_SUCCESS: 'REPORT_FILING_PLANS_SUCCESS',
    REPORT_FILING_PLANS_FAILURE: 'REPORT_FILING_PLANS_FAILURE',

    REPORT_DOWNLOAD_FILING_PLANS_REQUEST: 'REPORT_DOWNLOAD_FILING_PLANS_REQUEST',
    REPORT_DOWNLOAD_FILING_PLANS_SUCCESS: 'REPORT_DOWNLOAD_FILING_PLANS_SUCCESS',
    REPORT_DOWNLOAD_FILING_PLANS_FAILURE: 'REPORT_DOWNLOAD_FILING_PLANS_FAILURE',

    MESSAGE_READ_REQUEST: 'MESSAGE_READ_REQUEST',
    MESSAGE_READ_SUCCESS: 'MESSAGE_READ_SUCCESS',
    MESSAGE_READ_FAILURE: 'MESSAGE_READ_FAILURE',
};