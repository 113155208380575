import React, { useEffect, useState, useRef } from "react";
import IntlMessages from "util/IntlMessages";
import AdminNotifications from "components/AdminNotifications";
import {
  Card,
  Table,
  Row,
  Col,
  Button,
  Tooltip,
  Modal,
  Space,
  Input,
  Popover,
  Checkbox,
  Badge,
  Divider,
  Typography,
} from "antd";
import {
  FilterFilled,
  MessageOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  BellTwoTone,
  MailTwoTone,
  MedicineBoxOutlined,
  DownSquareTwoTone,
  SaveTwoTone,
  CheckSquareTwoTone,
  DeleteTwoTone,
  CloseSquareTwoTone,
  MailOutlined,
} from "@ant-design/icons";
import {
  getPartnerComplianceList,
  sendPartnerComplianceReminder,
  sendPartnerComplianceMail,
  downloadTemplatePDF,
  downloadSignedTemplatePDF,
  verifyPartnerSignedLetter,
  rejectPartnerSignedLetter,
  deleteAssignedParnterCompliance,
  sendBulkReminderParnterCompliance,
  getNotificationList,
  partnerComplianceMarkNotificationRead,
} from "../../../appRedux/actions";
import {
  showConfirm,
  getRole,
  showMessage,
  getUserInfo,
} from "../../../helpers";
import TextArea from "antd/lib/input/TextArea";
import { getEmailTemplate, putEmailTemplate } from "../../../services";
import Chats from "../chats";

const PartnerCompliance = (props) => {
  const isLoaded = useSelector((state) => state.partnerCompliance.isLoaded);
  const notifications = useSelector(
    (state) => state.partnerCompliance.notification
  );
  useEffect(() => {
    setNotificationCount(notifications.count);
  }, [notifications]);
  const [notificationCount, setNotificationCount] = useState(
    notifications.count
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);

  const [emailData, setEmailData] = useState({
    subject: "",
    body1: "",
  });
  const partnerComplianceTemplateList = useSelector(
    (state) => state.partnerCompliance.PartnerComplianceList
  );
  const dispatch = useDispatch();
  const [partner, setPartner] = useState();
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserLegal, setIsUserLegal] = useState(false);
  const [isUserPartner, setIsUserPartner] = useState(false);
  const [data, setData] = useState([]);
  const [requestObj, setRequestObj] = useState({
    page_number: 1,
    page_size: 10,
  });
  const [recordTotal, setRecordTotal] = useState(0);
  const [quarterDropdown, setQuarterDropdown] = useState([]);
  const [currentQuarter, setCurrentQuarter] = useState("");
  const [currentYear, setCurrentYear] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [searchedColumn, setSearchedColumn] = useState();
  const [filterValue, setFilterValue] = useState("");
  const [tempFilterValue, setTempFilterValue] = useState("");
  const [verficationStatus, setVerficationStatus] = useState("");
  const [tempVerficationStatus, setTempVerficationStatus] = useState("");
  const [tableSearch, setTableSearch] = useState({});

  useEffect(() => {
    setUserDetails(getUserInfo());
    setIsUserAdmin(() => {
      return getRole() === "ADMIN";
    });
    setIsUserLegal(() => {
      return getRole() === "LEGAL";
    });
    setIsUserPartner(() => {
      return getRole() === "PARTNER";
    });
    if (currentQuarter) {
      dispatch(
        getPartnerComplianceList({
          ...requestObj,
          quarter: currentQuarter,
          ...tableSearch,
          status: filterValue,
          verification_status: verficationStatus,
        })
      );
    } else {
      dispatch(
        getPartnerComplianceList({
          ...requestObj,
          ...tableSearch,
          status: filterValue,

          verification_status: verficationStatus,
        })
      );
    }
    dispatch(getNotificationList());
  }, [requestObj, currentQuarter, tableSearch, filterValue, verficationStatus]);

  const getFinalPartnerComplianceList = (partnerComplianceArray) => {
    let data = [];
    partnerComplianceArray.forEach((val) => {
      if (
        val.html_templates_assigned &&
        val.html_templates_assigned.length > 0
      ) {
        for (let i = 0; i < val.html_templates_assigned.length; i++) {
          data.push({
            partner_id: val.partner_id,
            name: val.company_name,
            email: val.email,
            template_name: val.html_templates_assigned[i].template_name,
            status: val.html_templates_assigned[i].status,
            template_history_id:
              val.html_templates_assigned[i].template_history_id,
            template_assigned: true,
            download_status: val.html_templates_assigned[i].download_status,

            reminder_status: val.html_templates_assigned[i].reminder_status,
            template_id: val.html_templates_assigned[i].template_id,
            verification_status: val.html_templates_assigned[i]
              .verification_status
              ? val.html_templates_assigned[i].verification_status
              : "-",
            quarter_year: parseInt(val.quarter_year, 10),
          });
        }
      } else {
        data.push({
          partner_id: val.partner_id,
          name: val.company_name,
          email: val.email,
          template_name: "Not Assigned",
          status: "N.A",
          template_assigned: false,
          verification_status: "-",
          quarter_year: parseInt(val.quarter_year, 10),
        });
      }
    });
    return data;
  };

  useEffect(() => {
    let complianceTemplateData = partnerComplianceTemplateList.results;
    let partnercomplianceData = complianceTemplateData
      ? complianceTemplateData.rows
      : [];
    const partnerTotalRecords = partnerComplianceTemplateList.count
      ? partnerComplianceTemplateList.count
      : 0;
    const finalPartnerComplianceData = getFinalPartnerComplianceList(
      partnercomplianceData
    );
    const quarterFilters = complianceTemplateData
      ? complianceTemplateData.filters.year
      : [];

    const currentYearNum = complianceTemplateData
      ? complianceTemplateData.current_year
      : null;

    setData(finalPartnerComplianceData);
    setRecordTotal(partnerTotalRecords);
    if (currentYearNum) {
      setCurrentYear(currentYearNum);
      setQuarterDropdown(quarterFilters);
    }
  }, [isLoaded]);

  const handleTableChange = (pagination, columns) => {
    const { current, pageSize } = pagination;
    setRequestObj({
      ...{
        page_size: pageSize,
        page_number: current,
      },
      ...tableSearch,
      status: filterValue,
      verification_status: verficationStatus,
    });
  };

  const onEmailTemplateChange = (e) => {
    if (e.target.id === "subject") {
      setEmailData({ ...emailData, subject: e.target.value });
    }
    if (e.target.id === "body1") {
      setEmailData({ ...emailData, body1: e.target.value });
    }
  };

  const navigateToAssignTemplate = () => {
    const { history } = props;
    history.push("/admin/compliance-template/assign");
  };

  const sendReminder = (item) => {
    const sendReminderData = {
      partner_id: item.partner_id,
    };
    dispatch(sendPartnerComplianceReminder(sendReminderData));
  };

  const showModal = (item) => {
    setPartner(item);
    getEmailTemplate(item.partner_id)
      .then((response) => {
        setEmailData({
          subject: response.subject,
          body1: response.body1,
        });
        setIsModalOpen(true);
      })
      .catch((err) => {
        console.log(err.toString());
        showMessage("error", err.toString());
      });
  };

  const showChatModal = (item) => {
    setPartner(item);
    setChatOpen(true);
  };
  const handleOk = () => {
    putEmailTemplate(partner.partner_id, emailData)
      .then((response) => {
        if (response) {
          sendMail(partner);
          setIsModalOpen(false);
        }
      })
      .catch((err) => {
        console.log(err.toString());
        showMessage("error", err.toString());
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const sendMail = (item) => {
    dispatch(
      sendPartnerComplianceMail(
        item.partner_id,
        { template_id: item.template_id },
        () => {
          if (currentQuarter) {
            dispatch(
              getPartnerComplianceList({
                ...requestObj,
                quarter: currentQuarter,
                ...tableSearch,
                status: filterValue,
                verification_status: verficationStatus,
              })
            );
            setIsModalOpen(false);
          } else {
            dispatch(
              getPartnerComplianceList({
                ...requestObj,
                ...tableSearch,
                status: filterValue,
                verification_status: verficationStatus,
              })
            );
          }
        }
      )
    );
  };

  const downloadTemplate = (item) => {
    const sendDownloadData = {
      template_history_id: item.template_history_id,
      templates_pdf: "true",
      signed_pdf: "false",
    };

    dispatch(downloadTemplatePDF(item.template_name, sendDownloadData));
  };

  const downloadSignedTemplate = (item) => {
    const sendSignedDownloadData = {
      template_history_id: item.template_history_id,
      templates_pdf: "false",
      signed_pdf: "true",
    };

    dispatch(
      downloadSignedTemplatePDF(item.template_name, sendSignedDownloadData)
    );
  };

  const verifySignedLetter = (item) => {
    const verifySignedData = {
      partner_id: item.partner_id,
      message: "-",
      template_history_id: item.template_history_id,
      template_id: item.template_id,
      is_approved: true,
    };
    dispatch(
      verifyPartnerSignedLetter(verifySignedData, () => {
        if (currentQuarter) {
          dispatch(
            getPartnerComplianceList({
              ...requestObj,
              quarter: currentQuarter,
              ...tableSearch,
              status: filterValue,
              verification_status: verficationStatus,
            })
          );
        } else {
          dispatch(
            getPartnerComplianceList({
              ...requestObj,
              ...tableSearch,
              status: filterValue,
              verification_status: verficationStatus,
            })
          );
        }
      })
    );
  };

  const rejectSignedLetter = (item) => {
    const rejectSignedData = {
      partner_id: item.partner_id,
      message: "-",
      template_history_id: item.template_history_id,
      template_id: item.template_id,
      is_approved: false,
    };

    dispatch(
      rejectPartnerSignedLetter(rejectSignedData, () => {
        if (currentQuarter) {
          dispatch(
            getPartnerComplianceList({
              ...requestObj,
              quarter: currentQuarter,
              ...tableSearch,
              status: filterValue,
              verification_status: verficationStatus,
            })
          );
        } else {
          dispatch(
            getPartnerComplianceList({
              ...requestObj,
              ...tableSearch,
              status: filterValue,
              verification_status: verficationStatus,
            })
          );
        }
      })
    );
  };

  const deletePartnerTemplate = (item) => {
    const deleteData = {
      template_id: item.template_id,
      partner_id: item.partner_id,
    };
    showConfirm(
      "Do you want to delete " + item.name.toUpperCase() + " ?",
      () => {
        dispatch(
          deleteAssignedParnterCompliance(deleteData, () => {
            if (currentQuarter) {
              dispatch(
                getPartnerComplianceList({
                  ...requestObj,
                  quarter: currentQuarter,
                  ...tableSearch,
                  status: filterValue,
                  verification_status: verficationStatus,
                })
              );
            } else {
              dispatch(
                getPartnerComplianceList({
                  ...requestObj,
                  ...tableSearch,
                  status: filterValue,
                  verification_status: verficationStatus,
                })
              );
            }
          })
        );
      }
    );
  };

  const sendBulkReminder = () => {
    dispatch(sendBulkReminderParnterCompliance({}));
  };

  const mergeCell = (data) => {
    const output_res = data
      .reduce((result, item) => {
        if (result.indexOf(item.email) < 0) {
          result.push(item.email);
        }
        return result;
      }, [])
      .reduce((result, email) => {
        const children = data.filter((item) => item.email === email);
        result = result.concat(
          children.map((item, index) => ({
            ...item,
            rowSpan: index === 0 ? children.length : 0,
          }))
        );
        return result;
      }, []);
    return output_res;
  };

  const changeQuarter = (e) => {
    const resetPageState = { page_number: 1, page_size: 10 };
    setRequestObj(resetPageState);
    if (e.target.value !== "0") {
      setCurrentQuarter(e.target.value);
    } else {
      setCurrentQuarter("");
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setTableSearch(selectedKeys);

    if (currentQuarter) {
      dispatch(
        getPartnerComplianceList({
          ...requestObj,
          ...selectedKeys,
          quarter: currentQuarter,
          status: filterValue,
          verification_status: verficationStatus,
        })
      );
    } else {
      dispatch(
        getPartnerComplianceList({
          ...requestObj,
          ...selectedKeys,
          status: filterValue,
          verification_status: verficationStatus,
        })
      );
    }
  };

  const handleReset = (dataIndex) => {
    setTableSearch({ ...tableSearch, [dataIndex]: undefined });
    setRequestObj({
      page_size: requestObj?.page_size,
      page_number: 1,
    });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={tableSearch[dataIndex]}
          onChange={(e) =>
            setSelectedKeys({ ...tableSearch, [dataIndex]: e?.target.value })
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const onSetFilter = (checkedValues) => {
    setTempFilterValue(checkedValues?.join(","));
  };

  const getFilteredData = () => {
    setFilterValue(tempFilterValue);
    if (currentQuarter) {
      dispatch(
        getPartnerComplianceList({
          ...requestObj,
          quarter: currentQuarter,
          ...tableSearch,
          status: tempFilterValue,
          verification_status: verficationStatus,
        })
      );
    } else {
      dispatch(
        getPartnerComplianceList({
          ...requestObj,
          ...tableSearch,
          status: tempFilterValue,
          verification_status: verficationStatus,
        })
      );
    }
  };

  const onSetVerificationStatus = (checkedValues) => {
    setTempVerficationStatus(checkedValues?.join(","));
  };

  const getVerificationStatusData = () => {
    setVerficationStatus(tempVerficationStatus);
    if (currentQuarter) {
      dispatch(
        getPartnerComplianceList({
          ...requestObj,
          quarter: currentQuarter,
          ...tableSearch,
          status: filterValue,
          verification_status: tempVerficationStatus,
        })
      );
    } else {
      dispatch(
        getPartnerComplianceList({
          ...requestObj,
          ...tableSearch,
          status: filterValue,
          verification_status: tempVerficationStatus,
        })
      );
    }
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("company_name"),
      render(_, row) {
        return {
          children: row.name,
          props: {
            rowSpan: row.rowSpan,
          },
        };
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      render(_, row) {
        return {
          children: row.email,
          props: {
            rowSpan: row.rowSpan,
          },
        };
      },
    },

    {
      title: "Templates Assigned",
      children: [
        {
          title: "Name",
          dataIndex: "template_name",
          key: 1,
          ...getColumnSearchProps("template_filter"),
        },
        {
          title: (
            <div>
              Status
              <Popover
                placement="bottom"
                trigger="click"
                content={
                  <>
                    <Checkbox.Group
                      style={{ width: "100%" }}
                      onChange={onSetFilter}
                      value={tempFilterValue?.split(",")}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <Checkbox
                          style={{ marginLeft: 15, marginBottom: 10 }}
                          value="Received"
                        >
                          Received
                        </Checkbox>

                        <Checkbox
                          style={{ marginLeft: 15, marginBottom: 10 }}
                          value="Sent"
                        >
                          Sent
                        </Checkbox>

                        <Checkbox
                          style={{ marginLeft: 15, marginBottom: 10 }}
                          value="Not Sent"
                        >
                          Not Sent
                        </Checkbox>
                      </Row>
                    </Checkbox.Group>

                    <Space>
                      <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                        onClick={() => getFilteredData()}
                      >
                        Search
                      </Button>
                      <Button
                        onClick={() => (
                          setFilterValue(""), setTempFilterValue("")
                        )}
                        size="small"
                        style={{ width: 90 }}
                      >
                        Reset
                      </Button>
                    </Space>
                  </>
                }
              >
                <FilterFilled
                  style={{
                    color: "#d9d9d9",
                    cursor: "pointer",
                    marginLeft: 15,
                  }}
                />
              </Popover>
            </div>
          ),
          dataIndex: "status",
          key: 2,
          align: "center",
        },
        {
          title: (
            <div>
              Verification Status
              <Popover
                placement="bottom"
                trigger="click"
                content={
                  <>
                    <Checkbox.Group
                      style={{ width: "100%" }}
                      onChange={onSetVerificationStatus}
                      value={tempVerficationStatus?.split(",")}
                    >
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <Checkbox
                          style={{ marginLeft: 15, marginBottom: 10 }}
                          value="Verified"
                        >
                          Verified
                        </Checkbox>
                        <Checkbox
                          style={{ marginLeft: 15, marginBottom: 10 }}
                          value="Rejected"
                        >
                          Rejected
                        </Checkbox>
                      </Row>
                    </Checkbox.Group>

                    <Space>
                      <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                        onClick={() => getVerificationStatusData()}
                      >
                        Search
                      </Button>
                      <Button
                        onClick={() => (
                          setTempVerficationStatus(""), setVerficationStatus("")
                        )}
                        size="small"
                        style={{ width: 90 }}
                      >
                        Reset
                      </Button>
                    </Space>
                  </>
                }
              >
                <FilterFilled
                  style={{ color: "#d9d9d9", cursor: "pointer", marginLeft: 4 }}
                />
              </Popover>
            </div>
          ),
          dataIndex: "verification_status",
          key: 3,
          align: "center",
        },
      ],
    },

    {
      title: "Action",
      key: "operation",
      align: "center",
      width: "450px",
      render: (item) => {
        return (
          <div style={{ display: "inline-flex" }}>
            {isUserPartner || isUserAdmin ? (
              <Button
                type="link"
                className="margin-0"
                onClick={() => showChatModal(item)}
                id={"sendmail-" + item.key}
                disabled={
                  (isUserAdmin || isUserLegal ? false : true) ||
                  -!item.template_assigned ||
                  item.quarter_year < currentYear
                }
              >
                <Tooltip title="Chats">
                  <MessageOutlined twoToneColor="#00AEEF" className="font-20" />
                </Tooltip>
              </Button>
            ) : (
              <></>
            )}
            <Button
              type="link"
              className="margin-0"
              onClick={() => showModal(item)}
              id={"sendmail-" + item.key}
              disabled={
                (isUserAdmin || isUserLegal ? false : true) ||
                !item.template_assigned ||
                item.quarter_year < currentYear
              }
            >
              <Tooltip title="Send Template">
                <MailTwoTone twoToneColor="#00AEEF" className="font-20" />
              </Tooltip>
            </Button>

            <Button
              type="link"
              className="margin-0"
              onClick={() => sendReminder(item)}
              id={"reminder-" + item.key}
              disabled={
                (isUserAdmin || isUserLegal ? false : true) ||
                !item.reminder_status ||
                item.quarter_year < currentYear
              }
            >
              <Tooltip title="Send Reminder">
                <BellTwoTone twoToneColor="#00AEEF" className="font-20" />
              </Tooltip>
            </Button>
            <Button
              type="link"
              className="margin-0"
              onClick={() => downloadTemplate(item)}
              id={"download-template-" + item.key}
              disabled={
                (isUserAdmin || isUserLegal ? false : true) ||
                !item.reminder_status ||
                !item.template_assigned
              }
            >
              <Tooltip title="Download">
                <SaveTwoTone twoToneColor="#00AEEF" className="font-20" />
              </Tooltip>
            </Button>
            <Button
              type="link"
              className="margin-0"
              onClick={() => downloadSignedTemplate(item)}
              id={"download-signed-" + item.key}
              disabled={
                (isUserAdmin || isUserLegal ? false : true) ||
                !item.template_assigned ||
                (item.template_assigned && !item.download_status)
              }
            >
              <Tooltip title="Download Signed PDF">
                <DownSquareTwoTone twoToneColor="#00AEEF" className="font-20" />
              </Tooltip>
            </Button>
            <Button
              type="link"
              className="margin-0"
              onClick={() => verifySignedLetter(item)}
              id={"verify-signed-" + item.key}
              disabled={
                (isUserAdmin || isUserLegal ? false : true) ||
                !item.template_assigned ||
                item.quarter_year < currentYear ||
                (item.template_assigned && !item.download_status)
              }
            >
              <Tooltip title="Verify">
                <CheckSquareTwoTone
                  twoToneColor="#52c41a"
                  className="font-20"
                />
              </Tooltip>
            </Button>
            <Button
              type="link"
              className="margin-0"
              onClick={() => rejectSignedLetter(item)}
              id={"reject-signed-" + item.key}
              disabled={
                (isUserAdmin || isUserLegal ? false : true) ||
                !item.template_assigned ||
                item.quarter_year < currentYear ||
                (item.template_assigned && !item.download_status)
              }
            >
              <Tooltip title="Reject">
                <CloseSquareTwoTone
                  twoToneColor="#f44336"
                  className="font-20"
                />
              </Tooltip>
            </Button>

            <Button
              type="link"
              className="margin-0"
              onClick={() => deletePartnerTemplate(item)}
              id={"delete-" + item.key}
              disabled={
                (isUserAdmin || isUserLegal ? false : true) ||
                !item.template_assigned ||
                item.quarter_year < currentYear
              }
            >
              <Tooltip title="Delete Template">
                <DeleteTwoTone twoToneColor="#f44336" className="font-20" />
              </Tooltip>
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Col span={24}>
        <div className="gx-flex-row align-items-baseline">
          <h1 className="title gx-mb-4">
            <IntlMessages id="sidebar.partner-compliance" />
          </h1>
          <Badge count={notificationCount} className="gx-ml-auto">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={
                <AdminNotifications
                  isAdmin={isUserAdmin}
                  data={notifications?.items}
                  title={"Notifications"}
                  isPartnerCompliance={true}
                />
              }
              trigger="click"
            >
              <Button
                className="mr-0"
                id="pdt-msg-box"
                style={{ margin: "3px" }}
                onClick={() =>
                  dispatch(
                    partnerComplianceMarkNotificationRead(() =>
                      setNotificationCount(0)
                    )
                  )
                }
              >
                <BellTwoTone />
              </Button>
            </Popover>
          </Badge>

          <Button
            id="add-product-btn"
            type="primary"
            onClick={navigateToAssignTemplate}
            disabled={isUserAdmin || isUserLegal ? false : true}
          >
            <MedicineBoxOutlined /> Assign Template
          </Button>
          {data && (
            <Button
              id="send-bulk-reminder-btn"
              type="primary"
              onClick={sendBulkReminder}
              disabled={isUserAdmin || isUserLegal ? false : true}
            >
              <MailOutlined />
              Send Bulk Reminder
            </Button>
          )}
          <div className="quarter-dropdown-container-admin-pdt">
            <select onChange={changeQuarter} className="quarter-dropdown">
              {quarterDropdown.map((quarter) => {
                if (quarter.quarter_year >= 2022) {
                  return (
                    <option
                      value={quarter.quarter_year}
                      className="quarter-dropdown-options"
                    >
                      {quarter.quarter_year}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <Card className="mb-0">
          <Modal
            title="Chats"
            centered
            visible={chatOpen}
            onCancel={() => setChatOpen(false)}
            footer={false}
            width={1000}
          >
            <Chats
              heading={true}
              partner_id={partner?.partner_id}
              template_id={partner?.template_id}
              userId={userDetails.user_id}
            />
            <Divider />
            <Typography.Link
              href={`/admin/chats?template_id=${partner?.template_id}&partner_id=${partner?.partner_id}&user_id=${userDetails.user_id}`}
              target="_blank"
            >
              Full view
            </Typography.Link>
          </Modal>
          <Modal
            title="Email"
            visible={isModalOpen}
            width={1000}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={handleOk}>
                Send
              </Button>,
            ]}
          >
            <label for="subject">Subject</label>
            <Input
              placeholder="Subject"
              id="subject"
              value={emailData.subject}
              style={{
                marginBottom: 20,
              }}
              onChange={onEmailTemplateChange}
            />
            <label for="body1">Body</label>
            <TextArea
              value={emailData.body1}
              className="my-select"
              showCount
              id="body1"
              onChange={onEmailTemplateChange}
              style={{
                // marginBottom: 20,
                // paddingBottom: 50,
                // resize: "none",
                height: 150,
              }}
              maxLength={500}
              placeholder="Body"
            />
          </Modal>
          <Table
            className="gx-table-responsive mpp-list-table"
            columns={columns}
            dataSource={mergeCell(data)}
            pagination={{
              pageSize: data.length,
              current: requestObj.page_number,
              total: data.length * Math.ceil(recordTotal / 10),
            }}
            // loading={!isLoaded}
            onChange={handleTableChange}
            bordered
          />
        </Card>
      </Col>
    </div>
  );
};

export default PartnerCompliance;
