export const partnerComplianceLetterConstants = {
  GET_PARTNER_COMPLIANCE_LETTER_REQUEST:
    "GET_PARTNER_COMPLIANCE_LETTER_REQUEST",
  GET_PARTNER_COMPLIANCE_LETTER_SUCCESS:
    "GET_PARTNER_COMPLIANCE_LETTER_SUCCESS",
  GET_PARTNER_COMPLIANCE_LETTER_FAILURE:
    "GET_PARTNER_COMPLIANCE_LETTER_FAILURE",

  DOWNLOAD_PARTNER_LETTER_REQUEST: "DOWNLOAD_PARTNER_LETTER_REQUEST",
  DOWNLOAD_PARTNER_LETTER_SUCCESS: "DOWNLOAD_PARTNER_LETTER_SUCCESS",
  DOWNLOAD_PARTNER_LETTER_FAILURE: "DOWNLOAD_PARTNER_LETTER_FAILURE",

  SEND_PARTNER_SIGNED_LETTER_REQUEST: "SEND_PARTNER_SIGNED_LETTER_REQUEST",
  SEND_PARTNER_SIGNED_LETTER_SUCCESS: "SEND_PARTNER_SIGNED_LETTER_SUCCESS",
  SEND_PARTNER_SIGNED_LETTER_FAILURE: "SEND_PARTNER_SIGNED_LETTER_FAILURE",
};
