import { config } from "../constants";
import { authHeader } from "../helpers";
import { handleResponse } from "./handle-response";

export const getPartnerComplianceLetterList = (data) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let getURL = `${config.API_URL}/compliance-letter/partner/`;
  if (data.quarter) {
    getURL = `${config.API_URL}/compliance-letter/partner/?year=${data.quarter}`;
  }

  return fetch(getURL, requestOptions).then(handleResponse);
};

export const downloadPartnerLetters = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.API_URL}/download-compliance-letter/`,
    requestOptions
  ).then((response) => response.json());
};

export const sendPartneSignedLetter = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
    redirect: "follow",
  };

  return fetch(
    `${config.API_URL}/upload-compliance-letter/partner/`,
    requestOptions
  ).then(handleResponse);
};

export const sendNotificationForComplianceLetter = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
    redirect: "follow",
  };

  return fetch(
    `${config.API_URL}/partner/notification-update/`,
    requestOptions
  ).then(handleResponse);
};
