export const InteractiveMapConstants = {

  PRODUCT_AVAILABILITIES_REQUEST: "PRODUCT_AVAILABILITIES_REQUEST",
  PRODUCT_AVAILABILITIES_SUCCESS: "PRODUCT_AVAILABILITIES_SUCCESS",
  PRODUCT_AVAILABILITIES_FAILURE: "PRODUCT_AVAILABILITIES_FAILURE",

  PRODUCT_DEVELOPMENT_REQUEST: "PRODUCT_DEVELOPMENT_REQUEST",
  PRODUCT_DEVELOPMENT_SUCCESS: "PRODUCT_DEVELOPMENT_SUCCESS",
  PRODUCT_DEVELOPMENT_FAILURE: "PRODUCT_DEVELOPMENT_FAILURE",

  SYNC_INTERACTIVE_ENTRY_REQUEST: "SYNC_INTERACTIVE_ENTRY_REQUEST",
  SYNC_INTERACTIVE_ENTRY_SUCCESS: "SYNC_INTERACTIVE_ENTRY_SUCCESS",
  SYNC_INTERACTIVE_ENTRY_FAILURE: "SYNC_INTERACTIVE_ENTRY_FAILURE",
 
  INTERACTIVE_MAP_LOGS_REQUEST: "INTERACTIVE_MAP_LOGS_REQUEST",
  INTERACTIVE_MAP_LOGS_SUCCESS: "INTERACTIVE_MAP_LOGS_SUCCESS",
  INTERACTIVE_MAP_LOGS_FAILURE: "INTERACTIVE_MAP_LOGS_FAILURE",
}