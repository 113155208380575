import * as FileSaver from "file-saver";
import { InteractiveMapConstants } from "../../constants";
import { hideLoader, showLoader } from "./Loader";
import {
  productAvailabilities,
  productDevelopment,
  syncInteractiveEntry,
  getInteractiveMapLogs
} from "../../services";
import { showMessage } from "../../helpers";

export const interactiveMapLogs = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: InteractiveMapConstants.INTERACTIVE_MAP_LOGS_REQUEST,
    });
    getInteractiveMapLogs(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: InteractiveMapConstants.INTERACTIVE_MAP_LOGS_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: InteractiveMapConstants.INTERACTIVE_MAP_LOGS_FAILURE,
        });
        console.log(error);
      });
  };
};


export const productAvailabilitiesLogs = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: InteractiveMapConstants.PRODUCT_AVAILABILITIES_REQUEST,
    });
    productAvailabilities(data)
      .then((response) => {
        dispatch(showLoader());
        dispatch({
          type: InteractiveMapConstants.PRODUCT_AVAILABILITIES_SUCCESS,
        });
        if(response) {
          dispatch(hideLoader());
          showMessage("success", "Product availabilities successfully updated");
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: InteractiveMapConstants.PRODUCT_AVAILABILITIES_FAILURE,
        });
        console.log(error);
      });
  };
};

export const productDevelopmentLogs = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: InteractiveMapConstants.PRODUCT_DEVELOPMENT_REQUEST,
    });
    productDevelopment(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: InteractiveMapConstants.PRODUCT_DEVELOPMENT_SUCCESS,
        });
        if(response) {
          showMessage("success", "Product development successfully updated");
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: InteractiveMapConstants.PRODUCT_DEVELOPMENT_FAILURE,
        });
        console.log(error);
      });
  };
};

export const syncInteractiveEntryLogs = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: InteractiveMapConstants.SYNC_INTERACTIVE_ENTRY_REQUEST,
    });
    syncInteractiveEntry(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: InteractiveMapConstants.SYNC_INTERACTIVE_ENTRY_SUCCESS,
        });
        if(response) {
          showMessage("success", "Interactive entry synced successfully");
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: InteractiveMapConstants.SYNC_INTERACTIVE_ENTRY_FAILURE,
        });
        console.log(error);
      });
  };
};
