import { royaltySalesConstants } from "../../constants";

const initialSettings = {
  partnersProductList: [],
  salesrequestdata: [],
  salesmappeddata: [],
  editSalesRequestData: [],
  isSalesRequestEdited: false,
  isSalesRequestDeleted: false,
  isSalesRequestAdded: false,
  isRoyaltySalesLoaded: false

};

const RoyaltySales = (state = initialSettings, action) => {
  switch (action.type) {
    case royaltySalesConstants.GET_PARTNERS_PRODUCT_LIST_REQUEST:
      return {
        ...state,
      };
    case royaltySalesConstants.GET_PARTNERS_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        partnersProductList: action.data,
      };
    case royaltySalesConstants.GET_PARTNERS_PRODUCT_LIST_FAILURE:
      return {
        ...state,
      };
    case royaltySalesConstants.GET_SALES_REQUEST_DATA_REQUEST:
      return {
        ...state,
        isRoyaltySalesLoaded: false
      };
    case royaltySalesConstants.GET_SALES_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        salesrequestdata: action.data,
        isRoyaltySalesLoaded: true
      };
    case royaltySalesConstants.GET_SALES_REQUEST_DATA_FAILURE:
      return {
        ...state,
        isRoyaltySalesLoaded: false
      };
    case royaltySalesConstants.GET_SALES_MAPPED_DATA_REQUEST:
      return {
        ...state,
        isRoyaltySalesMappedLoaded: false
      };
    case royaltySalesConstants.GET_SALES_MAPPED_DATA_SUCCESS:
      return {
        ...state,
        salesmappeddata: action.data,
        isRoyaltySalesMappedLoaded: true
      };
    case royaltySalesConstants.GET_SALES_MAPPED_DATA_FAILURE:
      return {
        ...state,
        isRoyaltySalesMappedLoaded: false
      };
      case royaltySalesConstants.EDIT_SALES_REQUEST_REQUEST:
      return {
        ...state,
        isSalesRequestEdited: false,
      };
    case royaltySalesConstants.EDIT_SALES_REQUEST_SUCCESS:
      return {
        ...state,
        isSalesRequestEdited: true,
      };
    case royaltySalesConstants.EDIT_SALES_REQUEST_FAILURE:
      return {
        ...state,
        isSalesRequestEdited: false,
      };
    case royaltySalesConstants.DELETE_SALES_REQUEST_DATA_REQUEST:
      return {
        ...state,
        isSalesRequestDeleted: false,
      };
    case royaltySalesConstants.DELETE_SALES_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        isSalesRequestDeleted: true,
      };
    case royaltySalesConstants.DELETE_SALES_REQUEST_DATA_FAILURE:
      return {
        ...state,
        isSalesRequestDeleted: false,
      };

    case royaltySalesConstants.RAISE_SALES_REQUEST_REQUEST:
      return {
        ...state,
        isSalesRequestAdded: false,
      };
    case royaltySalesConstants.RAISE_SALES_REQUEST_SUCCESS:
      return {
        ...state,
        isSalesRequestAdded: true,
      };
    case royaltySalesConstants.RAISE_SALES_REQUEST_FAILURE:
      return {
        ...state,
        isSalesRequestAdded: false,
      };
    case royaltySalesConstants.SINGLE_SALE_VALUE_REQUEST:
      return {
        ...state,
        isSalesRequestAdded: false,
      };
    case royaltySalesConstants.SINGLE_SALE_VALUE_SUCCESS:
      return {
        ...state,
        isSalesRequestAdded: true,
        editSalesRequestData: action.data
      };
    case royaltySalesConstants.SINGLE_SALE_VALUE_FAILURE:
      return {
        ...state,
        isSalesRequestAdded: false,
      };

    default:
      return state;
  }
};

export default RoyaltySales;
