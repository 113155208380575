import { partnerComplianceLetterConstants } from "../../constants";

const initialSettings = {
  PartnerComplianceLetterList: [],
  isLoaded: false,
};

const PartnerComplianceLetter = (state = initialSettings, action) => {
  switch (action.type) {
    case partnerComplianceLetterConstants.GET_PARTNER_COMPLIANCE_LETTER_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case partnerComplianceLetterConstants.GET_PARTNER_COMPLIANCE_LETTER_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        PartnerComplianceLetterList: action.data,
      };
    case partnerComplianceLetterConstants.GET_PARTNER_COMPLIANCE_LETTER_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    case partnerComplianceLetterConstants.DOWNLOAD_PARTNER_LETTER_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceLetterConstants.DOWNLOAD_PARTNER_LETTER_SUCCESS:
      return {
        ...state,
      };
    case partnerComplianceLetterConstants.DOWNLOAD_PARTNER_LETTER_FAILURE:
      return {
        ...state,
      };
    case partnerComplianceLetterConstants.SEND_PARTNER_SIGNED_LETTER_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceLetterConstants.SEND_PARTNER_SIGNED_LETTER_SUCCESS:
      return {
        ...state,
      };
    case partnerComplianceLetterConstants.SEND_PARTNER_SIGNED_LETTER_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default PartnerComplianceLetter;
