import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Card,
  DatePicker,
  Upload,
  message,
} from "antd";
import { LeftOutlined, InboxOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminCountryList,
  getPartnersProductList,
  raiseSalesRequest,
  getSingleSalesRequestData,
  editRoyaltySalesRequestData,
  deleteSalesRequestData,
} from "../../../appRedux/actions";
import { royalty_countries_product } from "../../../constants";
import moment from "moment";
import { getUserDetails } from "../../../helpers";

const { TextArea } = Input;
const { Dragger } = Upload;

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const RaiseApprovalRequest = (props) => {
  const { editSalesRequestData } = useSelector(
    ({ royaltySales }) => royaltySales
  );
  const [editFlag, setEditFlag] = useState(false);
  const [products, setProducts] = useState([]);
  const [editedFields, setEditedFields] = useState({});

  const [userDetails, setUserDetails] = useState(null);
  useEffect(() => {
    setUserDetails(getUserDetails())
  }, [])


  const handleFieldChange = (changedValues) => {
    setEditedFields((prevEditedFields) => ({
      ...prevEditedFields,
      ...changedValues,
    }));
  };
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const navigateToPartnerList = () => {
    const { history } = props;
    history.push("/partner/royalty-sales-approvals");
  };

  useEffect(() => {
    // Set initial values when editSalesRequestData changes
    form.setFieldsValue({
      country_id: editSalesRequestData?.country_id?.country_id,
      product_id: editSalesRequestData?.product_id?.product_name,
      expected_date_of_sale: moment(
        editSalesRequestData?.expected_date_of_sale
      ),
      pack_size: editSalesRequestData?.pack_size,
      quantity: editSalesRequestData?.quantity,
      buyer_name_and_address: editSalesRequestData?.buyer_name_and_address,
      end_customer_and_address: editSalesRequestData?.end_customer_and_address,
      proof_of_sale_details: editSalesRequestData?.proof_of_sale_details,
      file: editSalesRequestData?.file?.map((file) => ({
        uid: file.po_id,
        name: file.po_document.split("/").pop(),
        status: "done",
        url: file.po_document,
      })),
    });
  }, [editSalesRequestData]);

  const handleCountryChange = (value) => {
    // Find the selected country data
    const selectedCountry = royalty_countries_product.find(
      (country) => country.country_id === value
    );
    setProducts(selectedCountry ? selectedCountry.product : []);
    form.setFieldsValue({ product: undefined });
  };

  // Edit request products product list
  const getProduct = (value) => {
    const selectedCountry = royalty_countries_product.find(
      (country) => country.country_id === value
    );
    return selectedCountry ? selectedCountry.product : products
  }

  const handleRemove = (file) => {
    if (file.url) {
      dispatch(deleteSalesRequestData(file.uid));
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const checkFile = (file) => {
    const allowedFileTypes = ["application/pdf", "image/jpeg", "image/jpg", "image/png"];
    const isTypeValid = allowedFileTypes.includes(file.type);
    const isSizeValid = file.size / 1024 / 1024 <= 15;

    if (!isTypeValid) {
      message.error("Please upload a PDF, JPEG, or PNG document!");
    } else if (!isSizeValid) {
      message.error("File size must be less than or equal to 15 MB!");
    }

    return isTypeValid && isSizeValid;
  };

  const fetchData = async () => {
    await dispatch(getAdminCountryList());
    await dispatch(getPartnersProductList());

    const { path, params } = props.match;
    if (params.id && path.split("/")[3] === "edit-request") {
      await dispatch(getSingleSalesRequestData(params.id));
      setEditFlag(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Check if editSalesRequestData has been fetched and set the initial product values
    if (editSalesRequestData && editSalesRequestData.country_id) {
      const selectedCountry = royalty_countries_product.find(
        (country) => country.country_id === editSalesRequestData.country_id
      );
      setProducts(selectedCountry ? selectedCountry.product : []);
    }
  }, [editSalesRequestData]);

  const navigateBack = () => {
    const { history } = props;
    history.goBack();
  };

  const onFinish = (values) => {
    const { params } = props.match;
    const formData = new FormData();
    if (!editFlag) {
      values.file.map((data) => {
        formData.append("file", data.originFileObj);
      });
      formData.append("proof_of_sale_details", values.proof_of_sale_details);
      formData.append(
        "expected_date_of_sale",
        values.expected_date_of_sale.toISOString()
      );
      formData.append("pack_size", values.pack_size);
      formData.append("quantity", values.quantity);
      formData.append("buyer_name_and_address", values.buyer_name_and_address);
      formData.append(
        "end_customer_and_address",
        values.end_customer_and_address
      );
      formData.append("product_id", values.product_id);
      formData.append("country_id", values.country_id);
      dispatch(raiseSalesRequest(formData, navigateBack));
    } else {
      Object.entries(editedFields).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          // Filter files that have originFileObj
          const filesWithOriginFileObj = value.filter(
            (file) => file.originFileObj
          );
          filesWithOriginFileObj.forEach((file, index) => {
            formData.append(`${key}`, file.originFileObj);
          });
        } else {
          formData.append(key, value);
        }
      });
      formData.append('status', 'PENDING');
      dispatch(editRoyaltySalesRequestData(params.id, formData, navigateBack));
    }
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <h1 className="title gx-mb-4">
            <LeftOutlined className="mr-5" onClick={navigateBack} />
            {editFlag ? "Edit Request" : "Add Request"}
          </h1>
        </Col>
        <Col span={24}>
          <Card className="gx-card">
            <Row>
              <Col span={16}>
                <Form
                  {...formItemLayout}
                  form={form}
                  name="add-request"
                  onValuesChange={handleFieldChange}
                  onFinish={onFinish}
                  initialValues={{
                    country_id: editSalesRequestData?.country_id?.country_id,
                    product_id: editSalesRequestData?.product_id?.product_name,
                    expected_date_of_sale: moment(
                      editSalesRequestData?.expected_date_of_sale
                    ),
                    pack_size: editSalesRequestData?.pack_size,
                    quantity: editSalesRequestData?.quantity,
                    buyer_name_and_address:
                      editSalesRequestData?.buyer_name_and_address,
                    end_customer_and_address:
                      editSalesRequestData?.end_customer_and_address,
                    proof_of_sale_details:
                      editSalesRequestData?.proof_of_sale_details,
                    file: editSalesRequestData?.file?.map((file) => ({
                      uid: file.po_id,
                      name: file.po_document.split("/").pop(),
                      status: "done",
                      url: file.po_document,
                    })),
                  }}
                  scrollToFirstError
                >
                  <Form.Item
                    name="country_id"
                    label="Country"
                    rules={[
                      { required: true, message: "Please select a country" },
                    ]}
                  >
                    <Select onChange={handleCountryChange}>
                      {royalty_countries_product
                      .filter(country => !(userDetails === "Emcure" && country.country_name === "India"))
                      .map((country) => (
                        <Option
                          key={country.country_id}
                          value={country.country_id}
                        >
                          {country.country_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="product_id"
                    label="Product"
                    rules={[
                      { required: true, message: "Please select a product" },
                    ]}
                  >
                    <Select>
                      {getProduct(editSalesRequestData?.country_id?.country_id).map((product) => (
                        <Option
                          key={product.product_id}
                          value={product.product_id}
                        >
                          {product.product_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Expected date of sale"
                    name="expected_date_of_sale"
                    rules={[
                      { required: true, message: "Please select a date!" },
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>

                  <Form.Item
                    label="Pack Size"
                    name="pack_size"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the pack size!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const inputValue = parseInt(value, 10);
                          if (
                            !isNaN(inputValue) &&
                            inputValue >= 0 &&
                            inputValue <= 10000
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Pack size must be between 0 and 10000")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>

                  <Form.Item
                    label="Quantity"
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the pack size!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const inputValue = parseInt(value, 10);
                          if (
                            !isNaN(inputValue) &&
                            inputValue >= 0 &&
                            inputValue <= 99000000
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Pack size must be between 0 and 99Mn")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input name="quantity" type="number" />
                  </Form.Item>

                  <Form.Item
                    label="Buyer Name & Address"
                    name="buyer_name_and_address"
                  >
                    <TextArea rows={4} />
                  </Form.Item>

                  <Form.Item
                    label="End Customer & Address"
                    name="end_customer_and_address"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                  <Form.Item
                    label="Proof of sales details"
                    name="proof_of_sale_details"
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                  <Form.Item
                    label="Proof of sale (PR/PO)"
                    name="file"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      ({ getFieldValue, getFieldError }) => ({
                        validator(_, value) {
                          const fileList = getFieldValue("file");
                          const isTouched =
                            !!getFieldError("file") ||
                            fileList.some(
                              (file) =>
                                file.status === "done" ||
                                file.status === "uploading"
                            );

                          if (!isTouched) {
                            // Field not touched, don't show the error
                            return Promise.resolve();
                          }

                          // Validate file type
                          const allowedFileTypes = ["application/pdf", "image/jpeg", "image/jpg", "image/png"];
                          const isFileTypeValid = fileList.every(
                            (file) =>
                              !file.originFileObj ||
                              allowedFileTypes.includes(file.type)
                          );

                          if (!isFileTypeValid) {
                            return Promise.reject(
                              new Error("Please upload a PDF, JPEG, or PNG document!")
                            );
                          }

                          // Validate size for PDF, JPEG, and PNG files
                          const isSizeValid = fileList.every(
                            (file) =>
                              !file.originFileObj ||
                              (allowedFileTypes.includes(file.type) &&
                                file.size / 1024 / 1024 <= 15)
                          );

                          if (!isSizeValid) {
                            return Promise.reject(
                              new Error(
                                "File size must be less than or equal to 15 MB!"
                              )
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Dragger
                      name="file"
                      multiple={true}
                      beforeUpload={checkFile}
                      accept=".pdf, .png, .jpeg, .jpg"
                      onRemove={(file) => handleRemove(file)}
                      customRequest={({ file, onSuccess }) => {
                        // Custom logic for file upload, you can use a file upload API here
                        onSuccess();
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag files to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload (pdf, png, jpeg).
                      </p>
                    </Dragger>
                  </Form.Item>

                  <Form.Item className="flex-d-row-reverse">
                    <Button
                      id="add-partner-save"
                      type="primary"
                      htmlType="submit"
                    >
                      {editFlag ? "Save" : "Submit Request"}
                    </Button>
                    <Button
                      id="add-partner-cancel"
                      onClick={navigateToPartnerList}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={2}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RaiseApprovalRequest;
