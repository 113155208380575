export const taxInvoiceConstants = {

    GET_TAX_REQUEST_DATA_REQUEST: 'GET_TAX_REQUEST_DATA_REQUEST',
    GET_TAX_REQUEST_DATA_SUCCESS: 'GET_TAX_REQUEST_DATA_SUCCESS',
    GET_TAX_REQUEST_DATA_FAILURE: 'GET_TAX_REQUEST_DATA_FAILURE',


    DELETE_TAX_REQUEST_DATA_REQUEST: 'DELETE_TAX_REQUEST_DATA_REQUEST',
    DELETE_TAX_REQUEST_DATA_SUCCESS: 'DELETE_TAX_REQUEST_DATA_SUCCESS',
    DELETE_TAX_REQUEST_DATA_FAILURE: 'DELETE_TAX_REQUEST_DATA_FAILURE',

    RAISE_TAX_REQUEST_REQUEST: 'RAISE_TAX_REQUEST_REQUEST',
    RAISE_TAX_REQUEST_SUCCESS: 'RAISE_TAX_REQUEST_SUCCESS',
    RAISE_TAX_REQUEST_FAILURE: 'RAISE_TAX_REQUEST_FAILURE',

    EDIT_TAX_REQUEST_REQUEST: 'EDIT_TAX_REQUEST_REQUEST',
    EDIT_TAX_REQUEST_SUCCESS: 'EDIT_TAX_REQUEST_SUCCESS',
    EDIT_TAX_REQUEST_FAILURE: 'EDIT_TAX_REQUEST_FAILURE',

    SINGLE_TAX_VALUE_REQUEST: 'SINGLE_TAX_VALUE_REQUEST',
    SINGLE_TAX_VALUE_SUCCESS: 'SINGLE_TAX_VALUE_SUCCESS',
    SINGLE_TAX_VALUE_FAILURE: 'SINGLE_TAX_VALUE_FAILURE',

};