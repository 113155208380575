export const adminComplianceConstants = {
  ADD_COMPLIANCE_REQUEST: "ADD_COMPLIANCE_REQUEST",
  ADD_COMPLIANCE_SUCCESS: "ADD_COMPLIANCE_SUCCESS",
  ADD_COMPLIANCE_FAILURE: "ADD_COMPLIANCE_FAILURE",

  EDIT_COMPLIANCE_REQUEST: "EDIT_COMPLIANCE_REQUEST",
  EDIT_COMPLIANCE_SUCCESS: "EDIT_COMPLIANCE_SUCCESS",
  EDIT_COMPLIANCE_FAILURE: "EDIT_COMPLIANCE_FAILURE",

  GET_COMPLIANCE_LIST_REQUEST: "GET_COMPLIANCE_LIST_REQUEST",
  GET_COMPLIANCE_LIST_SUCCESS: "GET_COMPLIANCE_LIST_SUCCESS",
  GET_COMPLIANCE_LIST_FAILURE: "GET_COMPLIANCE_LIST_FAILURE",

  GET_COMPLIANCE_REQUEST: "GET_COMPLIANCE_REQUEST",
  GET_COMPLIANCE_SUCCESS: "GET_COMPLIANCE_SUCCESS",
  GET_COMPLIANCE_FAILURE: "GET_COMPLIANCE_FAILURE",

  DELETE_COMPLIANCE_REQUEST: "DELETE_COMPLIANCE_REQUEST",
  DELETE_COMPLIANCE_SUCCESS: "DELETE_COMPLIANCE_SUCCESS",
  DELETE_COMPLIANCE_FAILURE: "DELETE_COMPLIANCE_FAILURE",

  GET_COMPLIANCE_DETAILS_REQUEST: "GET_COMPLIANCE_DETAILS_REQUEST",
  GET_COMPLIANCE_DETAILS_SUCCESS: "GET_COMPLIANCE_DETAILS_SUCCESS",
  GET_COMPLIANCE_DETAILS_FAILURE: "GET_COMPLIANCE_DETAILS_FAILURE",

  RESET_TEMPLATE: "RESET_TEMPLATE",
};
