export const adminDashboardConstants = {
    GET_PROJECT_COUNT_REQUEST: 'GET_PROJECT_COUNT_REQUEST',
    GET_PROJECT_COUNT_SUCCESS: 'GET_PROJECT_COUNT_SUCCESS',
    GET_PROJECT_COUNT_FAILURE: 'GET_PROJECT_COUNT_FAILURE',

    GET_PRODUCT_COMPANY_REQUEST: 'GET_PRODUCT_COMPANY_REQUEST',
    GET_PRODUCT_COMPANY_SUCCESS: 'GET_PRODUCT_COMPANY_SUCCESS',
    GET_PRODUCT_COMPANY_FAILURE: 'GET_PRODUCT_COMPANY_FAILURE',

    GET_PRODUCT_COUNTRY_REQUEST: 'GET_PRODUCT_COUNTRY_REQUEST',
    GET_PRODUCT_COUNTRY_SUCCESS: 'GET_PRODUCT_COUNTRY_SUCCESS',
    GET_PRODUCT_COUNTRY_FAILURE: 'GET_PRODUCT_COUNTRY_FAILURE',

    GET_PRODUCT_COUNTRY_QUARTER_REQUEST: 'GET_PRODUCT_COUNTRY_QUARTER_REQUEST',
    GET_PRODUCT_COUNTRY_QUARTER_SUCCESS: 'GET_PRODUCT_COUNTRY_QUARTER_SUCCESS',
    GET_PRODUCT_COUNTRY_QUARTER_FAILURE: 'GET_PRODUCT_COUNTRY_QUARTER_FAILURE',

    GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
    GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
    GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',
    
    GET_COUNTRY_PRODUCT_REQUEST: 'GET_COUNTRY_PRODUCT_REQUEST',
    GET_COUNTRY_PRODUCT_SUCCESS: 'GET_COUNTRY_PRODUCT_SUCCESS',
    GET_COUNTRY_PRODUCT_FAILURE: 'GET_COUNTRY_PRODUCT_FAILURE',
    //
    GET_COUNTRY_PRODUCT_STATUS_REQUEST: 'GET_COUNTRY_PRODUCT_STATUS_REQUEST',
    GET_COUNTRY_PRODUCT_STATUS_SUCCESS: 'GET_COUNTRY_PRODUCT_STATUS_SUCCESS',
    GET_COUNTRY_PRODUCT_STATUS_FAILURE: 'GET_COUNTRY_PRODUCT_STATUS_FAILURE',

    GET_PRODUCT_COMPANY_SALES_REQUEST: 'GET_PRODUCT_COMPANY_SALES_REQUEST',
    GET_PRODUCT_COMPANY_SALES_SUCCESS: 'GET_PRODUCT_COMPANY_SALES_SUCCESS',
    GET_PRODUCT_COMPANY_SALES_FAILURE: 'GET_PRODUCT_COMPANY_SALES_FAILURE',

    GET_PRODUCT_PERIOD_SALES_REQUEST: 'GET_PRODUCT_PERIOD_SALES_REQUEST',
    GET_PRODUCT_PERIOD_SALES_SUCCESS: 'GET_PRODUCT_PERIOD_SALES_SUCCESS',
    GET_PRODUCT_PERIOD_SALES_FAILURE: 'GET_PRODUCT_PERIOD_SALES_FAILURE',

    GET_PRODUCT_COUNTRY_SALES_REQUEST: 'GET_PRODUCT_COUNTRY_SALES_REQUEST',
    GET_PRODUCT_COUNTRY_SALES_SUCCESS: 'GET_PRODUCT_COUNTRY_SALES_SUCCESS',
    GET_PRODUCT_COUNTRY_SALES_FAILURE: 'GET_PRODUCT_COUNTRY_SALES_FAILURE',

    GET_COMPANY_PRODUCT_SALES_REQUEST: 'GET_COMPANY_PRODUCT_SALES_REQUEST',
    GET_COMPANY_PRODUCT_SALES_SUCCESS: 'GET_COMPANY_PRODUCT_SALES_SUCCESS',
    GET_COMPANY_PRODUCT_SALES_FAILURE: 'GET_COMPANY_PRODUCT_SALES_FAILURE',

    GET_COMPANY_PERIOD_SALES_REQUEST: 'GET_COMPANY_PERIOD_SALES_REQUEST',
    GET_COMPANY_PERIOD_SALES_SUCCESS: 'GET_COMPANY_PERIOD_SALES_SUCCESS',
    GET_COMPANY_PERIOD_SALES_FAILURE: 'GET_COMPANY_PERIOD_SALES_FAILURE',
    
    GET_COMPANY_COUNTRY_SALES_REQUEST: 'GET_COMPANY_COUNTRY_SALES_REQUEST',
    GET_COMPANY_COUNTRY_SALES_SUCCESS: 'GET_COMPANY_COUNTRY_SALES_SUCCESS',
    GET_COMPANY_COUNTRY_SALES_FAILURE: 'GET_COMPANY_COUNTRY_SALES_FAILURE',
    
    GET_COUNTRY_PRODUCT_SALES_REQUEST: 'GET_COUNTRY_PRODUCT_SALES_REQUEST',
    GET_COUNTRY_PRODUCT_SALES_SUCCESS: 'GET_COUNTRY_PRODUCT_SALES_SUCCESS',
    GET_COUNTRY_PRODUCT_SALES_FAILURE: 'GET_COUNTRY_PRODUCT_SALES_FAILURE',

    GET_COUNTRY_PERIOD_SALES_REQUEST: 'GET_COUNTRY_PERIOD_SALES_REQUEST',
    GET_COUNTRY_PERIOD_SALES_SUCCESS: 'GET_COUNTRY_PERIOD_SALES_SUCCESS',
    GET_COUNTRY_PERIOD_SALES_FAILURE: 'GET_COUNTRY_PERIOD_SALES_FAILURE',

    GET_COUNTRY_COMPANY_SALES_REQUEST: 'GET_COUNTRY_COMPANY_SALES_REQUEST',
    GET_COUNTRY_COMPANY_SALES_SUCCESS: 'GET_COUNTRY_COMPANY_SALES_SUCCESS',
    GET_COUNTRY_COMPANY_SALES_FAILURE: 'GET_COUNTRY_COMPANY_SALES_FAILURE',
    
    GET_PERIOD_PRODUCT_SALES_REQUEST: 'GET_PERIOD_PRODUCT_SALES_REQUEST',
    GET_PERIOD_PRODUCT_SALES_SUCCESS: 'GET_PERIOD_PRODUCT_SALES_SUCCESS',
    GET_PERIOD_PRODUCT_SALES_FAILURE: 'GET_PERIOD_PRODUCT_SALES_FAILURE',

    GET_PERIOD_COUNTRY_SALES_REQUEST: 'GET_PERIOD_COUNTRY_SALES_REQUEST',
    GET_PERIOD_COUNTRY_SALES_SUCCESS: 'GET_PERIOD_COUNTRY_SALES_SUCCESS',
    GET_PERIOD_COUNTRY_SALES_FAILURE: 'GET_PERIOD_COUNTRY_SALES_FAILURE',

    GET_PERIOD_COMPANY_SALES_REQUEST: 'GET_PERIOD_COMPANY_SALES_REQUEST',
    GET_PERIOD_COMPANY_SALES_SUCCESS: 'GET_PERIOD_COMPANY_SALES_SUCCESS',
    GET_PERIOD_COMPANY_SALES_FAILURE: 'GET_PERIOD_COMPANY_SALES_FAILURE',

    GET_PACK_PERIOD_SALES_REQUEST: 'GET_PACK_PERIOD_SALES_REQUEST',
    GET_PACK_PERIOD_SALES_SUCCESS: 'GET_PACK_PERIOD_SALES_SUCCESS',
    GET_PACK_PERIOD_SALES_FAILURE: 'GET_PACK_PERIOD_SALES_FAILURE',
    
    GET_TREATMENT_PERIOD_SALES_REQUEST: 'GET_TREATMENT_PERIOD_SALES_REQUEST',
    GET_TREATMENT_PERIOD_SALES_SUCCESS: 'GET_TREATMENT_PERIOD_SALES_SUCCESS',
    GET_TREATMENT_PERIOD_SALES_FAILURE: 'GET_TREATMENT_PERIOD_SALES_FAILURE',

    GET_ADMIN_DASHBOARD_SUMMARY_REQUEST: 'GET_ADMIN_DASHBOARD_SUMMARY_REQUEST',
    GET_ADMIN_DASHBOARD_SUMMARY_SUCCESS: 'GET_ADMIN_DASHBOARD_SUMMARY_SUCCESS',
    GET_ADMIN_DASHBOARD_SUMMARY_FAILURE: 'GET_ADMIN_DASHBOARD_SUMMARY_FAILURE',

    SEND_BULK_REMINDER_REQUEST: 'SEND_BULK_REMINDER_REQUEST',
    SEND_BULK_REMINDER_SUCCESS: 'SEND_BULK_REMINDER_SUCCESS',
    SEND_BULK_REMINDER_FAILURE: 'SEND_BULK_REMINDER_FAILURE',

};