import React, { useEffect, useState, useRef } from 'react';
import IntlMessages from 'util/IntlMessages';
import { Card, Table, Row, Col, Button, Tooltip, Tabs, Select, Space, InputNumber, Slider } from 'antd';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { downloadApiSalesReport, downloadFdfSalesReport, getAdminConsolidatedApiReport, getAdminConsolidatedFdfReport } from '../../../appRedux/actions/SalesReport';

const { Option } = Select;

const TabPane = Tabs.TabPane;


const ConsolidatedSalesReport = (props) => {

    const { isLoaded, apiSalesReportData, fdfSalesReportData } = useSelector(({ salesReport }) => salesReport);
    const dispatch = useDispatch();
    const [apiRows, setApiRows] = useState([]);
    const [apiColumns, setApiColumns] = useState([]);
    const [fdfRows, setFdfRows] = useState([]);
    const [fdfColumns, setFdfColumn] = useState([]);
    const [apitotal, setApiTotal] = useState(0);
    const [fdftotal, setFdfTotal] = useState(0);
    const [apiRequestObj, setApiRequestObj] = useState({ page_number: 1, page_size: 100 });
    const [fdfRequestObj, setFdfRequestObj] = useState({ page_number: 1, page_size: 100 });
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [tempMinRange, setTempMinRange] = useState(null);
    const [tempMaxRange, setTempMaxRange] = useState(undefined);
    const [tabKey, setTabKey] = useState('api');

    const getCompNameOptions = (partner_filter, apiOrFDF) => {
        return partner_filter.map((partner, lstIdx) => (
                <Option value={partner.partner_id} key={`${apiOrFDF}CompanyNameFilter_${lstIdx}`}>
                    {partner.company_name.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())}
                </Option>
            )
        )
    }

    const getYearOptions = (year_filter, apiOrFDF) => {
        return year_filter.map((year, lstIdx) => (
            <Option value={year.year} key={`${apiOrFDF}YearFilter_${lstIdx}`}>{year.year}</Option>
        ))
    }

    const getMonthOptions = (month_filter, apiOrFDF) => {
        return month_filter.map((month, lstIdx) => (
            <Option value={month.month} key={`${apiOrFDF}MonthFilter_${lstIdx}`}>{month.month}</Option>
        ))
    }

    const getQuarterOptions = (quarter_filter, apiOrFDF) => {
        const  filtered_quarter_names = quarter_filter.map(name => name.quarter_name);
        const  quarter_name = filtered_quarter_names.filter((name, idx) => filtered_quarter_names.indexOf(name) === idx)
        return quarter_name.map((quarter, lstIdx) => (
            <Option value={quarter} key={`${apiOrFDF}QuarterFilter_${lstIdx}`}>{quarter}</Option>
        ))
    }

    const getCountryOptions = (country_filter, apiOrFDF) => {
        return country_filter.map((country, lstIdx) => (
            <Option value={country.country_id} key={`${apiOrFDF}CountryFilter_${lstIdx}`}>{country.country_name}</Option>
        ))
    }

    const getPurchaserOptions = (purchaser_filter, apiOrFDF) => {
        return purchaser_filter.map((purchaser, lstIdx) => (
            <Option value={purchaser.purchaser} key={`${apiOrFDF}PurchaserFilter_${lstIdx}`}>{purchaser.purchaser}</Option>
        ))
    }

    const getProductNameOptions = (product_filter, apiOrFDF) => {
        return product_filter.map((product, lstIdx) => (
            <Option value={product.product_id} key={`${apiOrFDF}ProductFilter_${lstIdx}`}>{product.product_name}</Option>
        ))
    }

    const getStrengthOptions = (strength_filter, apiOrFDF) => {
        return strength_filter.map((strength, lstIdx) => (
            <Option value={strength.strength} key={`${apiOrFDF}StrengthFilter_${lstIdx}`}>{strength.strength}</Option>
        ))
    }

    const getFormulationTypeOptions = (formulation_md_filter, apiOrFDF) => {
        return formulation_md_filter.map((formulation_md, lstIdx) => (
            <Option value={formulation_md.formulation_md} key={`${apiOrFDF}FormulationFilter_${lstIdx}`}>{formulation_md.formulation_md}</Option>
        ))
    }

    const getCurrencyOptions = (currency_filter, apiOrFDF) => {
        return currency_filter.map((currency, lstIdx) => (
            <Option value={currency.currency} key={`${apiOrFDF}CurrencyFilter_${lstIdx}`}>{currency.currency}</Option>
        ))
    }

    const getProcurementAgencyOptions = (procurement_end_country_filter, apiOrFDF) => {
        return procurement_end_country_filter.map((procurement_end_country, lstIdx) => (
            <Option value={procurement_end_country.procurement_end_country} key={`${apiOrFDF}ProcurementAgencyFilter_${lstIdx}`}>{procurement_end_country.procurement_end_country}</Option>
        ))
    }

    const getCommentsOptions = (comments_filter, apiOrFDF) => {
        return comments_filter.map((comments, lstIdx) => (
            <Option value={comments.comments} key={`${apiOrFDF}CommentsFilter_${lstIdx}`}>{comments.comments}</Option>
        ))
    }

    const getPackSizeOptions = (pack_size_filter, apiOrFDF) => {
        return pack_size_filter.map((pack_size, lstIdx) => (
            <Option value={pack_size.pack_size} key={`${apiOrFDF}PackSizeFilter_${lstIdx}`}>{pack_size.pack_size}</Option>
        ))
    }

    const getColumnSearchProps = (dataIndex, filterOptionObjects, placeholderName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    style={{ width: 200 }}
                    placeholder={`Select ${placeholderName}(s)`}
                    optionFilterProp="children"
                    onChange={value => setSelectedKeys(value ? value : [])}
                    filterOption={(input, option) => {
                        if(option.children)
                            return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                        else
                            return null
                    }
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                    value={selectedKeys}
                >
                    {apiSalesReportData.filter_options && filterOptionObjects}
                </Select>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Filter
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });

    const getColumnRangeProps = (dataIndex, min_value, max_value) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Slider
                    range
                    min={min_value ? min_value :0}
                    max={max_value}
                    defaultValue={[min_value ? min_value :0, max_value]}
                    onChange={(value) => {
                            setSelectedKeys(value[0] < value[1] ? [value] : [value[1], value[0]]);
                        }
                    }
                >
                </Slider>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Filter
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size="small" style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
    });

    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    
    const handleReset = clearFilters => {
        clearFilters();
        setSearchText(null);
    };
    

    useEffect(() => {
        if (tabKey === 'api') {
            dispatch(getAdminConsolidatedApiReport(apiRequestObj));
        } else {
            dispatch(getAdminConsolidatedFdfReport(fdfRequestObj))
        }
    }, [apiRequestObj, tabKey, fdfRequestObj])

    useEffect(() => {
        if (isLoaded) {
            if (tabKey === 'api') {
                const { total_rows, rows, filter_options } = apiSalesReportData;
                const { partner_filter, product_filter, country_filter, quarter_filter, year_filter, month_filter, purchaser_filter } = filter_options;

                const apiColumns = [
                    {
                        title: 'Company Name',
                        dataIndex: 'company_name',
                        key: 'company_name',
                        ellipsis: true,
                        fixed: 'left',
                        render: name => <span className='text-capitalize'>{name}</span>,
                        width: 250,
                        ...getColumnSearchProps('company_name', getCompNameOptions(partner_filter, 'API'), 'Company Name')
                    },
                    {
                        title: 'Year',
                        dataIndex: 'year',
                        key: 'year',
                        width: 150,
                        ...getColumnSearchProps('year', getYearOptions(year_filter, 'API'), 'Year')
                    },
                    {
                        title: 'Month',
                        dataIndex: 'month',
                        key: 'month',
                        width: 150,
                        ...getColumnSearchProps('month', getMonthOptions(month_filter, 'API'), 'Month')
                    },
                    {
                        title: 'Quarter',
                        dataIndex: 'quarter_name',
                        key: 'quarter_name',
                        width: 150,
                        ...getColumnSearchProps('quarter_name', getQuarterOptions(quarter_filter, 'API'), 'Quarter')
                    },
                    {
                        title: 'Country',
                        dataIndex: 'country_name',
                        key: 'country_name',
                        width: 150,
                        ...getColumnSearchProps('country_name', getCountryOptions(country_filter, 'API'), 'Country')
                    },
                    {
                        title: 'Purchaser',
                        dataIndex: 'purchaser',
                        key: 'purchaser',
                        render: name => <span className='text-capitalize'>{name}</span>,
                        width: 200,
                        ...getColumnSearchProps('purchaser', getPurchaserOptions(purchaser_filter, 'API'), 'Purchaser')
                    },
                    {
                        title: 'Api Name',
                        dataIndex: 'product_name',
                        key: 'product_name',
                        width: 200,
                        ...getColumnSearchProps('product_name', getProductNameOptions(product_filter, 'API'), 'API Name')
                    },
                    {
                        title: 'Quantity (Kg)',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        width: 150,
                        
                    },
                    {
                        title: 'Total Value (USD)',
                        dataIndex: 'total_value',
                        key: 'total_value',
                        width: 150,
                        
                    }
                ]
                setApiColumns(apiColumns);
                setApiTotal(total_rows);
                setApiRows(rows);
            } else {
                const { total_rows, rows } = fdfSalesReportData;
                const { partner_filter, product_filter, country_filter,
                        quarter_filter, year_filter, month_filter,
                        purchaser_filter, strength_filter, formulation_md_filter,
                        currency_filter, procurement_end_country_filter, comments_filter,
                        pack_size_filter, quantity_filter, gross_sale_price_filter,
                        royalty_due_filter, net_sales_value_filter} = fdfSalesReportData.filter_options;
    
                const fdfColumns = [
                    {
                        title: 'Company Name',
                        dataIndex: 'company_name',
                        key: 'company_name',
                        ellipsis: true,
                        fixed: 'left',
                        render: name => <span className='text-capitalize'>{name}</span>,
                        width: 250,
                        ...getColumnSearchProps('company_name', getCompNameOptions(partner_filter, 'fdf'), 'Company Name')
                    },
                    {
                        title: 'Year',
                        dataIndex: 'year',
                        key: 'year',
                        width: 150,
                        ...getColumnSearchProps('year', getYearOptions(year_filter, 'fdf'), 'Year')
                    },
                    {
                        title: 'Month',
                        dataIndex: 'month',
                        key: 'month',
                        width: 150,
                        ...getColumnSearchProps('month', getMonthOptions(month_filter, 'fdf'), 'Month')
                    },
                    {
                        title: 'Quarter',
                        dataIndex: 'quarter_name',
                        key: 'quarter_name',
                        width: 150,
                        ...getColumnSearchProps('quarter_name', getQuarterOptions(quarter_filter, 'fdf'), 'Quarter')
                    },
                    {
                        title: 'Country',
                        dataIndex: 'country_name',
                        key: 'country_name',
                        width: 150,
                        ...getColumnSearchProps('country_name', getCountryOptions(country_filter, 'fdf'), 'Country')
                    },
                    {
                        title: 'Product',
                        dataIndex: 'product_name',
                        key: 'product_name',
                        width: 200,
                        ...getColumnSearchProps('product_name', getProductNameOptions(product_filter, 'fdf'), 'Product')
                    },
                    {
                        title: 'Purchaser',
                        dataIndex: 'purchaser',
                        key: 'purchaser',
                        render: name => <span className='text-capitalize'>{name}</span>,
                        width: 200,
                        ...getColumnSearchProps('purchaser', getPurchaserOptions(purchaser_filter, 'fdf'), 'Purchaser')
                    },
                    {
                        title: 'Strength',
                        dataIndex: 'strength',
                        key: 'strength',
                        width: 150,
                        ...getColumnSearchProps('strength', getStrengthOptions(strength_filter, 'fdf'), 'Strength')
                    },
                    {
                        title: 'Formulation Type',
                        dataIndex: 'formulation_md',
                        key: 'formulation_md',
                        width: 150,
                        ...getColumnSearchProps('formulation_md', getFormulationTypeOptions(formulation_md_filter, 'fdf'), 'Formulation')
                    },
                    {
                        title: 'Pack Size',
                        dataIndex: 'pack_size',
                        key: 'pack_size',
                        width: 100,
                        ...getColumnSearchProps('pack_size', getPackSizeOptions(pack_size_filter, 'fdf'), 'PackSize')
                    },
                    {
                        title: 'Quantity',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        width: 100,
                        ...getColumnRangeProps('quantity', quantity_filter.quantity__min, quantity_filter.quantity__max)
                    },
                    {
                        title: 'Currency',
                        dataIndex: 'currency',
                        key: 'currency',
                        width: 100,
                        ...getColumnSearchProps('currency', getCurrencyOptions(currency_filter, 'fdf'), 'Currency')
                    },
                    {
                        title: 'Gross Sale Price (per pack) (Local Currency)',
                        dataIndex: 'gross_sale_price_currency',
                        key: 'gross_sale_price_currency',
                        width: 150,
                    },
                    {
                        title: 'Applicable Currency Exchange Rate to USD',
                        dataIndex: 'usd_exchange_rate',
                        key: 'usd_exchange_rate',
                        width: 150
                    },
                    {
                        title: 'Gross Sale Price (per pack) (USD)',
                        dataIndex: 'gross_sale_price_usd',
                        key: 'gross_sale_price_usd',
                        width: 150,
                        ...getColumnRangeProps('gross_sale_price_usd', gross_sale_price_filter.gross_sale_price_usd__min, gross_sale_price_filter.gross_sale_price_usd__max)
                    },
                    {
                        title: 'Deductable Expenses (USD)',
                        dataIndex: 'deductable_expenses',
                        key: 'deductable_expenses',
                        width: 150
                    },
                    {
                        title: 'Total Net Sales Value',
                        dataIndex: 'total_value',
                        key: 'total_value',
                        width: 150,
                        ...getColumnRangeProps('total_value', net_sales_value_filter.total_value__min, net_sales_value_filter.total_value__max)
                    },
                    {
                        title: 'Royalty %',
                        dataIndex: 'royalty_percent',
                        key: 'royalty_percent',
                        width: 100
                    },
                    {
                        title: 'Royalty Due',
                        dataIndex: 'royalty_due',
                        key: 'royalty_due',
                        width: 100,
                        ...getColumnRangeProps('royalty_due', royalty_due_filter.royalty_due__min, royalty_due_filter.royalty_due__max)
                    },
                    // {
                    //     title: 'Royalty Factor',
                    //     dataIndex: 'royalty_factor',
                    //     key: 'royalty_factor',
                    //     width: 100,
                    // },
                    // {
                    //     title: 'Scenario',
                    //     dataIndex: 'scenario',
                    //     key: 'scenario',
                    //     width: 100,
                    // },
                    // {
                    //     title: 'FMV Factor',
                    //     dataIndex: 'fmv_factor',
                    //     key: 'fmv_factor',
                    //     width: 100,
                    // },
                    // {
                    //     title: 'Gross Sale Price (per pack) 30 (USD)',
                    //     dataIndex: 'gross_sale_price_per_pack_30_usd',
                    //     key: 'gross_sale_price_per_pack_30_usd',
                    //     width: 150,
                    // },
                    {
                        title: 'Procurement Agency End-Country',
                        dataIndex: 'procurement_end_country',
                        key: 'procurement_end_country',
                        width: 150,
                        ...getColumnSearchProps('procurement_end_country', getProcurementAgencyOptions(procurement_end_country_filter, 'fdf'), 'Procurement Agency End-Country')
                    },
                    {
                        title: 'Comments',
                        dataIndex: 'comments',
                        key: 'comments',
                        width: 200,
                        ...getColumnSearchProps('comments', getCommentsOptions(comments_filter, 'fdf'), 'Comments')
                    },
                ]
                setFdfColumn(fdfColumns);
                setFdfTotal(total_rows);
                setFdfRows(rows);
            }
        }
    }, [isLoaded, apiSalesReportData, fdfSalesReportData])

    const handleApiTableChange = (pagination, columns) => {
        const { current, pageSize } = pagination
        const { company_name, product_name, country_name, quarter_name, year, month, purchaser } = columns
        setApiRequestObj({
            page_size: pageSize,
            page_number: current,
            partner_id: company_name,
            product_id: product_name,
            country_id: country_name,
            quarter_id: quarter_name,
            year: year,
            month: month,
            purchaser: purchaser
        })
    }

    const handleFdfTableChange = (pagination, columns) => {
        const { current, pageSize } = pagination
        const { company_name, product_name, country_name,
                quarter_name, year, month, purchaser,
                strength, formulation_md, currency,
                procurement_end_country, comments,
                pack_size, quantity, gross_sale_price_usd,
                total_value, royalty_due } = columns
        const rangeFilterCols = {"quantity": quantity, "gross_sale_price": gross_sale_price_usd,
                                "net_sales_value": total_value, "royalty_due": royalty_due}
        let sanitizedCols = {}
        Object.keys(rangeFilterCols).map(val=>{
            if(rangeFilterCols[val])
            sanitizedCols = {...sanitizedCols, [val]: {min_value: rangeFilterCols[val][0][0], max_value: rangeFilterCols[val][0][1]}}
        })
        setFdfRequestObj({
            page_size: pageSize,
            page_number: current,
            partner_id: company_name,
            product_id: product_name,
            country_id: country_name,
            quarter_id: quarter_name,
            year: year,
            month: month,
            purchaser: purchaser,
            strength: strength,
            formulation_md: formulation_md,
            currency: currency,
            procurement_end_country: procurement_end_country,
            comments: comments,
            pack_size: pack_size,
            ...sanitizedCols
        })
    }

    return (
        <div>
            <Row>
                <Col span={24}>
                    <div className='gx-flex-row gx-align-items-center gx-justify-content-between'>
                        <h1 className='title gx-mb-4'><IntlMessages id='report.sales-report' /></h1>
                    </div>
                </Col>
                <Col span={24}>
                    <Card className='mb-0'>
                        <Tabs
                            tabPosition='top'
                            type='card'
                            className='sales-report-tabs'
                            tabBarExtraContent={
                                <div>
                                    <Tooltip title={tabKey === 'api' ? 'download API report!' : 'download FDF report!'}>
                                        <Button
                                            className='mb-0'
                                            id='report-download'
                                            onClick={() => {
                                                tabKey === 'api' ?
                                                    dispatch(downloadApiSalesReport('api-sales-report.csv', apiRequestObj))
                                                    : dispatch(downloadFdfSalesReport('fdf-sales-report.csv', fdfRequestObj))
                                            }}
                                        >
                                            <DownloadOutlined className='font-20' />
                                        </Button>
                                    </Tooltip>
                                </div>
                            }
                            onChange={(currentTabKey) => { setTabKey(currentTabKey) }}
                        >
                            <TabPane tab='API' key='api'>
                                <Table
                                    className='gx-table-responsive mpp-list-table'
                                    columns={apiColumns}
                                    dataSource={apiRows}
                                    pagination={{
                                        pageSize: apiRequestObj.page_size,
                                        current: apiRequestObj.page_number,
                                        total: apitotal,
                                    }}
                                    size='small'
                                    loading={!isLoaded}
                                    onChange={handleApiTableChange}
                                    rowKey="Id"
                                    bordered
                                />
                            </TabPane>
                            <TabPane tab='FDF' key='fdf'>
                                <Table
                                    className='gx-table-responsive mpp-list-table'
                                    columns={fdfColumns}
                                    dataSource={fdfRows}
                                    pagination={{
                                        pageSize: fdfRequestObj.page_size,
                                        current: fdfRequestObj.page_number,
                                        total: fdftotal,
                                    }}
                                    size='small'
                                    loading={!isLoaded}
                                    onChange={handleFdfTableChange}
                                    bordered
                                />
                            </TabPane>
                        </Tabs>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ConsolidatedSalesReport
