import React, { useEffect, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import { Card, Table, Row, Col, Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { interactiveMapLogs, productAvailabilitiesLogs, productDevelopmentLogs, syncInteractiveEntryLogs } from '../../../appRedux/actions';
import { DeleteTwoTone, EditTwoTone, MedicineBoxOutlined } from '@ant-design/icons';
import { showConfirm, getRole } from '../../../helpers';

const InteractiveMap = (props) => {

    const isLoaded = useSelector(state => state.interactiveMaps.isLoaded);
    const interactiveMapList = useSelector(state => state.interactiveMaps.InteractiveMapList);
    const dispatch = useDispatch();
    const [data, setData] = useState([]); 
    const [isUserAdmin, setIsUserAdmin] = useState(false);
    const [isUserLegal, setIsUserLegal] = useState(false);

    useEffect(() => {
        setIsUserAdmin(() => {
            return (getRole() === 'ADMIN')
        })
        setIsUserLegal(() => {
            return getRole() === "LEGAL";
        });
        dispatch(interactiveMapLogs(true));
    }, [])

    useEffect(() => {
        let rowData = interactiveMapList.length ? interactiveMapList.map(
            value => ({
                key: value.interactive_log_id,
                status: value.status.toUpperCase(),
                statusAt: value.status_at,
                newImport: value.is_new_import ? "Yes" : "No",
                nbEntriesUpdated: value.nb_entries_updated,
                nbEntriesCreated: value.nb_entries_created,
                type: value.type
            })
        ) : [];
        setData(rowData)
    }, [isLoaded])

    const columns = [{
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        ellipsis: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: name => <span>{name}</span>
    }, {
        title: 'Status At',
        dataIndex: 'statusAt',
        key: 'statusAt',
        ellipsis: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: name => <span>{name}</span>
    }, {
        title: 'New Import',
        dataIndex: 'newImport',
        key: 'newImport',
        ellipsis: true
    },{
        title: 'Nb Entries Created',
        dataIndex: 'nbEntriesCreated',
        key: 'nbEntriesCreated',
        ellipsis: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: name => <span>{name}</span>
    },{
        title: 'Nb Entries Updated',
        dataIndex: 'nbEntriesUpdated',
        key: 'nbEntriesUpdated',
        ellipsis: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: name => <span>{name}</span>
    },{
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    ];

    const getInteractiveMaplogs = () => {
        dispatch(interactiveMapLogs(true))
    }

    const productAvailabilities = () => {
        dispatch(productAvailabilitiesLogs({}))
        getInteractiveMaplogs()
    }

    const productDevelopment = () => {
        dispatch(productDevelopmentLogs({}))
        getInteractiveMaplogs()
    }

    const syncInteractiveEntry = () => {
        dispatch(syncInteractiveEntryLogs({}))
        getInteractiveMaplogs()
    }

    return (
        <div>
            <Row>
                <Col span={24}>
                    <div className="gx-flex-row align-items-baseline">
                        <h1 className='title gx-mb-4'><IntlMessages id='sidebar.interactive-map' /></h1>

                        <Button
                            id="product-availabilities-btn"
                            type="primary"
                            className="gx-ml-auto"
                            onClick={productAvailabilities}
                            disabled={((isUserAdmin || isUserLegal) ? false : true)}
                        >
                            <MedicineBoxOutlined /> <IntlMessages id='interactive_map.productAvailabilities' />
                        </Button>
                        
                        <Button
                            id="product-development-btn"
                            type="primary"
                            onClick={productDevelopment}
                            disabled={!isUserAdmin}
                        >
                            <MedicineBoxOutlined /> <IntlMessages id='interactive_map.productDevelopment' />
                        </Button>
                        <Button
                            id="sync-interactive-entry-btn"
                            type="primary"
                            onClick={syncInteractiveEntry}
                            disabled={!isUserAdmin}
                        >
                            <MedicineBoxOutlined /> <IntlMessages id='interactive_map.syncInteractiveEntry' />
                        </Button>
                    </div>
                </Col>
                <Col span={24}>
                    <Card className='mb-0'>
                        <Table
                            className='gx-table-responsive mpp-list-table'
                            columns={columns}
                            dataSource={data}
                            bordered
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default InteractiveMap
