import React, { useEffect, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import { Card, Table, Row, Col, Button, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminTemplateList,deleteAdminTemplate } from '../../../appRedux/actions';
import { DeleteTwoTone, EditTwoTone, MedicineBoxOutlined } from '@ant-design/icons';
import { showConfirm, getRole } from '../../../helpers';
import moment from 'moment';
 
const ComplianceTemplate = (props) =>{

    const isLoaded = useSelector(state => state.adminCompliance.isLoaded);
    const adminComplianceTemplateList = useSelector(state => state.adminCompliance.templateList);
    const dispatch = useDispatch();
    const [data, setData] = useState([]); 
    
    const [isUserAdmin, setIsUserAdmin] = useState(false);
    const [isUserLegal, setIsUserLegal] = useState(false);

    useEffect(() => {
        setIsUserAdmin(() => {
            return (getRole() === 'ADMIN')
        })
        setIsUserLegal(() => {
            return (getRole() === 'LEGAL')
        })
        dispatch(getAdminTemplateList());
        
    }, [])

    useEffect(() => {
        
        let rowData = adminComplianceTemplateList.length ? adminComplianceTemplateList.map(
            value => ({
                key: value.template_id,
                name: value.template_name,
                createdOn: moment(value.created_at).format('DD/MM/YYYY'),
                updatedOn: moment(value.updated_at).format('DD/MM/YYYY'),
            })
        ) : [];
        setData(rowData)
        
    }, [isLoaded])


  
    const navigateToAddTemplate = () => {
        const { history } = props;
        history.push('/admin/add-compliance-template')
    } 

    const navigateToEditTemplate = (item) => {
        const { history } = props;
        history.push('/admin/edit-compliance-template/' + item.key)
    }


    const columns = [{
        title: 'Template Name',
        dataIndex: 'name',
        key: 'name',
    }, 
 
    {
        title: 'Created On',
        dataIndex: 'createdOn',
        key: 'createdOn',
    },
    {
        title: 'Updated On',
        dataIndex: 'updatedOn',
        key: 'updatedOn',
    },
    {
        title: 'Action',
        key: 'operation',
        align: 'center',
        render: item => (
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Button
                    type='link'
                    className='margin-0'
                    onClick={() => navigateToEditTemplate(item)}
                    id={'edit-' + item.key}
                    disabled={(isUserAdmin || isUserLegal) ? false : true}

                >
                    <Tooltip title='Edit Template'>
                        <EditTwoTone twoToneColor='#00AEEF' className='font-20' />
                    </Tooltip>
                </Button>
                <Button
                    type='link'
                    className='margin-0'
                    onClick={() => showDeleteTemplate(item)}
                    id={'delete-template-' + item.key}
                    disabled={(isUserAdmin || isUserLegal) ? false : true}

                >
                    <Tooltip title='Delete'>
                        <DeleteTwoTone twoToneColor='#00AEEF' className='font-20' />
                    </Tooltip>
                </Button>
            </div>
        ),
    }
    ];

    const showDeleteTemplate = (item) => {
        showConfirm('Do you want to delete ' + item.name.toUpperCase() + ' ?', () => {
            dispatch(deleteAdminTemplate(item.key, () => { dispatch(getAdminTemplateList()) }))
        })
    }
    return (
        <div>
            <Row>
                <Col span={24}>
                    <div className='gx-flex-row align-items-center'>
                        <h1 className='title gx-mb-4'>
                            <IntlMessages id='sidebar.compliance-template' />
                            </h1>
                        <Button id='add-product-btn' type='primary' className='gx-ml-auto' 
                         onClick={navigateToAddTemplate} 
                         disabled={(isUserAdmin || isUserLegal) ? false : true}
                        >
                            <MedicineBoxOutlined /> Add Template
                        </Button>
                    </div>
                </Col>
                <Col span={24}>
                    <Card className='mb-0'>
                        <Table
                            className='gx-table-responsive mpp-list-table'
                            columns={columns}
                            dataSource={data}
                            bordered
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ComplianceTemplate;