export const royaltySalesConstants = {
    GET_PARTNERS_PRODUCT_LIST_REQUEST: 'GET_PARTNERS_PRODUCT_LIST_REQUEST',
    GET_PARTNERS_PRODUCT_LIST_SUCCESS: 'GET_PARTNERS_PRODUCT_LIST_SUCCESS',
    GET_PARTNERS_PRODUCT_LIST_FAILURE: 'GET_PARTNERS_PRODUCT_LIST_FAILURE',

    GET_SALES_REQUEST_DATA_REQUEST: 'GET_SALES_REQUEST_DATA_REQUEST',
    GET_SALES_REQUEST_DATA_SUCCESS: 'GET_SALES_REQUEST_DATA_SUCCESS',
    GET_SALES_REQUEST_DATA_FAILURE: 'GET_SALES_REQUEST_DATA_FAILURE',

    GET_SALES_MAPPED_DATA_REQUEST: 'GET_SALES_MAPPED_DATA_REQUEST',
    GET_SALES_MAPPED_DATA_SUCCESS: 'GET_SALES_MAPPED_DATA_SUCCESS',
    GET_SALES_MAPPED_DATA_FAILURE: 'GET_SALES_MAPPED_DATA_FAILURE',

    DELETE_SALES_REQUEST_DATA_REQUEST: 'DELETE_SALES_REQUEST_DATA_REQUEST',
    DELETE_SALES_REQUEST_DATA_SUCCESS: 'DELETE_SALES_REQUEST_DATA_SUCCESS',
    DELETE_SALES_REQUEST_DATA_FAILURE: 'DELETE_SALES_REQUEST_DATA_FAILURE',

    RAISE_SALES_REQUEST_REQUEST: 'RAISE_SALES_REQUEST_REQUEST',
    RAISE_SALES_REQUEST_SUCCESS: 'RAISE_SALES_REQUEST_SUCCESS',
    RAISE_SALES_REQUEST_FAILURE: 'RAISE_SALES_REQUEST_FAILURE',

    EDIT_SALES_REQUEST_REQUEST: 'EDIT_SALES_REQUEST_REQUEST',
    EDIT_SALES_REQUEST_SUCCESS: 'EDIT_SALES_REQUEST_SUCCESS',
    EDIT_SALES_REQUEST_FAILURE: 'EDIT_SALES_REQUEST_FAILURE',

    SINGLE_SALE_VALUE_REQUEST: 'SINGLE_SALE_VALUE_REQUEST',
    SINGLE_SALE_VALUE_SUCCESS: 'SINGLE_SALE_VALUE_SUCCESS',
    SINGLE_SALE_VALUE_FAILURE: 'SINGLE_SALE_VALUE_FAILURE',

};