import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Col,
  Button,
  Descriptions,
} from "antd";
import {
  LeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
  UploadOutlined,
  InfoCircleTwoTone
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleInvoiceRequestData,
  editTaxInvoiceRequest,
  getPartnerInvoiceData,
  getPartnerTaxInvoiceData,
  getSingleTaxRequestData
} from "../../../appRedux/actions";
import { useNavigate} from "react-router-dom";
import { royalty_countries_product } from "../../../constants";
import moment from "moment";
import { getRole, getUserInfo, getIsApprover } from "../../../helpers";
const confirm = Modal.confirm;

const { TextArea } = Input;

const ViewTaxInvoiceTracking = (props) => {
  const { editTaxRequestData } = useSelector(
    ({ partnerInvoice }) => partnerInvoice
  );
  const [isAdminApprover, setIsAdminApprover] = useState([]);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserPartner, setIsUserPartner] = useState(false);
  const [isUserLicensor, setIsUserLicensor] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const fetchData = async () => {
    const { path, params } = props.match;
    await dispatch(getSingleTaxRequestData(params.id));
    setIsAdminApprover(getIsApprover());
    setIsUserAdmin(() => {
      return getRole() === "ADMIN";
    });
    setIsUserPartner(() => {
      return getRole() === "PARTNER";
    });
    setIsUserLicensor(() => {
      return getRole() === "LICENSOR";
    });
    if (isUserPartner) {
      setIsDisabled(editTaxRequestData.status !== "APPROVED")
    } else {
      setIsDisabled(editTaxRequestData.status !== "PENDING")
    }
    console.log(isUserPartner,"isUserPartner")
    console.log(isDisabled,"isDisabled")
  };
  console.log(isUserPartner,"isUserPartner2")

  const navigateToAddPaymentData = (id) => {
    const { history } = props;
    history.push(
      `/partner/partner-invoice/add-payment-proof/${editTaxRequestData.tax_id}`
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const navigateBack = () => {
    const { history } = props;
    history.goBack();
  };

  const isDisabledFunc = () => {
    if (isUserPartner) {
      return editTaxRequestData.status !== "UNDER PROCESS"
    }
    else {
      return editTaxRequestData.status !== "PENDING" && editTaxRequestData.status !== "NEEDS REVISION"
    }
  }

  const showCommentsForm = () => {
    return (
      <Form form={form} layout="vertical" className="mt-20">
        <div className="mb-10">
          <span>Add comments:</span>
        </div>
        <Form.Item
          name="comment"
          className="mb-0"
          rules={[
            {
              required: isUserAdmin,
              message: isUserAdmin ? "Enter comments" : "Enter comments",
            },
          ]}
        >
          <TextArea placeholder="Enter comments here" />
        </Form.Item>
      </Form>
    );
  };

  const showApproveConfirm = (item, partner_id) => {
    confirm({
      title: "Do you want to submit the report?",
      className: "btn-confirm-green",
      content: showCommentsForm(),
      icon: <CheckCircleOutlined />,
      okText: "Submit",
      cancelText: "Cancel",
      onOk(close) {
        form.validateFields().then((values) => {
          const approveFormData = new FormData();

          approveFormData.append("comment", values.comment);
          approveFormData.append("partner_id", parseInt(partner_id));
          if (isUserPartner){
            approveFormData.append("status", "ACCEPTED");
          }
          else {
            approveFormData.append("status", "APPROVED");
            approveFormData.append("is_approved", true);
          }
          dispatch(
            editTaxInvoiceRequest(
              item,
              approveFormData,
              () => {
                dispatch(getPartnerInvoiceData());
                navigateBack();
              },
              "Approved"
            )
          );
          close();
        });
      },
      onCancel() {
        form.resetFields(["comment"]);
      },
    });
  };

  const showPaidConfirm = (item, partner_id) => {
    confirm({
      title: "Do you want to request for revisions?",
      className: "btn-confirm-primary",
      content: showCommentsForm(),
      okText: "Submit",
      cancelText: "Cancel",
      icon: <ExclamationCircleOutlined />,
      onOk(close) {
        form.validateFields().then((values) => {
          const rejectFormData = new FormData();
          rejectFormData.append("status", "NEEDS REVISION");
          rejectFormData.append("comment", values.comment);
          rejectFormData.append("partner_id", parseInt(partner_id));
          dispatch(
            editTaxInvoiceRequest(
              item,
              rejectFormData,
              () => {
                dispatch(getPartnerInvoiceData());
                navigateBack();
              },
              "Status Sent"
            )
          );
          close();
        });
      },
      onCancel() {
        form.resetFields(["comment"]);
      },
    });
  };
  const labelStyle = { width: "200px" };

  return (
    <div>
      <Col span={24}>
        <div
          className="gx-flex-row"
          style={{ justifyContent: "space-between" }}
        >
          <h1 className="title gx-mb-4">
            <LeftOutlined className="mr-5" onClick={navigateBack} />
            Tax Document Information
          </h1>
          {(isUserAdmin || isUserPartner) && (
            <div>
              <Button
                className="gx-btn-success"
                icon={<CheckOutlined />}
                onClick={() =>
                  showApproveConfirm(
                    editTaxRequestData.tax_id,
                    editTaxRequestData.partner_id.partner_id
                  )
                }
                id={`approve-${editTaxRequestData.tax_id}`}
                disabled={isDisabledFunc()}
              >
                {isUserPartner?"Accept":"Submit"}
              </Button>

              <Button
                className="gx-btn-danger"
                icon={<ExclamationCircleOutlined />}
                onClick={() =>
                  showPaidConfirm(
                    editTaxRequestData.tax_id,
                    editTaxRequestData.partner_id.partner_id
                  )
                }
                id={`reject-${editTaxRequestData.tax_id}`}
                disabled={isDisabledFunc()}
              >
                Needs Revision
              </Button>
            </div>
          )}
        </div>
      </Col>
      <Col span={12}>
        <Descriptions bordered column={1}>
        <Descriptions.Item label="Year" style={labelStyle}>
            {editTaxRequestData.year}
          </Descriptions.Item>
          <Descriptions.Item label="Company Name" style={labelStyle}>
            {editTaxRequestData?.partner_id?.company_name}
          </Descriptions.Item>
          {(isUserAdmin || isUserPartner) && (
          <Descriptions.Item label="Status" style={labelStyle}>
              <span
                style={{
                  color:
                    editTaxRequestData.status === "APPROVED"
                      ? "green"
                      :
                        editTaxRequestData.status === "NEEDS REVISION"
                      ? "red"
                      : "black",
                }}
              >
                {editTaxRequestData.status}
              </span>
          </Descriptions.Item>
          )}
          {(isUserLicensor) && (
          <Descriptions.Item label="Status" style={labelStyle}>
              <span
                style={{
                  color:
                    editTaxRequestData.licensor_status === "APPROVED"
                      ? "green"
                      :
                        editTaxRequestData.licensor_status === "NEEDS REVISION"
                      ? "red"
                      : "black",
                }}
              >
                {editTaxRequestData.licensor_status}
              </span>
          </Descriptions.Item>
          )}
          {editTaxRequestData?.comment && (
            <Descriptions.Item label="Comments" style={labelStyle}>
              {editTaxRequestData.comment}
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Document" style={labelStyle}>
            {editTaxRequestData?.invoice_file?.map((file, index) => (
              <>
                <a
                  key={file.id}
                  href={file.document}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DOC {index + 1}
                </a>
                <br />
              </>
            ))}
          </Descriptions.Item>
          {/* Add more descriptions for other fields */}
        </Descriptions>
      </Col>
    </div>
  );
};

export default ViewTaxInvoiceTracking;
