export const MOMConstants = {

  DOWNLOAD_MOM_REQUEST: "DOWNLOAD_MOM_REQUEST",
  DOWNLOAD_MOM_SUCCESS: "DOWNLOAD_MOM_SUCCESS",
  DOWNLOAD_MOM_FAILURE: "DOWNLOAD_MOM_FAILURE",

 UPLOAD_MOM_REQUEST: "UPLOAD_MOM_REQUEST",
 UPLOAD_MOM_SUCCESS: "UPLOAD_MOM_SUCCESS",
 UPLOAD_MOM_FAILURE: "UPLOAD_MOM_FAILURE",
 
GET_MOM_REQUEST: "GET_MOM_REQUEST",
GET_MOM_SUCCESS: "GET_MOM_SUCCESS",
GET_MOM_FAILURE: "GET_MOM_FAILURE",

}