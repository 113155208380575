import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Button, Card } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { LeftOutlined } from "@ant-design/icons";
import "./texteditor.css";
import { useDispatch, useSelector } from "react-redux";
import {
  adminAddTemplate,
  adminEditTemplate,
  getAdminTemplate,
  resetTemplate,
} from "../../../appRedux/actions";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AddComplianceTemplate = (props) => {
  const { template_id } = props.match.params;
  const { istemplateAdded, isLoaded, templateItem, istemplateEdited } =
    useSelector(({ adminCompliance }) => adminCompliance);
  const [editFlag, setEditFlag] = useState(false);
  const dispatch = useDispatch();

  const [complianceEditorData, SetcomplianceEditorData] = useState("");
  const handleChange = (e, editor) => {
    const data = editor.getData();
    SetcomplianceEditorData(data);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (template_id) {
      setEditFlag(true);
      dispatch(getAdminTemplate(template_id));
    }
  }, []);

  useEffect(() => {
    if (istemplateAdded || istemplateEdited) {
      form.resetFields();
      props.history.push("/admin/compliance-template");
    }
    if (isLoaded && editFlag) {
      form.setFieldsValue(templateItem);
    }
  }, [istemplateAdded, istemplateEdited, isLoaded]);

  const onFinish = (values) => {
    const postData = {
      template_name: values.template_name,
      template: complianceEditorData,
    };

    if (editFlag) {
      dispatch(
        adminEditTemplate(template_id, postData, () => {
          dispatch(resetTemplate());
        })
      );
    } else {
      dispatch(
        adminAddTemplate(postData, () => {
          dispatch(resetTemplate());
        })
      );
    }
  };

  const navigateBack = () => {
    const { history } = props;
    history.goBack();
  };

  const navigateToTemplateList = () => {
    const { history } = props;
    history.push("/admin/compliance-template");
  };

  const editorConfig = {
    lineSpacing: {
      options: [
        { model: "1", title: "Single", class: "ck-line-spacing-1" },
        { model: "1.15", title: "1.15", class: "ck-line-spacing-1-15" },
        { model: "1.5", title: "1.5", class: "ck-line-spacing-1-5" },
        { model: "2", title: "Double", class: "ck-line-spacing-2" },
        { model: "3", title: "Triple", class: "ck-line-spacing-3" },
      ],
    },
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <h1 className="title gx-mb-4">
            <LeftOutlined className="mr-5" onClick={navigateBack} />
            {editFlag ? "Edit Compliance Template" : "Add Compliance Template"}
          </h1>
        </Col>

        <Col span={24}>
          <Card className="gx-card">
            <Row>
              <Col span={22}>
                <Form
                  {...formItemLayout}
                  form={form}
                  name="add-template"
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  <Form.Item
                    name="template_name"
                    label="Template Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Template name!",
                      },
                    ]}
                  >
                    <Input id="add-template-name" />
                  </Form.Item>
                  <div className="text-editor-wrapper">
                    <div className="extra-info-display">
                      <table>
                        <tbody>
                          <tr>
                            <td>{`{{LICENSEE}}`}</td>
                            <td>{`|`}</td>
                            <td>Name of Partner/company</td>
                          </tr>
                          <tr>
                            <td>{`{{LICENSEE_ADDRESS}}`}</td>
                            <td>{`|`}</td>
                            <td>Address of Partner/Company</td>
                          </tr>
                          <tr>
                            <td>{`{{DATE}}`}</td>
                            <td>{`|`}</td>
                            <td>Current Date</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {editFlag ? (
                      <div className="text-editor">
                        <CKEditor
                          editor={ClassicEditor}
                          data={
                            templateItem.template ? templateItem.template : ""
                          }
                          onReady={() => {}}
                          onChange={handleChange}
                        />
                      </div>
                    ) : (
                      <div className="text-editor">
                        <CKEditor
                          editor={ClassicEditor}
                          data=""
                          onReady={() => {}}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </div>

                  <Form.Item className="flex-d-row-reverse">
                    <Button
                      id="add-product-submit"
                      type="primary"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      id="add-product-cancel"
                      onClick={navigateToTemplateList}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default AddComplianceTemplate;
