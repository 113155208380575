import { useState, useRef, useDispatch } from "react";
import React from "react";
import "./needs-clarification.css";
import classNames from "classnames";
import {
    getSalesRequestData,
    editRoyaltySalesRequestData,
  } from "../../../appRedux/actions";
  import {
    Modal,
    Button,
    List,
    Input,
  } from "antd";
  const { TextArea } = Input;

const ClarificationChat = ({isUserPartner,isChatModalVisible, setIsChatModalVisible, setChatConversation, chatConversation}) => {
    const [messageInput, setMessageInput] = useState("");
    const dispatch = useDispatch();


    const handleMessageChange = (e) => {
        setMessageInput(e.target.value);
        };

    const handleSendMessage = (item) => {
        if (messageInput.trim() !== "") {
            const newMessage = {
            message: messageInput.trim(),
            is_partner: isUserPartner,
            };
            const rejectFormData = new FormData();
            rejectFormData.append("status", "NEEDS CLARIFICATION");
            rejectFormData.append("message", newMessage.message);
            dispatch(
            editRoyaltySalesRequestData(
                item,
                rejectFormData,
                () => {
                dispatch(getSalesRequestData());
                },
                "Message Sent"
            )
            );
            const updatedChatConversation = [...chatConversation, newMessage];
            setMessageInput("");
            setChatConversation(updatedChatConversation);
        }
        };

    return (
        <Modal
            title="Clarification Chat"
            visible={isChatModalVisible}
            onCancel={() => setIsChatModalVisible(false)}
            footer={null}
        >
            <div style={{ maxHeight: 400, overflowY: "auto" }}>
            <List
                itemLayout="horizontal"
                dataSource={chatConversation}
                renderItem={(message, index) => (
                <List.Item
                    className={classNames("chat-message", {
                    "chat-message-user": !message.is_partner,
                    "chat-message-partner": message.is_partner,
                    })}
                >
                    <List.Item.Meta
                    description={
                        <div
                        style={{
                            background: message.is_partner ? "#f6f6f6" : "#e6f7ff",
                        }}
                        className="chat-message-content"
                        >
                        {message.message}
                        </div>
                    }
                    />
                </List.Item>
                )}
            />
            </div>
            <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
            }}
            >
            <TextArea
                rows={3}
                value={messageInput}
                onChange={handleMessageChange}
                placeholder="Type your message..."
                autoFocus
                style={{ flex: 1, marginRight: 10 }}
            />
            <Button
                type="primary"
                onClick={() => {
                handleSendMessage(chatConversation[0].request_id);
                }}
            >
                Send
            </Button>
            </div>
        </Modal>
    );
};

export default ClarificationChat;
