import { config } from "../constants";
import { authHeader } from "../helpers";
import { handleResponse } from "./handle-response";

export const getPartnerComplianceTemplateList = (data) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let getURL = `${config.API_URL}/template-letter/admin/?page=${
    data.page_number
  }&page_size=${data.page_size}&company_name=${
    data?.company_name || ""
  }&email=${data?.email || ""}&template_filter=${
    data?.template_filter || ""
  }&status=${data?.status || ""}&verification_status=${
    data?.verification_status || ""
  }`;

  if (data?.quarter) {
    getURL = `${config.API_URL}/template-letter/admin/?page=${
      data.page_number
    }&page_size=${data.page_size}&company_name=${
      data?.company_name || ""
    }&email=${data?.email || ""}&year=${data?.quarter}&template_filter=${
      data?.template_filter || ""
    }&status=${data?.status || ""}&verification_status=${
      data?.verification_status || ""
    }`;
  }
  return fetch(getURL, requestOptions).then(handleResponse);
};

export const getPartnerList = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${config.API_URL}/admin/partner/`, requestOptions).then(
    handleResponse
  );
};

export const getEmailTemplate = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${config.API_URL}/email-template/${id}`, requestOptions).then(
    handleResponse
  );
};

export const putEmailTemplate = (id, data) => {
  console.log(id, data);
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${config.API_URL}/email-template/${id}/`, requestOptions);
};

export const getAssignedList = (id) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/template-letter/admin/${id}/`,
    requestOptions
  ).then(handleResponse);
};

export const getNotificationData = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(`${config.API_URL}/admin/notification/`, requestOptions).then(
    handleResponse
  );
};

export const markNotificationRead = () => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/admin/notification-read/`,
    requestOptions
  ).then(handleResponse);
};

export const getAdminComplianceListData = () => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/template-letter-create/`,
    requestOptions
  ).then(handleResponse);
};

export const sendComplianceReminder = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.API_URL}/compliance-letter-reminder/`,
    requestOptions
  ).then(handleResponse);
};

export const sendComplianceMail = (id, data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${config.API_URL}/html_template/${id}/ `, requestOptions).then(
    handleResponse
  );
};

export const getPartnerComplianceMessages = (partnerId, templateId) => {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/admin/compliance-message?template_id=${templateId}&partner_id=${partnerId} `,
    requestOptions
  ).then(handleResponse);
};

export const postPartnerComplianceMessages = (
  templateId,
  message,
  partnerId,
  isAdmin
) => {
  const requestOptions = isAdmin
    ? {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({
          partner_id: partnerId,
          template_id: templateId,
          message: message,
        }),
      }
    : {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ template_id: templateId, message: message }),
      };

  return fetch(
    `${config.API_URL}/admin/compliance-message/`,
    requestOptions
  ).then(handleResponse);
};

export const postPartnerComplianceMessageEmail = (
  templateId,
  partnerId,
  isAdmin
) => {
  const requestOptions = isAdmin
    ? {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({
          template_id: templateId,
          partner_id: partnerId,
        }),
      }
    : {
        method: "POST",
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify({ template_id: templateId }),
      };

  return fetch(
    `${config.API_URL}/compliance-message/email/`,
    requestOptions
  ).then(handleResponse);
};

export const downloadComplianceTemplate = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.API_URL}/download-compliance-letter/`,
    requestOptions
  ).then((response) => response.json());
};

export const downloadSignedTemplate = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.API_URL}/download-compliance-letter/`,
    requestOptions
  ).then((response) => response.json());
};

export const verifySignedLetter = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.API_URL}/compliance-template-letter/approve/`,
    requestOptions
  ).then(handleResponse);
};

export const rejectSignedLetter = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.API_URL}/compliance-template-letter/approve/`,
    requestOptions
  ).then(handleResponse);
};

export const deletePartnerTemplate = (data) => {
  const partnerId = data.partner_id;
  delete data.partner_id;
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(
    `${config.API_URL}/template-letter/admin/${partnerId}/`,
    requestOptions
  ).then(handleResponse);
};

export const assignTemplateToPartner = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${config.API_URL}/template-letter/admin/`, requestOptions).then(
    handleResponse
  );
};

export const sendBulkReminderPartnerComp = (data) => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${config.API_URL}/compliance-bulk-reminder/`,
    requestOptions
  ).then(handleResponse);
};
