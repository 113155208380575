export const config = {
  API_URL: process.env.REACT_APP_API_ENDPOINT,
  // API_URL: 'http://3.6.94.126:8000/api',
};

export const sentryData = {
  SENTRY_DSN:
    "https://7384d5f97c8c430b9818607c6929631b@o445214.ingest.sentry.io/5421223",
};

export const royalty_countries_product = [
  {
    country_id: 3,
    country_name: "Algeria",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 12,
    country_name: "Azerbaijan",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 8,
    country_name: "Armenia",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 17,
    country_name: "Belarus",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 54,
    country_name: "Egypt",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 79,
    country_name: "India",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 80,
    country_name: "Indonesia",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 89,
    country_name: "Kazakhstan",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 108,
    country_name: "Malaysia",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 117,
    country_name: "Moldova, Republic of",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 118,
    country_name: "Mongolia",
    product: [
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
    ],
  },
  {
    country_id: 121,
    country_name: "Morocco",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 142,
    country_name: "Philippines",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 181,
    country_name: "Tunisia",
    product: [
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
    ],
  },
  {
    country_id: 183,
    country_name: "Turkmeinstan",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 187,
    country_name: "Ukraine",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
  {
    country_id: 195,
    country_name: "Viet Nam",
    product: [
      { product_id: 18, product_name: "DTG adult (50 mg)" },
      { product_id: 35, product_name: "TLD - TDF/3TC/DTG (300/300/50 mg)" },
      { product_id: 2, product_name: "ALD - ABC/3TC/DTG (600/300/50 mg)" },
      { product_id: 15, product_name: "DTG/3TC (50/300 mg)" },
      { product_id: 16, product_name: "DTG/RPV" },
      { product_id: 32, product_name: "TAF/FTC/DTG (25/200/50 mg)" },
      { product_id: 30, product_name: "TAF/3TC/DTG (25/300/50 mg)" },
    ],
  },
];

export const royaltyApprovalStatus = ["APPROVED", "REJECTED", "PENDING","NEEDS CLARIFICATION","REVISIONS REQUIRED"];
export const royaltyInvoiceStatus = ["PAID", "REJECTED", "PENDING","APPROVED"];
