import { config } from "../constants";
import { authHeader } from "../helpers";
import { handleResponse } from "./handle-response";


export const productAvailabilities = ({}) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader()},
      body: {},
    };
  
    return fetch(
      `${config.API_URL}/product-availabilities/`,
      requestOptions
    ).then(handleResponse);
  };

  export const productDevelopment = ({}) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
  
    return fetch(
      `${config.API_URL}/product-developments/`,
      requestOptions
    ).then(handleResponse);
  };

  export const syncInteractiveEntry = ({}) => {
    const requestOptions = {
      method: "POST",
      headers: { ...authHeader(), "Content-Type": "application/json" },
      body: JSON.stringify({}),
    };
  
    return fetch(
      `${config.API_URL}/sync-interactive-entry/`,
      requestOptions
    ).then(handleResponse);
  };


  export const getInteractiveMapLogs = () => {
    const requestOptions = {
      method: "GET",
      headers: { ...authHeader(), "Content-Type": "application/json" },
    };
  
    return fetch(
      `${config.API_URL}/admin/interactive-map-logs/`,
      requestOptions
    ).then(handleResponse);
  };