import { royaltyInvoiceConstants, taxInvoiceConstants } from "../../constants";
import { hideLoader, showLoader } from "./Loader";
import {
  getRoyaltyPartnerList,
  getLicensorsPartnerList,
  getRoyaltyTaxInvoiceRequestData,
  deleteInvoiceRequest,
  addTaxInvoiceRequest,
  editInvoiceRequest,
  editTaxInvoiceRequestData,
  getSingleRoyaltyInvoiceRequestData,
  getSingleTaxRequest
} from "../../services";
import { showMessage } from "../../helpers";


export const getRoyaltyPartnersList = () => {
  return (dispatch) => {
    dispatch({ type: royaltyInvoiceConstants.GET_PARTNERS_LIST_REQUEST });
    getRoyaltyPartnerList()
      .then((response) => {
        dispatch({
          type: royaltyInvoiceConstants.GET_PARTNERS_LIST_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: royaltyInvoiceConstants.GET_PARTNERS_LIST_FAILURE });
        console.log(error);
      });
  };
};

export const getLicensorsPartnersList = () => {
  return (dispatch) => {
    dispatch({ type: royaltyInvoiceConstants.GET_PARTNERS_LIST_REQUEST });
    getLicensorsPartnerList()
      .then((response) => {
        dispatch({
          type: royaltyInvoiceConstants.GET_PARTNERS_LIST_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: royaltyInvoiceConstants.GET_PARTNERS_LIST_FAILURE });
        console.log(error);
      });
  };
};

export const getPartnerTaxInvoiceData = () => {
  return (dispatch) => {
    dispatch({ type: taxInvoiceConstants.GET_TAX_REQUEST_DATA_REQUEST });
    getRoyaltyTaxInvoiceRequestData()
      .then((response) => {
        dispatch({
          type: taxInvoiceConstants.GET_TAX_REQUEST_DATA_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: taxInvoiceConstants.GET_TAX_REQUEST_DATA_FAILURE });
        console.log(error);
      });
  };
};
export const editTaxInvoiceRequest = (id, data, onSuccess,status) => {
  return (dispatch) => {
    dispatch({ type: taxInvoiceConstants.EDIT_TAX_REQUEST_REQUEST });
    editTaxInvoiceRequestData(id,data)
      .then((response) => {
        dispatch({
          type: taxInvoiceConstants.EDIT_TAX_REQUEST_SUCCESS,
          data: response ? response : [],
        });
        showMessage("success", "Edited Request!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({ type: taxInvoiceConstants.EDIT_TAX_REQUEST_FAILURE });
        console.log(error);
      });
  };
};

export const getSingleTaxRequestData = (id) => {
  return (dispatch) => {
    dispatch({ type: taxInvoiceConstants.SINGLE_TAX_VALUE_REQUEST });
    getSingleTaxRequest(id)
      .then((response) => {
        dispatch({
          type: taxInvoiceConstants.SINGLE_TAX_VALUE_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch({ type: taxInvoiceConstants.SINGLE_TAX_VALUE_FAILURE });
        console.log(error);
      });
  };
};

// export const getSingleInvoiceRequestData = (id) => {
//   return (dispatch) => {
//     dispatch({ type: royaltyInvoiceConstants.SINGLE_INVOICE_VALUE_REQUEST });
//     getSingleRoyaltyInvoiceRequestData(id)
//       .then((response) => {
//         dispatch({
//           type: royaltyInvoiceConstants.SINGLE_INVOICE_VALUE_SUCCESS,
//           data: response ? response : [],
//         });
//       })
//       .catch((error) => {
//         dispatch({ type: royaltyInvoiceConstants.SINGLE_INVOICE_VALUE_FAILURE });
//         console.log(error);
//       });
//   };
// };


// export const deleteInvoiceRequestData = (id, onSuccess) => {
//   return (dispatch) => {
//     dispatch(showLoader());
//     dispatch({ type: royaltyInvoiceConstants.DELETE_PARTNER_INVOICE_REQUEST_DATA_REQUEST });
//     deleteInvoiceRequest(id)
//       .then((response) => {
//         dispatch(hideLoader());
//         dispatch({ type: royaltyInvoiceConstants.DELETE_PARTNER_INVOICE_REQUEST_DATA_SUCCESS });
//         showMessage("success", "Removed!");
//         if (onSuccess) {
//           onSuccess();
//         }
//       })
//       .catch((error) => {
//         dispatch(hideLoader());
//         dispatch({ type: royaltyInvoiceConstants.DELETE_PARTNER_INVOICE_REQUEST_DATA_FAILURE });
//         console.log(error);
//       });
//   };
// };

export const raisePartnerTaxInvoiceRequest = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({ type: taxInvoiceConstants.RAISE_TAX_REQUEST_REQUEST });
    addTaxInvoiceRequest(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({ type: taxInvoiceConstants.RAISE_TAX_REQUEST_SUCCESS });
        showMessage("success", "Tax Invoice Request raised succcessfully!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({ type: taxInvoiceConstants.RAISE_TAX_REQUEST_FAILURE });
        let errorMsg =
          error && error.error
            ? error.error.filter((value) => value !== "None")
            : "";
        if (errorMsg.length) {
          showMessage("error", "Error");
        }
      });
  };
};

// export const editRoyaltyInvoiceRequestData = (id, data, onSuccess,status) => {
//   return (dispatch) => {
//     dispatch(showLoader());
//     dispatch({ type: royaltyInvoiceConstants.EDIT_INVOICE_REQUEST_REQUEST });
//     editInvoiceRequest(id, data)
//       .then((response) => {
//         dispatch(hideLoader());
//         dispatch({ type: royaltyInvoiceConstants.EDIT_INVOICE_REQUEST_SUCCESS });
//         if(status){
//           showMessage("success", status);
//         }else{
//           showMessage("success", "Request edited succcessfully!");
//         }

//         if (onSuccess) {
//           onSuccess();
//         }
//       })
//       .catch((error) => {
//         dispatch(hideLoader());
//         dispatch({ type: royaltyInvoiceConstants.EDIT_INVOICE_REQUEST_FAILURE });
//         let errorMsg =
//           error && error.error
//             ? error.error.filter((value) => value !== "None")
//             : "";
//         if (errorMsg.length) {
//           showMessage("error", errorMsg[0]);
//           showMessage("error", "Error");
//         }
//       });
//   };
// };

