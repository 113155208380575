import * as FileSaver from "file-saver";
import { partnerComplianceLetterConstants } from "../../constants";
import { hideLoader, showLoader } from "./Loader";
import {
  getPartnerComplianceLetterList,
  downloadPartnerLetters,
  sendPartneSignedLetter,
  sendNotificationForComplianceLetter,
} from "../../services";
import { showMessage } from "../../helpers";

export const getPartnerComplianceLetter = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceLetterConstants.GET_PARTNER_COMPLIANCE_LETTER_REQUEST,
    });
    getPartnerComplianceLetterList(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceLetterConstants.GET_PARTNER_COMPLIANCE_LETTER_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceLetterConstants.GET_PARTNER_COMPLIANCE_LETTER_FAILURE,
        });
        console.log(error);
      });
  };
};

export const downloadPartnerLetter = (filename, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceLetterConstants.DOWNLOAD_PARTNER_LETTER_REQUEST,
    });
    downloadPartnerLetters(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceLetterConstants.DOWNLOAD_PARTNER_LETTER_SUCCESS,
        });
        if (response.url) window.open(response.url);
        // FileSaver.saveAs(response, filename);
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceLetterConstants.DOWNLOAD_PARTNER_LETTER_FAILURE,
        });
        console.log(error);
      });
  };
};

export const sendPartnerSignedPDF = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceLetterConstants.SEND_PARTNER_SIGNED_LETTER_REQUEST,
    });
    sendPartneSignedLetter(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceLetterConstants.SEND_PARTNER_SIGNED_LETTER_SUCCESS,
        });
        if (onSuccess) {
          let formData = new FormData();
          formData.append(
            "template_history_id",
            data.get("template_history_id")
          );
          onSuccess();
          sendNotificationForComplianceLetter(formData);
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceLetterConstants.SEND_PARTNER_SIGNED_LETTER_FAILURE,
        });
        showMessage("error", error.toString());
      });
  };
};
