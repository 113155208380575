import React, { useEffect } from "react";
import { Button, DatePicker, Form, Card, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  adminGetCutOffDate,
  adminSetCutOffDate,
  adminGetLetterSubmissionDate,
  adminSETLetterSubmissionDate,
} from "../../../appRedux/actions";
import { LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import { getRole } from "../../../helpers";
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AdminSettings = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [dateform] = Form.useForm();

  const { isLoaded, cutOffDate, letterSubmissionDate } = useSelector(
    ({ adminPartner }) => adminPartner
  );

  useEffect(() => {
    dispatch(adminGetCutOffDate());
    dispatch(adminGetLetterSubmissionDate());
  }, []);

  useEffect(() => {
    if (isLoaded) {
      form.setFieldsValue({
        date:
          cutOffDate && cutOffDate.cut_off_date
            ? moment(cutOffDate.cut_off_date)
            : "",
      });
      dateform.setFieldsValue({
        date:
          letterSubmissionDate && letterSubmissionDate.letter_submission_date
            ? moment(letterSubmissionDate.letter_submission_date)
            : "",
      });
    }
  }, [cutOffDate, letterSubmissionDate]);

  const config = {
    rules: [{ type: "object", required: true, message: "Please select date!" }],
  };

  const navigateBack = () => {
    props.history.goBack();
  };

  const navigateToDashboard = () => {
    props.history.push("/");
  };

  const onFinish = (values) => {
    dispatch(adminSetCutOffDate({ date: values.date.format("DD-MM-yyyy") }));
  };
  const onLetterDateSubmission = (values) => {
    dispatch(
      adminSETLetterSubmissionDate({ date: values.date.format("DD-MM-yyyy") })
    );
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <h1 className="title gx-mb-4">
            <LeftOutlined className="mr-5" onClick={navigateBack} />
            Admin Settings
          </h1>
        </Col>
        <Col span={24}>
          <Card className="gx-card">
            <Row>
              <Col span={22}>
                <Form
                  name="time_related_controls"
                  onFinish={onFinish}
                  form={form}
                  className="gx-signin-form gx-form-row0"
                  {...formItemLayout}
                >
                  <Form.Item
                    name="date"
                    {...config}
                    label="Set Report submission date"
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      disabled={getRole() === "ADMIN" ? false : true}
                    />
                  </Form.Item>

                  <Form.Item className="flex-d-row-reverse">
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={getRole() === "ADMIN" ? false : true}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={navigateToDashboard}
                      disabled={getRole() === "ADMIN" ? false : true}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={2}></Col>
            </Row>
            <Row>
              <Col span={22}>
                <Form
                  name="time_related_controls"
                  onFinish={onLetterDateSubmission}
                  form={dateform}
                  className="gx-signin-form gx-form-row0"
                  {...formItemLayout}
                >
                  <Form.Item
                    name="date"
                    {...config}
                    label="Set letter submission date"
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      disabled={getRole() === "ADMIN" ? false : true}
                    />
                  </Form.Item>

                  <Form.Item className="flex-d-row-reverse">
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={getRole() === "ADMIN" ? false : true}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={navigateToDashboard}
                      disabled={getRole() === "ADMIN" ? false : true}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col span={2}></Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminSettings;
