import * as FileSaver from "file-saver";
import { partnerComplianceTemplateConstants } from "../../constants";
import { hideLoader, showLoader } from "./Loader";
import {
  getPartnerComplianceTemplateList,
  getPartnerList,
  getAssignedList,
  getAdminComplianceListData,
  sendComplianceReminder,
  sendComplianceMail,
  downloadComplianceTemplate,
  downloadSignedTemplate,
  verifySignedLetter,
  rejectSignedLetter,
  deletePartnerTemplate,
  assignTemplateToPartner,
  sendBulkReminderPartnerComp,
  getNotificationData,
  markNotificationRead,
  getPartnerComplianceMessages,
  postPartnerComplianceMessages,
  postPartnerComplianceMessageEmail,
} from "../../services";
import { showMessage } from "../../helpers";

export const getPartnerComplianceList = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.GET_PARTNER_COMPLIANCE_LIST_REQUEST,
    });
    getPartnerComplianceTemplateList(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_PARTNER_COMPLIANCE_LIST_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_PARTNER_COMPLIANCE_LIST_FAILURE,
        });
        console.log(error);
      });
  };
};

export const getNotificationList = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.GET_NOTIFICATION_REQUEST,
    });
    getNotificationData(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_NOTIFICATION_REQUEST_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_NOTIFICATION_REQUEST_FAILURE,
        });
        console.log(error);
      });
  };
};

export const partnerComplianceMarkNotificationRead = (onSuccess) => {
  return (dispatch) => {
    dispatch({
      type: partnerComplianceTemplateConstants.NOTIFICATION_READ_REQUEST,
    });
    markNotificationRead()
      .then((response) => {
        dispatch({
          type: partnerComplianceTemplateConstants.NOTIFICATION_READ_REQUEST_SUCCESS,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: partnerComplianceTemplateConstants.NOTIFICATION_READ_REQUEST_FAILURE,
        });
        showMessage("error", error.toString());
      });
  };
};

export const getPartnersList = (onsuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.GET_PARTNER_LIST_REQUEST,
    });
    getPartnerList()
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_PARTNER_LIST_SUCCESS,
          data: response ? response : [],
        });
        if (onsuccess) {
          onsuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_PARTNER_LIST_FAILURE,
        });
        console.log(error);
      });
  };
};

export const getAssignedComplianceList = (id, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.GET_ASSIGNED_LIST_REQUEST,
    });
    getAssignedList(id)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_ASSIGNED_LIST_SUCCESS,
          data: response ? response : [],
        });
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_ASSIGNED_LIST_FAILURE,
        });
        console.log(error);
      });
  };
};

export const getAdminComplianceList = (onsuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.GET_ADMIN_COMPLIANCE_LIST_REQUEST,
    });
    getAdminComplianceListData()
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_ADMIN_COMPLIANCE_LIST_SUCCESS,
          data: response ? response : [],
        });
        if (onsuccess) {
          onsuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.GET_ADMIN_COMPLIANCE_LIST_FAILURE,
        });
        console.log(error);
      });
  };
};

export const getComplianceMessages = (partnerId, templateId) => {
  return (dispatch) => {
    dispatch({
      type: partnerComplianceTemplateConstants.GET_COMPLIANCE_MESSAGE_REQUEST,
    });
    getPartnerComplianceMessages(partnerId, templateId)
      .then((response) => {
        dispatch({
          type: partnerComplianceTemplateConstants.GET_COMPLIANCE_MESSAGE_SUCCESS,
          data: response ? response : [],
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: partnerComplianceTemplateConstants.GET_COMPLIANCE_MESSAGE_FAILURE,
        });
        console.log(error);
      });
  };
};

export const postComplianceMessages = (
  partnerId,
  templateId,
  isAdmin,
  message
) => {
  return (dispatch) => {
    dispatch({
      type: partnerComplianceTemplateConstants.POST_COMPLIANCE_MESSAGE_REQUEST,
    });
    postPartnerComplianceMessages(templateId, message, partnerId, isAdmin)
      .then((response) => {
        dispatch({
          type: partnerComplianceTemplateConstants.POST_COMPLIANCE_MESSAGE_SUCCESS,
          data: response ? response : [],
        });
        postPartnerComplianceMessageEmail(templateId, partnerId, isAdmin);
      })
      .catch((error) => {
        dispatch({
          type: partnerComplianceTemplateConstants.POST_COMPLIANCE_MESSAGE_FAILURE,
        });
        console.log(error);
      });
  };
};

export const sendPartnerComplianceReminder = (data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.SEND_COMPLIANCE_REMINDER_REQUEST,
    });
    sendComplianceReminder(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.SEND_COMPLIANCE_REMINDER_SUCCESS,
        });
        showMessage("success", "Reminder sent succcessfully!");
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.SEND_COMPLIANCE_REMINDER_FAILURE,
        });
        showMessage("error", error.toString());
      });
  };
};

export const sendPartnerComplianceMail = (id, data, onsuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.SEND_COMPLIANCE_MAIL_REQUEST,
    });
    sendComplianceMail(id, data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.SEND_COMPLIANCE_MAIL_SUCCESS,
        });
        showMessage("success", "Mail sent succcessfully!");
        if (onsuccess) {
          onsuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.SEND_COMPLIANCE_MAIL_FAILURE,
        });
        showMessage("error", error.toString());
      });
  };
};

export const downloadTemplatePDF = (filename, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.DOWNLOAD_TEMPLATE_REQUEST,
    });
    downloadComplianceTemplate(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.DOWNLOAD_TEMPLATE_SUCCESS,
        });
        if (response.url) window.open(response.url);
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.DOWNLOAD_TEMPLATE_FAILURE,
        });
        console.log(error);
      });
  };
};

export const downloadSignedTemplatePDF = (filename, data) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.DOWNLOAD_SIGNED_TEMPLATE_REQUEST,
    });
    downloadSignedTemplate(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.DOWNLOAD_SIGNED_TEMPLATE_SUCCESS,
        });
        if (response.url) window.open(response.url);
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.DOWNLOAD_SIGNED_TEMPLATE_FAILURE,
        });
        console.log(error);
      });
  };
};

export const verifyPartnerSignedLetter = (data, onsuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.VERIFY_PARTNER_SIGNED_LETTER_REQUEST,
    });
    verifySignedLetter(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.VERIFY_PARTNER_SIGNED_LETTER_SUCCESS,
        });
        showMessage("success", "Verified");
        if (onsuccess) {
          onsuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.VERIFY_PARTNER_SIGNED_LETTER_FAILURE,
        });
        showMessage("error", error.toString());
      });
  };
};

export const rejectPartnerSignedLetter = (data, onsuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.REJECT_PARTNER_SIGNED_LETTER_REQUEST,
    });
    rejectSignedLetter(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.REJECT_PARTNER_SIGNED_LETTER_SUCCESS,
        });
        showMessage("success", "Rejected");
        if (onsuccess) {
          onsuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.REJECT_PARTNER_SIGNED_LETTER_FAILURE,
        });
        showMessage("error", error.toString());
      });
  };
};

export const deleteAssignedParnterCompliance = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.DELETE_PARTNER_COMPLIANCE_REQUEST,
    });
    deletePartnerTemplate(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.DELETE_PARTNER_COMPLIANCE_SUCCESS,
        });
        showMessage("success", "Template Deleted!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.DELETE_PARTNER_COMPLIANCE_FAILURE,
        });
        console.log(error);
      });
  };
};

export const adminAssignTemplate = (data, onSuccess, onError) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.ASSIGN_TEMPLATE_PARTNER_REQUEST,
    });
    assignTemplateToPartner(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.ASSIGN_TEMPLATE_PARTNER_SUCCESS,
        });
        showMessage("success", "Compliance assigned succcessfully!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.ASSIGN_TEMPLATE_PARTNER_FAILURE,
        });
        if (error.message) showMessage("error", error.message.toString());
        else showMessage("error", "Something Went Wrong!!");
        if (onError) {
          onError();
        }
      });
  };
};

export const resetAssign = () => {
  return (dispatch) => {
    dispatch({ type: partnerComplianceTemplateConstants.RESET_ASSIGN });
  };
};

export const sendBulkReminderParnterCompliance = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch({
      type: partnerComplianceTemplateConstants.SEND_BULK_REMINDER_REQUEST,
    });
    sendBulkReminderPartnerComp(data)
      .then((response) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.SEND_BULK_REMINDER_SUCCESS,
        });
        showMessage("success", "Bulk Reminder Sent!");
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(hideLoader());
        dispatch({
          type: partnerComplianceTemplateConstants.SEND_BULK_REMINDER_FAILURE,
        });
        console.log(error);
      });
  };
};
