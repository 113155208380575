import { config } from '../constants';
import { authHeader } from '../helpers';
import { handleResponse } from './handle-response';



export const addTemplate = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.API_URL}/template-letter-create/`, requestOptions)
        .then(handleResponse)
}

export const editTemplate = (id, data) => {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.API_URL}/template-letter/${id}/`, requestOptions)
        .then(handleResponse)
}

export const getComplianceTemplateList = () => {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    }; 

    return fetch(`${config.API_URL}/template-letter-create/`, requestOptions)
        .then(handleResponse)
}

export const getTemplate = (id) => {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.API_URL}/template-letter/${id}/`, requestOptions)
        .then(handleResponse)
}

export const deleteTemplate = (id) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${config.API_URL}/template-letter/${id}/`, requestOptions)
};