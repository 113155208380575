export const pdtConstants = {
    GET_PDT_REQUEST: 'GET_PDT_REQUEST',
    GET_PDT_SUCCESS: 'GET_PDT_SUCCESS',
    GET_PDT_FAILURE: 'GET_PDT_FAILURE',

    GET_PDT_REPORT_REQUEST: 'GET_PDT_REPORT_REQUEST',
    GET_PDT_REPORT_SUCCESS: 'GET_PDT_REPORT_SUCCESS',
    GET_PDT_REPORT_FAILURE: 'GET_PDT_REPORT_FAILURE',

    POST_PDT_REQUEST: 'POST_PDT_REQUEST',
    POST_PDT_SUCCESS: 'POST_PDT_SUCCESS',
    POST_PDT_FAILURE: 'POST_PDT_FAILURE',

    GET_ADMIN_PDT_REQUEST: 'GET_ADMIN_PDT_REQUEST',
    GET_ADMIN_PDT_SUCCESS: 'GET_ADMIN_PDT_SUCCESS',
    GET_ADMIN_PDT_FAILURE: 'GET_ADMIN_PDT_FAILURE',

    POST_ADMIN_PDT_REQUEST: 'POST_ADMIN_PDT_REQUEST',
    POST_ADMIN_PDT_SUCCESS: 'POST_ADMIN_PDT_SUCCESS',
    POST_ADMIN_PDT_FAILURE: 'POST_ADMIN_PDT_FAILURE',

    APPROVE_PDT_REQUEST: 'APPROVE_PDT_REQUEST',
    APPROVE_PDT_SUCCESS: 'APPROVE_PDT_SUCCESS',
    APPROVE_PDT_FAILURE: 'APPROVE_PDT_FAILURE',

    REPORT_PDT_REQUEST: 'REPORT_PDT_REQUEST',
    REPORT_PDT_SUCCESS: 'REPORT_PDT_SUCCESS',
    REPORT_PDT_FAILURE: 'REPORT_PDT_FAILURE',

    REPORT_DOWNLOAD_PDT_REQUEST: 'REPORT_DOWNLOAD_PDT_REQUEST',
    REPORT_DOWNLOAD_PDT_SUCCESS: 'REPORT_DOWNLOAD_PDT_SUCCESS',
    REPORT_DOWNLOAD_PDT_FAILURE: 'REPORT_DOWNLOAD_PDT_FAILURE',

    MESSAGE_READ_REQUEST: 'MESSAGE_READ_REQUEST',
    MESSAGE_READ_SUCCESS: 'MESSAGE_READ_SUCCESS',
    MESSAGE_READ_FAILURE: 'MESSAGE_READ_FAILURE',
};