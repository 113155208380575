export const salesReportConstants = {
    GET_API_SALES_REPORT_REQUEST: 'GET_API_SALES_REPORT_REQUEST',
    GET_API_SALES_REPORT_SUCCESS: 'GET_API_SALES_REPORT_SUCCESS',
    GET_API_SALES_REPORT_FAILURE: 'GET_API_SALES_REPORT_FAILURE',

    GET_FDF_SALES_REPORT_REQUEST: 'GET_FDF_SALES_REPORT_REQUEST',
    GET_FDF_SALES_REPORT_SUCCESS: 'GET_FDF_SALES_REPORT_SUCCESS',
    GET_FDF_SALES_REPORT_FAILURE: 'GET_FDF_SALES_REPORT_FAILURE',

    GET_CONSOLIDATED_API_SALES_REPORT_REQUEST: 'GET_CONSOLIDATED_API_SALES_REPORT_REQUEST',
    GET_CONSOLIDATED_API_SALES_REPORT_SUCCESS: 'GET_CONSOLIDATED_API_SALES_REPORT_SUCCESS',
    GET_CONSOLIDATED_API_SALES_REPORT_FAILURE: 'GET_CONSOLIDATED_API_SALES_REPORT_FAILURE',

    GET_CONSOLIDATED_FDF_SALES_REPORT_REQUEST: 'GET_CONSOLIDATED_FDF_SALES_REPORT_REQUEST',
    GET_CONSOLIDATED_FDF_SALES_REPORT_SUCCESS: 'GET_CONSOLIDATED_FDF_SALES_REPORT_SUCCESS',
    GET_CONSOLIDATED_FDF_SALES_REPORT_FAILURE: 'GET_CONSOLIDATED_FDF_SALES_REPORT_FAILURE',

    POST_API_SALES_REPORT_REQUEST: 'POST_API_SALES_REPORT_REQUEST',
    POST_API_SALES_REPORT_SUCCESS: 'POST_API_SALES_REPORT_SUCCESS',
    POST_API_SALES_REPORT_FAILURE: 'POST_API_SALES_REPORT_FAILURE',

    POST_FDF_SALES_REPORT_REQUEST: 'POST_FDF_SALES_REPORT_REQUEST',
    POST_FDF_SALES_REPORT_SUCCESS: 'POST_FDF_SALES_REPORT_SUCCESS',
    POST_FDF_SALES_REPORT_FAILURE: 'POST_FDF_SALES_REPORT_FAILURE',

    GET_ADMIN_API_SALES_REPORT_REQUEST: 'GET_ADMIN_API_SALES_REPORT_REQUEST',
    GET_ADMIN_API_SALES_REPORT_SUCCESS: 'GET_ADMIN_API_SALES_REPORT_SUCCESS',
    GET_ADMIN_API_SALES_REPORT_FAILURE: 'GET_ADMIN_API_SALES_REPORT_FAILURE',

    GET_ADMIN_FDF_SALES_REPORT_REQUEST: 'GET_ADMIN_FDF_SALES_REPORT_REQUEST',
    GET_ADMIN_FDF_SALES_REPORT_SUCCESS: 'GET_ADMIN_FDF_SALES_REPORT_SUCCESS',
    GET_ADMIN_FDF_SALES_REPORT_FAILURE: 'GET_ADMIN_FDF_SALES_REPORT_FAILURE',

    POST_ADMIN_API_SALES_REPORT_REQUEST: 'POST_ADMIN_API_SALES_REPORT_REQUEST',
    POST_ADMIN_API_SALES_REPORT_SUCCESS: 'POST_ADMIN_API_SALES_REPORT_SUCCESS',
    POST_ADMIN_API_SALES_REPORT_FAILURE: 'POST_ADMIN_API_SALES_REPORT_FAILURE',

    POST_ADMIN_FDF_SALES_REPORT_REQUEST: 'POST_ADMIN_FDF_SALES_REPORT_REQUEST',
    POST_ADMIN_FDF_SALES_REPORT_SUCCESS: 'POST_ADMIN_FDF_SALES_REPORT_SUCCESS',
    POST_ADMIN_FDF_SALES_REPORT_FAILURE: 'POST_ADMIN_FDF_SALES_REPORT_FAILURE',

    APPROVE_SALES_REPORT_REQUEST: 'APPROVE_SALES_REPORT_REQUEST',
    APPROVE_SALES_REPORT_SUCCESS: 'APPROVE_SALES_REPORT_SUCCESS',
    APPROVE_SALES_REPORT_FAILURE: 'APPROVE_SALES_REPORT_FAILURE',

    SUBMIT_SALES_REPORT_REQUEST: 'SUBMIT_SALES_REPORT_REQUEST',
    SUBMIT_SALES_REPORT_SUCCESS: 'SUBMIT_SALES_REPORT_SUCCESS',
    SUBMIT_SALES_REPORT_FAILURE: 'SUBMIT_SALES_REPORT_FAILURE',

    DOWNLOAD_API_SALES_REPORT_REQUEST: 'DOWNLOAD_API_SALES_REPORT_REQUEST',
    DOWNLOAD_API_SALES_REPORT_SUCCESS: 'DOWNLOAD_API_SALES_REPORT_SUCCESS',
    DOWNLOAD_API_SALES_REPORT_FAILURE: 'DOWNLOAD_API_SALES_REPORT_FAILURE',

    DOWNLOAD_FDF_SALES_REPORT_REQUEST: 'DOWNLOAD_FDF_SALES_REPORT_REQUEST',
    DOWNLOAD_FDF_SALES_REPORT_SUCCESS: 'DOWNLOAD_FDF_SALES_REPORT_SUCCESS',
    DOWNLOAD_FDF_SALES_REPORT_FAILURE: 'DOWNLOAD_FDF_SALES_REPORT_FAILURE',

    MESSAGE_READ_REQUEST: 'MESSAGE_READ_REQUEST',
    MESSAGE_READ_SUCCESS: 'MESSAGE_READ_SUCCESS',
    MESSAGE_READ_FAILURE: 'MESSAGE_READ_FAILURE',

    GET_PRODUCT_VERIFICATION_LIST_REQUEST: 'GET_PRODUCT_VERIFICATION_LIST_REQUEST',
    GET_PRODUCT_VERIFICATION_LIST_SUCCESS: 'GET_PRODUCT_VERIFICATION_LIST_SUCCESS',
    GET_PRODUCT_VERIFICATION_LIST_FAILURE: 'GET_PRODUCT_VERIFICATION_LIST_FAILURE',

    POST_PRODUCT_VERIFICATION_LIST_REQUEST: 'POST_PRODUCT_VERIFICATION_LIST_REQUEST',
    POST_PRODUCT_VERIFICATION_LIST_SUCCESS: 'POST_PRODUCT_VERIFICATION_LIST_SUCCESS',
    POST_PRODUCT_VERIFICATION_LIST_FAILURE: 'POST_PRODUCT_VERIFICATION_LIST_FAILURE',

};