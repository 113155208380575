import { partnerComplianceTemplateConstants } from "../../constants";

const initialSettings = {
  isReminderSent: false,
  isMailSent: false,
  istemplateAssigned: false,
  PartnerComplianceList: {},
  PartnerListDetails: [],
  AdminComplianceList: [],
  AssignedList: [],
  isLoaded: false,
  notification: [],
  chats: [],
};

const PartnerCompliance = (state = initialSettings, action) => {
  switch (action.type) {
    case partnerComplianceTemplateConstants.GET_PARTNER_COMPLIANCE_LIST_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case partnerComplianceTemplateConstants.GET_PARTNER_COMPLIANCE_LIST_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        PartnerComplianceList: action.data,
      };
    case partnerComplianceTemplateConstants.GET_PARTNER_COMPLIANCE_LIST_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    case partnerComplianceTemplateConstants.GET_PARTNER_LIST_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case partnerComplianceTemplateConstants.GET_PARTNER_LIST_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        PartnerListDetails: action.data,
      };
    case partnerComplianceTemplateConstants.GET_PARTNER_LIST_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    case partnerComplianceTemplateConstants.GET_ASSIGNED_LIST_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case partnerComplianceTemplateConstants.GET_ASSIGNED_LIST_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        AssignedList: action.data,
      };
    case partnerComplianceTemplateConstants.GET_ASSIGNED_LIST_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    case partnerComplianceTemplateConstants.GET_ADMIN_COMPLIANCE_LIST_REQUEST:
      return {
        ...state,
        isLoaded: false,
      };
    case partnerComplianceTemplateConstants.GET_ADMIN_COMPLIANCE_LIST_SUCCESS:
      return {
        ...state,
        isLoaded: true,
        AdminComplianceList: action.data,
      };
    case partnerComplianceTemplateConstants.GET_ADMIN_COMPLIANCE_LIST_FAILURE:
      return {
        ...state,
        isLoaded: true,
      };
    case partnerComplianceTemplateConstants.GET_COMPLIANCE_MESSAGE_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.GET_COMPLIANCE_MESSAGE_SUCCESS:
      return {
        ...state,
        chats: action.data,
      };
    case partnerComplianceTemplateConstants.GET_COMPLIANCE_MESSAGE_FAILURE:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.POST_COMPLIANCE_MESSAGE_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.POST_COMPLIANCE_MESSAGE_SUCCESS:
      return {
        ...state,
        chats: [...state.chats, action.data],
      };
    case partnerComplianceTemplateConstants.POST_COMPLIANCE_MESSAGE_FAILURE:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.SEND_COMPLIANCE_REMINDER_REQUEST:
      return {
        ...state,
        isReminderSent: false,
      };
    case partnerComplianceTemplateConstants.SEND_COMPLIANCE_REMINDER_SUCCESS:
      return {
        ...state,
        isReminderSent: true,
      };
    case partnerComplianceTemplateConstants.SEND_COMPLIANCE_REMINDER_FAILURE:
      return {
        ...state,
        isReminderSent: true,
      };
    case partnerComplianceTemplateConstants.SEND_COMPLIANCE_MAIL_REQUEST:
      return {
        ...state,
        isMailSent: false,
      };
    case partnerComplianceTemplateConstants.SEND_COMPLIANCE_MAIL_SUCCESS:
      return {
        ...state,
        isMailSent: true,
      };
    case partnerComplianceTemplateConstants.SEND_COMPLIANCE_MAIL_FAILURE:
      return {
        ...state,
        isMailSent: true,
      };
    case partnerComplianceTemplateConstants.DOWNLOAD_TEMPLATE_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.DOWNLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.DOWNLOAD_TEMPLATE_FAILURE:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.DOWNLOAD_SIGNED_TEMPLATE_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.DOWNLOAD_SIGNED_TEMPLATE_SUCCESS:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.DOWNLOAD_SIGNED_TEMPLATE_FAILURE:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.VERIFY_PARTNER_SIGNED_LETTER_REQUEST_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.VERIFY_PARTNER_SIGNED_LETTER_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.VERIFY_PARTNER_SIGNED_LETTER_REQUEST_FAILURE:
      return {
        ...state,
      };

    case partnerComplianceTemplateConstants.REJECT_PARTNER_SIGNED_LETTER_REQUEST_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.REJECT_PARTNER_SIGNED_LETTER_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.REJECT_PARTNER_SIGNED_LETTER_REQUEST_FAILURE:
      return {
        ...state,
      };

    case partnerComplianceTemplateConstants.DELETE_PARTNER_COMPLIANCE_REQUEST_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.DELETE_PARTNER_COMPLIANCE_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.DELETE_PARTNER_COMPLIANCE_REQUEST_AILURE:
      return {
        ...state,
      };

    case partnerComplianceTemplateConstants.ASSIGN_TEMPLATE_PARTNER_REQUEST:
      return {
        ...state,
        istemplateAssigned: false,
      };
    case partnerComplianceTemplateConstants.ASSIGN_TEMPLATE_PARTNER_SUCCESS:
      return {
        ...state,
        istemplateAssigned: true,
      };
    case partnerComplianceTemplateConstants.ASSIGN_TEMPLATE_PARTNER_FAILURE:
      return {
        ...state,
        istemplateAssigned: false,
      };
    case partnerComplianceTemplateConstants.RESET_ASSIGN:
      return {
        ...state,
        istemplateAssigned: false,
      };
    case partnerComplianceTemplateConstants.GET_NOTIFICATION_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.GET_NOTIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        notification: {
          count: action.data.unread_count,
          items: [
            ...action.data.unread_notifications,
            ...action.data.read_notifications,
          ],
        },
      };
    case partnerComplianceTemplateConstants.GET_NOTIFICATION_REQUEST_FAILURE:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.NOTIFICATION_READ_REQUEST:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.NOTIFICATION_READ_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case partnerComplianceTemplateConstants.NOTIFICATION_READ_REQUEST_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default PartnerCompliance;
