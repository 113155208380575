import React, { useEffect, useState} from "react";
import IntlMessages from "util/IntlMessages";
import { Card, Table, Col, Button, Upload, Row, Form, Modal, message, Select} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  UploadOutlined, MedicineBoxOutlined
} from "@ant-design/icons";
import {
  uploadMOMPDF,
  getMOMList,
} from "../../../appRedux/actions";
import { getRole } from "../../../helpers";

const MOMList = (props) => {

  const dispatch = useDispatch();
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserLegal, setIsUserLegal] = useState(false);
  const [requestObj, setRequestObj] = useState({ page_number: 1, page_size: 10 });
  const [recordTotal, setRecordTotal] = useState(0);
  const [currentQuarter, setCurrentQuarter] = useState('');
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(true);
  const [form] = Form.useForm();
  const [quarter, setQuarter] = useState([]);
  const { Option } = Select;

  const isLoaded = useSelector((state) => state.momList.isLoaded);

  const momListData = useSelector(
    (state) => state.momList.MOMList
  );

  // Set role
  useEffect(() => {
    setIsUserAdmin(() => {
      return getRole() === "ADMIN";
    });
    setIsUserLegal(() => {
      return getRole() === "LEGAL";
    });

    dispatch(getMOMList())
  }, [requestObj]);

  // Set Quarter
  useEffect(() => {
    if (isLoaded) {
      let rowData = momListData.length
        ? momListData
            .filter((value) => value.quarter_name)
            .map((value) => ({
              key: value.quarter_id,
              name: value.quarter_name,
            }))
        : [];
      setQuarter(rowData);
    }
  }, [isLoaded]);

  // Set Pagination
  useEffect(() => { })
  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination
    setRequestObj({
      page_size: pageSize,
      page_number: current
    })
  }

  // Set data
  useEffect(() => {
    let finaldata = momListData

    let MOMData = !!finaldata
      ? finaldata.map((value) => ({
          key: value.quarter_id,
          template_name: value.quarter_name,
          quarter_id: value.quarter_id,
          document: value.mom_docs.length > 0 ? [value.mom_docs.length > 0 ? <a href={value.mom_docs[0].document} target="_blank" rel="noopener noreferrer">{value.mom_docs[0].name}</a>: '',
          value.mom_docs.length > 1 ? <a href={value.mom_docs[1].document} target="_blank" rel="noopener noreferrer">{value.mom_docs[1].name}</a>: '',
          value.mom_docs.length > 2 ? <a href={value.mom_docs[2].document} target="_blank" rel="noopener noreferrer">{value.mom_docs[2].name}</a>: '']: [],
        }))
      : [];
    let newData = []
    MOMData.forEach((data) => {
      if (data.document.length > 0) {
        data.document.forEach((value) => {
          newData.push({ key: data.quarter_id, template_name: data.template_name, quarter_id: data.quarter_id, document: value })
        })
      }
    })
    setData(newData);
  }, [isLoaded]);

  // Handle cancel request
  const handleCancel = (e) => {
    setIsModalVisible(false);
    setUploadStatus(true);
  };

  // Handle modal open
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Handle request change
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList && e.fileList.length > 1) {
      return [e.fileList[1]];
      }

    return e && e.fileList;
  };

  // Merge Cells
  const mergeCell = (data) => {
    const output_res = data.reduce((result, item) => {
      if (result.indexOf(item.template_name) < 0) {
        result.push(item.template_name);
      }
      return result;
    }, [])
      .reduce((result, template_name) => {
        const children = data.filter((item) => item.template_name === template_name);
        result = result.concat(
          children.map((item, index) => ({
            ...item,
            rowSpan: index === 0 ? children.length : 0,
          }))
        );
        return result;
      }, []);
    return output_res
  };

  // Dummy Request
  const dummyRequest = async options => {
    const { onSuccess, onError, file } = options;
    setTimeout(() => {
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (!isLt2M) {
        message.error('File must be smaller than 15MB!');
        onError('error');
      }else{
        setUploadStatus(false);
        onSuccess("ok");
      }
    }, 0);
  };

  // Set Quarter Id
  const setQuarterId = (e) => {
    setCurrentQuarter(e)
  }

  // Upload request
  const fileUpload = (event) => {
    if (event && event.upload) {
      const formData = new FormData();
      formData.append("name", event.upload[0].name);
      formData.append("quarter_id", currentQuarter);
      formData.append( "document", event.upload[0].originFileObj);
      // dispatch(uploadMOMPDF(formData));
      setIsModalVisible(false);
    } else {
      console.log("File is not uploaded");
    }
  };

  // Columns
  const columns = [
  {
    title: "Quarter Name",
    dataIndex: "template_name",
    key: "template_name",
    width: "300px",
    align: 'center',
    render(_, row) {
      return {
        children: row.template_name,
        props: {
          rowSpan: row.rowSpan,
        },
      };
    },
  },
  {
    title: "File",
    dataIndex: "document",
    key: "document",
    align: 'center'
  },
  ]

  return (
    <div>
      <Row>
        <Col span={24}>
          <div className="gx-flex-row" style={{justifyContent: "space-between"}}>
            <h1 className="title gx-mb-4">
              <IntlMessages id="sidebar.MOM" />
            </h1>
              <div>
              <Button
                id="add-product-btn"
                type="primary"
                className="gx-ml-auto"
                onClick={() => {
                  showModal();
                }}
              >
                <MedicineBoxOutlined /> Upload MOM
              </Button>
              </div>
          </div>
        </Col>
        <Col span={24}>
          <Card className="mb-0">
            <Table
              className="gx-table-responsive mpp-list-table"
              columns={columns}
              dataSource={mergeCell(data)}
              bordered
              pagination={{
                pageSize: data.length,
                current: requestObj.page_number,
                total: data.length * Math.ceil(recordTotal / 10),
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        destroyOnClose={true}
        title={`Upload MOM`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} name="uploadSignedDocument" onFinish={fileUpload} preserve={false}>
          <Form.Item
            name="quarter"
            label="Quarter"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Select onChange={setQuarterId}>
              {quarter.map((user) => (
                <Option value={user.key}>{user.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="upload"
            label="Upload"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              customRequest={dummyRequest}
              listType="picture"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>
            </Upload>
          </Form.Item>

          <div className="mb-10">
            <small className="ant-upload-hint">
                **Only supports file size upto 15MB.
            </small>
          </div>
          <Form.Item
            wrapperCol={{
              span: 12,
              offset: 6,
            }}
          >
            <Button type="primary" htmlType="submit" disabled={uploadStatus}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MOMList;
