import { royaltyInvoiceConstants,taxInvoiceConstants } from "../../constants";

const initialSettings = {
  royaltyPartnersList: [],
  invoiceRequestdata: [],
  taxRequestdata: [],
  editInvoiceRequestData: [],
  editTaxRequestData: [],
  isInvoiceRequestEdited: false,
  isInvoiceRequestDeleted: false,
  isInvoiceRequestAdded: false,
  isTaxRequestAdded: false,
  isRoyaltyInvoiceLoaded: false,
  isTaxDataLoaded: false

};

const PartnerInvoice = (state = initialSettings, action) => {
  switch (action.type) {
    case royaltyInvoiceConstants.GET_PARTNERS_LIST_REQUEST:
      return {
        ...state,
      };
    case royaltyInvoiceConstants.GET_PARTNERS_LIST_SUCCESS:
      return {
        ...state,
        royaltyPartnersList: action.data,
      };
    case royaltyInvoiceConstants.GET_PARTNERS_LIST_FAILURE:
      return {
        ...state,
      };
    case royaltyInvoiceConstants.GET_PARTNER_INVOICE_REQUEST_DATA_REQUEST:
      return {
        ...state,
        isRoyaltyInvoiceLoaded: false
      };
    case royaltyInvoiceConstants.GET_PARTNER_INVOICE_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        invoiceRequestdata: action.data,
        isRoyaltyInvoiceLoaded: true
      };
    case royaltyInvoiceConstants.GET_PARTNER_INVOICE_REQUEST_DATA_FAILURE:
      return {
        ...state,
        isRoyaltyInvoiceLoaded: false
      };
      case taxInvoiceConstants.GET_TAX_REQUEST_DATA_REQUEST:
      return {
        ...state,
        isTaxDataLoaded: false
      };
    case taxInvoiceConstants.GET_TAX_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        taxRequestdata: action.data,
        isTaxDataLoaded: true
      };
    case taxInvoiceConstants.GET_TAX_REQUEST_DATA_FAILURE:
      return {
        ...state,
        isTaxDataLoaded: false
      };
      case royaltyInvoiceConstants.EDIT_INVOICE_REQUEST_REQUEST:
      return {
        ...state,
        isInvoiceRequestEdited: false,
      };
    case royaltyInvoiceConstants.EDIT_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        isInvoiceRequestEdited: true,
      };
    case royaltyInvoiceConstants.EDIT_INVOICE_REQUEST_FAILURE:
      return {
        ...state,
        isInvoiceRequestEdited: false,
      };
    case royaltyInvoiceConstants.DELETE_PARTNER_INVOICE_REQUEST_DATA_REQUEST:
      return {
        ...state,
        isInvoiceRequestDeleted: false,
      };
    case royaltyInvoiceConstants.DELETE_PARTNER_INVOICE_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        isInvoiceRequestDeleted: true,
      };
    case royaltyInvoiceConstants.DELETE_PARTNER_INVOICE_REQUEST_DATA_FAILURE:
      return {
        ...state,
        isInvoiceRequestDeleted: false,
      };

    case royaltyInvoiceConstants.RAISE_INVOICE_REQUEST_REQUEST:
      return {
        ...state,
        isInvoiceRequestAdded: false,
      };
    case royaltyInvoiceConstants.RAISE_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        isInvoiceRequestAdded: true,
      };
    case royaltyInvoiceConstants.RAISE_INVOICE_REQUEST_FAILURE:
      return {
        ...state,
        isInvoiceRequestAdded: false,
      };
    case royaltyInvoiceConstants.SINGLE_INVOICE_VALUE_REQUEST:
      return {
        ...state,
        isInvoiceRequestAdded: false,
      };
    case royaltyInvoiceConstants.SINGLE_INVOICE_VALUE_SUCCESS:
      return {
        ...state,
        isInvoiceRequestAdded: true,
        editInvoiceRequestData: action.data
      };
    case royaltyInvoiceConstants.SINGLE_INVOICE_VALUE_FAILURE:
      return {
        ...state,
        isInvoiceRequestAdded: false,
      };
    case taxInvoiceConstants.SINGLE_TAX_VALUE_REQUEST:
      return {
        ...state,
        isTaxRequestAdded: false,
      };
    case taxInvoiceConstants.SINGLE_TAX_VALUE_SUCCESS:
      return {
        ...state,
        isTaxRequestAdded: true,
        editTaxRequestData: action.data
      };
    case taxInvoiceConstants.SINGLE_TAX_VALUE_FAILURE:
      return {
        ...state,
        isTaxRequestAdded: false,
      };

    default:
      return state;
  }
};

export default PartnerInvoice;
